import React from "react";
import "react-multi-carousel/lib/styles.css";
import { SmallImageSlider } from "../../../theme/css/Global";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions";
import { ToastsStore } from "react-toasts";

import Flickity from "react-flickity-component";
import "../../../theme/css/flickity.css";

const _ = require("lodash");
// const moment = require("moment");

function SquareImageSilder(props) {
  let { sliderData } = props;
  const dispatch = useDispatch();
  const {
    UserData,
    savedOfferDetails,
    isSavedOfferDeleted,
    showToaster,
  } = useSelector((state) => ({
    UserData: state.userState.UserDetails,
    savedOfferDetails: state.savedOfferState.savedOfferDetails,
    isSavedOfferDeleted: state.savedOfferState.isSavedOfferDeleted,
    showToaster: state.savedOfferState.showToaster,
  }));

  if (_.get(savedOfferDetails, "id")) {
    sliderData.map((slider, index) => {
      if (_.get(slider, "id") === _.get(savedOfferDetails, "offer_detail.id")) {
        sliderData[index].is_saved.flag = true;
        sliderData[index].is_saved.saved_offer_id = savedOfferDetails.id;
        // dispatch(actions.initialization());
      }
    });
  }

  if (_.get(isSavedOfferDeleted, "flag")) {
    sliderData.map((slider, index) => {
      if (_.get(slider, "id") === _.get(isSavedOfferDeleted, "offerId")) {
        sliderData[index].is_saved.saved_offer_id = null;
        sliderData[index].is_saved.flag = false;
        // dispatch(actions.initialization());
      }

      if (
        _.get(slider.is_saved, "saved_offer_id") ===
        _.get(isSavedOfferDeleted, "offerId")
      ) {
        sliderData[index].is_saved.saved_offer_id = null;
        sliderData[index].is_saved.flag = false;
      }
    });
  }

  const handleOnClick = (slider) => {
    props.onClick({
      selectedOffer: slider,
    });
  };

  const toggleFavourite = (offerData) => {
    if (localStorage.getItem("token")) {
      if (offerData.is_saved.flag) {
        dispatch(
          actions.unSaveOffer(offerData.is_saved.saved_offer_id, offerData.id)
        );
      } else if (_.get(UserData, "id")) {
        dispatch(actions.saveOffer(offerData.id, UserData.id));
      } else {
        ToastsStore.error(
          "Something went wrong. Please refresh the page and try again."
        );
      }
    } else {
      window.$("#recommendedForSignUp").modal("show");
    }
  };

  const ChangeToaster = () => {
    dispatch(actions.closeToaster(null));
  };

  if (showToaster !== null) {
    setTimeout(ChangeToaster, 3000);
  }

  return (
    <Flickity
      options={{
        pageDots: false,
        cellAlign: "left",
        contain: true,
        groupCells:  '90%',
        freeScroll: true,
        freeScrollFriction: 0.02,
      }}
    >
      {sliderData
        ? sliderData.map((slider, index) => (
            <React.Fragment key={slider.id + "_" + (Math.floor(Math.random() * 9999) + 999)}>
              <SmallImageSlider
                className={sliderData.length - 1 !== index ? "" : "last-slide"}
              >
                {showToaster &&
                (_.get(slider, "id") ===
                  _.get(savedOfferDetails, "offer_detail.id") ||
                  _.get(slider, "id") ===
                    _.get(isSavedOfferDeleted, "offerId")) ? (
                  <div className="saved-label">{showToaster} </div>
                ) : null}
                <div className="slide-inner" key={index}>
                  <img
                    src={_.get(slider, "image") ? slider?.image : null}
                    alt={slider?.title}
                    onClick={() => handleOnClick(slider)}
                  />
                  <div
                    className="heart-circle"
                    onClick={() => toggleFavourite(slider)}
                  >
                    <i
                      className={
                        slider.is_saved.flag ? "fa fa-heart" : "fa fa-heart-o"
                      }
                      aria-hidden="true"
                    ></i>
                  </div>
                  <br />
                  <div
                    className="slide-info-text"
                    onClick={() => handleOnClick(slider)}
                  >
                    <div className="from-text">
                      <b>{slider.brand.name}</b>
                    </div>
                    <h5 className="heading">{slider.title}</h5>

                    {/*
                    <div className="days-ago">
                      Added{" "}
                      {moment(new Date()).diff(
                        moment(slider?.created_at),
                        "days"
                      )}{" "}
                      days ago
                    </div>
                    */}

                  </div>
                  {/* <div className="overlay d-md-block d-none"></div> */}
                </div>
              </SmallImageSlider>
              {sliderData.length - 1 === index ? (
                <SmallImageSlider className="empty-slide"></SmallImageSlider>
              ) : (
                ""
              )}
            </React.Fragment>
          ))
        : ""}
    </Flickity>
  );
}

export default SquareImageSilder;
