import React from "react";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from "react-responsive-carousel";

// image type
import HeaderImageSlider from "./HeaderImageSlider";
import SquareImageSlider from "./SquareImageSilder";
import RectanleImageSlider from "./RectangleImageSlider";
import CircleImageSlider from "./CircleImageSlider";

// function ImageSlider(props) {
//   const getConfigurableProps = () => props.settings;

//   return (
//     <Carousel infiniteLoop centerMode {...getConfigurableProps()}>
//       {props.images.map((image) => {
//         console.log('asdasd', props);

//         // return (
//         //   <div>
//         //     <img src={image} />
//         //   </div>
//         // );
//       })}
//     </Carousel>
//   );
// }

const ImageSlider = (props) => {
  const { sliderFor } = props;
  switch (sliderFor) {
    case 'header':
      return <HeaderImageSlider {...props} />;
    case 'square':
      return <SquareImageSlider {...props} />;
    case 'rectangle':
      return <RectanleImageSlider {...props} />;
    case 'circle':
      return <CircleImageSlider {...props} />;
    default:
      return null;
  }
}

export default ImageSlider;
