/** @format */

import React, { useEffect, useState } from "react";
import Header from "./header/HeaderComponent";
import { useDispatch } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import OffersDashboard from "../application/offers/OffersDashboard";
import OfferList from "../application/offers/OfferList";
import SavedOfferList from "../application/offers/SavedOfferList";
import SearchOffersList from "../application/offers/SearchOffersList";
import MyAccountContainer from "../application/user/MyAccountContainer";
import Footer from "./footer/Footer";
import PrivateRoute from "../../services/PrivateRoute";
import * as actions from "../../store/actions";
import PrivacyPolicy from "./footer/PrivacyPolicy";
import CAPrivacyRights from "./footer/CAPrivacyRights";
import TermsAndConditions from "./footer/TermsAndConditions";
import DoNotSellMyPersonalInformation from "./footer/DoNotSellMyPersonalInformation";
import MedicarePartners from "./footer/MedicarePartners";
import UnsubscribeComponent from "./footer/UnsubscribeComponent";
import LandingPage from "../application/offers/LandingPage";
import CompareOffer from "../application/offers/CompareOffer";
import OfferDetails from "../application/offers/OfferDetails";
import MyOffersList from "../application/offers/MyOffersList";
import AllOffers from "../application/offers/AllOffers";
import { Helmet } from "react-helmet";
import SignupPage from "../application/user/SignupPage";
import BrandDetails from "../application/brands/BrandDetails";

function MainContainer() {
  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(actions.getUserDetails());
    }
  }, []);

  let [canonicalLink, setCanonicalLink] = useState("");
  useEffect(() => {
    setCanonicalLink(window.location.href);
  }, [window.location.href]);

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="The latest deals from brands you love."
        />
        <link rel="canonical" href={canonicalLink} />
        <title>Welcome to {window.location.hostname}</title>
      </Helmet>
      {/* <Header /> */}
      <Switch>
        <Route
          exact
          path="/"
          component={OffersDashboard}
          isVerify={false}
          isResetPassword={false}
          key=""
        />
        <Route
          exact
          path="/offer/medicaid/landing/:zipcode?"
          component={LandingPage}
        />
        <Route exact path="/offer/medicaid" component={CompareOffer} />
        <Route path="/offer/:id" component={OfferDetails} />
        {/* <Route path="/brand/:id" component={BrandDetails} /> */}
        <Route path="/offers" component={OfferList} />
        <Route path="/all" component={AllOffers} />
        <Route exact path="/saved-offers" component={SavedOfferList} />
        {/* <Route exact path="/my-offers" component={MyOffersList} /> */}
        <Route path="/search" component={SearchOffersList} />
        <PrivateRoute path="/account" component={MyAccountContainer} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route
          exact
          path="/terms-and-conditions"
          component={TermsAndConditions}
        />
        <Route exact path="/CA-privacy-rights" component={CAPrivacyRights} />
        <Route
          exact
          path="/do-not-sell-my-personal-information"
          component={DoNotSellMyPersonalInformation}
        />
        <Route exact path="/medicare-partners" component={MedicarePartners} />
        <Route exact path="/unsubscribe" component={UnsubscribeComponent} />
        <Route exact path="/signup" component={SignupPage} />
        {/* <Redirect from="/" to="/dashboard" /> */}
        <Redirect to="/" />
      </Switch>
      <Footer />
    </React.Fragment>
  );
}

export default MainContainer;
