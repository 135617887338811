import React, { useState } from "react";

function ResetPassword(props) {
  const [isVerify, setIsVerify] = useState(false);

  if (!isVerify) {
    localStorage.setItem('resetPassword', props.location.search.replace("?key=", ""));
    props.history.push(
      {
        pathname: "/",
      },
      { isResetPassword: true
      }
    );
    setIsVerify(true);
  }

  return <div></div>;
}
export default ResetPassword;
