import React, { useEffect, useState } from "react";
import { ToastsStore } from "react-toasts";
import { useDispatch } from "react-redux";
import * as actions from "../../../store/actions";

import CircleLoader from "../../shared/CircleLoader";
import { Button, ErrorLabel } from "../../../theme/css/Global";
import apiClient from "../../../store/actions/ApiInterceptor";
import { getFields } from "../../shared/FieldRenderer";
import {
  PersonalDetailsLeftPanelConstant,
  PersonalDetailsRightPanelConstant,
  EmailPasswordPanelConstant,
  ChangePasswordPanelConstant,
} from "../../../constants/UserInfoConstant";
// import { max } from "moment";

const moment = require("moment");
const _ = require("lodash");

function MyDetails(props) {
  const [userDetailsLoading, setUserDetailsLoading] = useState(false);
  const [userDetailsFormData, setUserDetailsFormData] = useState({});
  const [userDetailsFormError, setUserDetailsFormError] = useState({});
  // password
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);
  const [showChangePasswordPanel, setShowChangePasswordPanel] = useState(false);
  const [changePasswordFormData, setChangePasswordFormData] = useState({});
  const [changePasswordFormError, setChangePasswordFormError] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    getUserDetails();
  }, []);

  const redirectToPage = (redirectLink) => {
    props.history.push({
      pathname: redirectLink,
    });
  };

  const getUserDetails = () => {
    apiClient
      .get("user/me/")
      .then((res) => {
        setUserDetailsFormData(res.data);
      })
      .catch((error) => {});
  };

  const handleChanges = ({ key, value } = {}) => {
    setUserDetailsFormData({
      ...userDetailsFormData,
      [key]: value,
    });
    setUserDetailsFormError({ ...userDetailsFormError, [key]: [] });
  };

  const handleChangesChangePassword = ({ key, value } = {}) => {
    setChangePasswordFormData({
      ...changePasswordFormData,
      [key]: value,
    });
    setChangePasswordFormError({ ...changePasswordFormError, [key]: [] });
  };

  const handleEditField = (value) => {
    setShowChangePasswordPanel(true);
  };

  const renderPersonalDetailsForm = (constant, formData, formError) => {
    return getFields(
      constant,
      formData,
      formError,
      handleChanges,
      null,
      null,
      handleEditField
    );
  };

  const renderChangePasswordForm = (constant, formData, formError) => {
    return getFields(
      constant,
      formData,
      formError,
      handleChangesChangePassword,
      null,
      null,
      handleEditField
    );
  };

  const Membership = () => {
    return (
      <React.Fragment>
        <div className="my-details-headings">Membership</div>
        <div className="membership-details">
          <div className="row pt-5">
            <div className="col-lg-4 col-6">
              <label>Member Since:</label>
            </div>
            <div className="col-lg-4 col-6">
              <b className="joining-date">
                {moment(userDetailsFormData.created_at).format("DD/MM/YYYY")}
              </b>
            </div>
          </div>
          {/* <div className="row pt-3">
            <div className="col-lg-4 col-6">
              <label>Offers Claimed:</label>
            </div>
            <div className="col-lg-4 col-6">
              <b>182</b>
            </div>
          </div> */}
        </div>
      </React.Fragment>
    );
  };

  const CheckValidations = (data) => {
    let flag = false;
    if (moment(data.birth_date).format("MM/DD/YYYY") !== "Invalid date") {
      var maxDate = moment(moment().format("MM/DD/YYYY"));
      var minDate = moment(moment("01/01/1900").format("MM/DD/YYYY"));
      var birthDate = moment(moment(data.birth_date).format("MM/DD/YYYY"));

      if (birthDate > maxDate || birthDate < minDate) {
        flag = true;
      }
    } else {
      flag = true;
    }

    if (flag === true) {
      setUserDetailsFormError({
        ...userDetailsFormError,
        birth_date: 'Invalid Date'
      })
    }
    return flag;
  };

  const saveUserDetails = (event) => {
    event.preventDefault(true);
    setUserDetailsLoading(true);
    const flag = CheckValidations(userDetailsFormData);

    if (!flag) {
      setUserDetailsLoading(true);
      var cloneUserDetails = Object.assign({}, userDetailsFormData);
      var date = moment(cloneUserDetails["birth_date"]).format("MM-DD-YYYY");
      cloneUserDetails["birth_date"] = date;
      apiClient
        .patch(`user/${userDetailsFormData.id}/`, cloneUserDetails)
        .then((res) => {
          setUserDetailsLoading(false);
          dispatch(actions.getUserDetails());
        })
        .catch((error) => {
          setUserDetailsFormError(error.response.data);
          setUserDetailsLoading(false);
        });
    } else {
      setUserDetailsLoading(false);
    }
  };

  const logoutUser = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("resetPassword");
    // redirectToPage("/dashboard");
    redirectToPage("/");
    window.$("#logOutModal").modal("show");
  };

  const submitChangePassword = (event) => {
    event.preventDefault(true);
    setChangePasswordLoading(true);
    apiClient
      .patch(`user/change-password/`, changePasswordFormData)
      .then((res) => {
        setChangePasswordLoading(false);
        setShowChangePasswordPanel(false);
        logoutUser();
        ToastsStore.success("Password update successfully.");
      })
      .catch((error) => {
        setChangePasswordLoading(false);
        setChangePasswordFormError(_.get(error.response, "data"));
      });
  };

  return (
    <React.Fragment>
      <div className="col-xl-9 col-lg-8">
        <div className="white-panel">
          <div style={{ display: 'flex', }}>
            <h4 className="dark-bold-heading">My Details</h4>
            <Button
                type="submit"
                className="btn btn-primary w-50 d-xl-block d-lg-none d-md-block d-none"
                onClick={props.history.goBack}
                style={{marginLeft: 'auto', maxWidth: '150px'}}
              >
                Back
              </Button>
          </div>
          <div className="my-details-headings">Personal Information</div>
          <div className="row">
            <div className="col-xl-4  pt-4 mt-xl-3">
              <p className="pt-1">
                To update your personal information, change the content to the
                right, then click save
              </p>
            </div>
            <div className="col-xl-4 col-lg-12 col-md-6 pt-4 mt-xl-3">
              {renderPersonalDetailsForm(
                PersonalDetailsLeftPanelConstant,
                userDetailsFormData,
                userDetailsFormError
              )}
              <Button
                type="submit"
                className="btn btn-primary w-50 d-xl-block d-lg-none d-md-block d-none"
                onClick={(event) => saveUserDetails(event)}
                style={{minHeight:'50px'}}
              >
                {userDetailsLoading ? (
                  <CircleLoader size={20} color="#ffffff" />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
            <div className="col-xl-4 col-lg-12 col-md-6 pt-xl-4 pt-lg-0 pt-md-4 pt-0 mt-xl-3">
              {renderPersonalDetailsForm(
                PersonalDetailsRightPanelConstant,
                userDetailsFormData,
                userDetailsFormError
              )}

              <Button
                type="submit"
                className="btn btn-primary w-50 d-xl-none d-lg-block d-md-none d-block"
                onClick={(event) => saveUserDetails(event)}
              >
                {userDetailsLoading ? (
                  <CircleLoader size={20} color="#ffffff" />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </div>

          <div className="my-details-headings">Email & Password</div>
          <div className="row">
            <div className="col-xl-4 pt-4 mt-xl-3">
              <p className="pt-1">
                To update your email information, change the content to the
                right, then click save
              </p>
            </div>
            <div className="col-xl-4 col-lg-12 col-md-6 pt-4 mt-xl-3">
              {renderPersonalDetailsForm(
                EmailPasswordPanelConstant,
                userDetailsFormData,
                userDetailsFormError
              )}

              {/* password panel */}
              {showChangePasswordPanel
                ? renderChangePasswordForm(
                    ChangePasswordPanelConstant,
                    changePasswordFormData,
                    changePasswordFormError
                  )
                : ""}
              <ErrorLabel className="text-center">
                {changePasswordFormError.non_field_errors
                  ? changePasswordFormError.non_field_errors
                  : ""}
              </ErrorLabel>

              <Button
                type="button"
                className={
                  showChangePasswordPanel
                    ? "btn btn-primary w-50"
                    : "btn btn-secondary w-50"
                }
                onClick={(event) => submitChangePassword(event)}
              >
                {changePasswordLoading ? (
                  <CircleLoader size={20} color="#ffffff" />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </div>
          <Membership />
        </div>
      </div>
    </React.Fragment>
  );
}

export default MyDetails;
