import { useState, useEffect } from "react";

function calculateSeed() {
  const url = window.location.hostname;
  const now = new Date();
  const currentHour = now.getUTCHours();
  const currentDay = now.getUTCDate();
  const urlValue = [...url].reduce((sum, char) => sum + char.charCodeAt(0), 0);

  return (urlValue + currentHour * 1000 + currentDay * 100000) % 233280;
}

function shuffleArray(array, seed) {
  const newArray = [...array];

  for (let i = newArray.length - 1; i > 0; i--) {
    seed = (seed * 9301 + 49297) % 233280;
    const j = Math.floor((seed / 233280) * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }

  return newArray;
}

function useShuffledData(dataArray) {
  const [shuffledData, setShuffledData] = useState([]);

  useEffect(() => {
    if (dataArray && dataArray.length > 0){ 
      const seed = calculateSeed();
      const shuffledArray = shuffleArray(dataArray, seed);
      setShuffledData(shuffledArray);
    }
  }, [dataArray]);

  return shuffledData;
}

export default useShuffledData;