export const Theme = {
  /* Theme Base color */
  "$white-color": "#ffffff",
  "$pink-color": "#fe0b62",
  "$red-color": "#fd397a",
  "$dark-red": "#c82727",
  "$light-yellow": "#e8bb55",
  "$yellow-color": "#ffb822",
  "$dark-yellow": "#b59245",
  "$orange-color": "#eea698",
  "$teal-color": "#95ebe9",
  "$green-color": "#0abb87",
  "$dark-green": "#09ac7d",
  "$light-blue": "#3d8fff",
  "$blue-color": "#5578eb",
  "$black-color": "#000000",

  "$gray-105": "#0E1018",
  "$gray-103": "#1f212e",
  "$gray-102": "#151723",
  "$gray-100": "#111111",
  "$gray-97": "#2b2e3d",
  "$gray-96": "#292b30",
  "$gray-95": "#262a37",
  "$gray-94": "#252835",
  "$gray-93": "#2e3242",
  "$gray-92": "#232733",
  "$gray-91": "#212531",
  "$gray-90": "#222222",
  "$gray-86": "#38393f",
  "$gray-85": "#35383f",
  "$gray-82": "#3f4149",
  "$gray-81": "#31323d",
  "$gray-80": "#333333",
  "$gray-75": "#40434c",
  "$gray-72": "#464a57",
  "$gray-70": "#444444",
  "$gray-65": "#5d6074",
  "$gray-60": "#555555",
  "$gray-55": "#636679",
  "$gray-54": "#646772;",
  "$gray-52": "#66727c",
  "$gray-51": "#6b737a",
  "$gray-50": "#666666",
  "$gray-40": "#777777",
  "$gray-38": "#727e86",
  "$gray-32": "#888a8b",
  "$gray-30": "#888888",
  "$gray-27": "#9ba5af",
  "$gray-25": "#9798aa",
  "$gray-20": "#999999",
  "$gray-15": "#a4a5b6",
  "$gray-10": "#aaaaaa",
  "$gray-8": "#bbbbbb",
  "$gray-6": "#cccccc",
  "$gray-4": "#dddddd",
  "$gray-3": "#d5dad8",

  "$white-90": "#f5f6f8",
  "$white-80": "#e2ecf6",

  /* Colors */
  "$body-bg-color": "#242834",
  "$base-color": "#ffffff",
  "$primary-color": "#3d8eff",
  "$secondary-color": "#2e40d4",

  /* Basic font size */
  "$v-small-f-size": "8px",
  "$e-small-f-size": "10px",
  "$small-f-size": "12px",
  "$base-f-size": "14px",
  "$medium-f-size": "16px",
  "$extra-medium-f-size": "18px",
  "$large-f-size": "24px",
  "$e-large-f-size": "26px",

  /* Title font size */

  "$base-title-f-size": "20px",
  "$primary-title-f-size": " 48px",
  "$secondary-title-f-size": "30px",
  "$list-title-f-size": "41px",

  /* Font family */
  "$base-font-family": "Gilroy-Regular",
  "$title-font-family": "Gilroy-Bold",
  "$thin-font-family": "Gilroy-Thin",
  "$medium-font-family": "Gilroy-Medium",
};
