import React from "react";
import { withRouter } from "react-router-dom";

function CAPrivacyRights(props) {
  return (
    <React.Fragment>
      <div className="container pt-5 pb-5">
      </div>
    </React.Fragment>
  );
}

export default withRouter(CAPrivacyRights);
