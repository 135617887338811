import React, { useEffect, useState } from "react";
import { Button, ModalBox } from "../../../theme/css/Global";

import { getFields } from "../../shared/FieldRenderer";
import {
  SignUpFormConstant,
  VerificationFormConstant,
} from "../../../constants/SignUpConstant";
import apiClient from "../../../store/actions/ApiInterceptor";
import CircleLoader from "../../shared/CircleLoader";
import SuccessIcon from "../../../theme/images/sucess.svg";
import FailIcon from "../../../theme/images/fail.svg";

const _ = require("lodash");

function SignUp(props) {
  const [signUpFormData, setSignUpFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState({ email: null });
  const [isLoading, setIsLoading] = useState(false);
  const [verificationEmailData, setVerificationEmailData] = useState({
    email: "",
  });

  useEffect(() => {
    if (_.get(props.state, "isVerify")) {
      window.$("#VerificationSuccessful").modal("show");
    } else if (_.get(props.state, "key")) {
      window.$("#VerificationFail").modal("show");
    }

    if (_.get(props, "isClearData")) {
      clearError();
      props.onClick();
    }
  });

  const handleFieldChange = ({ key, value }) => {
    setSignUpFormData({
      ...signUpFormData,
      [key]: value,
    });

    setError({ ...error, [key]: [] });
  };

  const verificationFieldChange = ({ key, value }) => {
    setVerificationEmailData({
      ...verificationEmailData,
      [key]: value,
    });

    setError({ ...error, [key]: [] });
  };

  const handleSubmit = (event) => {
    event.preventDefault(true);
    setIsLoading(true);
    apiClient
      .post("register/", {
        email: signUpFormData.email,
        password: signUpFormData.password,
      })
      .then((res) => {
        setIsLoading(false);
        window.$("#joinForFree").modal("hide");
        window.$("#sendVerificationEmail").modal("show");
      })
      .catch((error) => {
        setError(_.get(error.response, "data"));
        setIsLoading(false);
      });
  };

  const handleResendVerification = (event) => {
    event.preventDefault(true);
    setIsLoading(true);
    apiClient
      .post("resend-verification/", {
        email: verificationEmailData.email,
      })
      .then((res) => {
        setIsLoading(false);
        window.$("#resendVerificationEmail").modal("hide");
      })
      .catch((error) => {
        setError(_.get(error.response, "data"));
        setIsLoading(false);
      });
  };

  const clearError = () => {
    setError({ email: "", password: "" });
    setSignUpFormData({ email: "", password: "" });
  };

  const signUpModal = () => {
    return (
      <ModalBox>
        <div className="modal fade" id="joinForFree" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <h2>
                  Create your Offers
                  <br /> Club account
                </h2>
                <div className="modal-sub-heading">
                  Sign up for great deals to save
                  <br /> for later or use on the go.{" "}
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="form-label-group pt-sm-2 mt-4">
                    {getFields(
                      SignUpFormConstant,
                      signUpFormData,
                      error,
                      handleFieldChange
                    )}
                  </div>
                  <p className="small pb-3 pb-2 mb-0 pt-sm-0 pt-0">
                    By signing up, you agree to OffersClub.com
                    <br />
                    <a href="/terms-and-conditions"> terms of service </a> and{" "}
                    <a href="/privacy-policy">privacy policy. </a>
                  </p>
                  <Button
                    type="submit"
                    className="btn btn-primary w-100"
                    data-toggle="modal"
                    // data-target="#sendVerificationEmail"
                    // data-dismiss="modal"
                  >
                    {isLoading ? (
                      <CircleLoader size={20} color="#ffffff" />
                    ) : (
                      "Sign Up"
                    )}
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ModalBox>
    );
  };

  const verificationEmailSendModal = () => {
    return (
      <ModalBox>
        <div className="modal fade" id="sendVerificationEmail" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div className="info text-center ">
                  {" "}
                  A verification email has been sent to{" "}
                </div>
                <div className="email-data text-center mb-3">
                  {signUpFormData.email}
                </div>

                <p className="small  mb-3 text-center">
                  Simply click on the verification link in your email <br />
                  to verify your account.
                  <br />
                  <br />
                  If you didn’t see our email, check your spam <br />
                  folder. You can also resend it.
                </p>

                <div
                  className="forgot-link text-center mt-3"
                  data-toggle="modal"
                  data-target="#resendVerificationEmail"
                  data-dismiss="modal"
                  onClick={clearError}
                >
                  Resend Verification Email
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBox>
    );
  };

  const resendVerificationEmailModal = () => {
    return (
      <ModalBox>
        <div className="modal fade" id="resendVerificationEmail" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <h4 className="modal-title text-center">
                  Resend Email Verification
                </h4>

                <p className="mt-2 mb-2 small text-center">
                  Enter your email address below and we'll resend <br />
                  you a verification code.
                </p>

                <form onSubmit={handleResendVerification}>
                  <div className="form-label-group pt-2 mt-4">
                    {getFields(
                      VerificationFormConstant,
                      verificationEmailData,
                      error,
                      verificationFieldChange
                    )}
                  </div>
                  <Button type="submit" className="btn btn-primary w-100">
                    {isLoading ? (
                      <CircleLoader size={20} color="#ffffff" />
                    ) : (
                      "Send Email"
                    )}
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ModalBox>
    );
  };

  const verifiedEmailSuccessModal = () => {
    return (
      <ModalBox>
        <div className="modal fade" id="VerificationSuccessful" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body text-center">
                <img src={SuccessIcon} alt="" className="mt-4" />
                <h2 className="text-center mt-2 success-heading">Success!</h2>

                <p className="small mt-3 mb-5 text-center">
                  Your email has been verified! <br />
                  Welcome to Offers Club!
                </p>

                <Button
                  type="button"
                  className="btn btn-primary w-100"
                  data-toggle="modal"
                  data-target="#LoginModal"
                  data-dismiss="modal"
                >
                  Log In
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ModalBox>
    );
  };

  const verificationEmailFailModal = () => {
    return (
      <ModalBox>
        <div className="modal fade" id="VerificationFail" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body text-center">
                <img src={FailIcon} alt="" className="mt-4" />
                <h2 className="text-center mt-2 success-heading">Fail!</h2>
                <p className="mt-3 mb-3 text-center small">
                  The provided verification key is invalid.
                </p>

                <div
                  className="forgot-link text-center mt-5"
                  data-toggle="modal"
                  data-target="#resendVerificationEmail"
                  data-dismiss="modal"
                  onClick={clearError}
                >
                  Resend Verification Email
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBox>
    );
  };

  return (
    <React.Fragment>
      {/* <Button
        className="btn btn-primary"
        data-toggle="modal"
        data-target="#joinForFree"
        onClick={clearError}
      >
        Join for Free
      </Button> */}
      {signUpModal()}
      {verificationEmailSendModal()}
      {resendVerificationEmailModal()}
      {verifiedEmailSuccessModal()}
      {verificationEmailFailModal()}
    </React.Fragment>
  );
}
export default SignUp;
