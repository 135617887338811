import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import { Button, ModalBox } from "../../../theme/css/Global";
import styled from "styled-components";
import apiClient from "../../../store/actions/ApiInterceptor";
import {DNSMPIConstant} from "../../../constants/FooterConstant";
import {getFields} from "../../shared/FieldRenderer";

const _ = require("lodash");

function DoNotSellMyPersonalInformation(props) {
	const [FormData, setFormData] = useState({
		first_name: "",
		last_name: "",
		email: "",
		california_address: "",
		phone_number: ""
	});
	const [error, setError] = useState({email: null});
	const [_, setIsLoading] = useState(false);

	const handleFieldChange = ({key, value}) => {
		setFormData({
			...FormData,
			[key]: value,
		});

		setError({...error, [key]: []});
	};

	const handleSubmit = (event) => {
		event.preventDefault(true);
		setIsLoading(true);
		apiClient
			.post("/dnsmpi/", {
				FormData
			})
			.then((res) => {
				setIsLoading(false);
                window.$("#DNSMPISuccessfull").modal("show");
			})
			.catch((error) => {
				setError(_.get(error.response, "data"));
				setIsLoading(false);
			});
	};

	const redirectToPage = (redirectLink) => {
		window.scrollTo(0, 0);
		props.history.push({
		pathname: redirectLink,
		});
	};

	return (
		<React.Fragment>
			<DoNotSell>
				<div className="container pt-5 pb-5">
					<div className="row">
						<div className="col-12">
							<h2>Do Not Sell My Personal Information</h2>
							<h4>
								For California Residents Only
							</h4>
							<p>A list of the categories of personal information which we collect and the business/commercial purposes for which such is used is listed in the “What Personal Information Do We Collect” section of our Privacy Policy, which is located here: <a href="javascript:void(0);" onClick={() => redirectToPage("/privacy-policy")}>Privacy Policy</a>.</p>
							<p>If you are a California resident, under the California Consumer Privacy Act ("CCPA"), you have the right to opt-out of the sale of your personal information. To submit a request to opt-out of the sale of your personal information, you may complete the information requested in the webform below and click on the "Do Not Sell My Personal Information" button, or email us at helpdesk@offersclub.com. To process your request, we may require that you submit the following information in conjunction with your request: your first name, last name, California address, phone number, and email address.</p>
							<p>You may exercise your right to opt-out through an authorized agent. If you elect to use an authorized agent to exercise your right to submit a request to opt-out of the sale of your personal information, we may require that you provide proof that the authorized agent has been authorized to act on your behalf. We will only use the information which you submit to us for the express purposes of verification and complying with your opt-out request, if required.</p>
							<p>Our general privacy policy is located at <a href="javascript:void(0);" onClick={() => redirectToPage("/privacy-policy")}>here</a>. Individuals with a disability which interferes with their ability to submit an opt-out request hereunder may contact us by emailing helpdesk@offersclub.com or by mail at OffersClub.com, 2803 Philadelphia Pike, Suite B #259, Claymont, DE 19703, United States of America, to access this notice and information in an alternative format.</p>
							<br/>
							<form className="do-not-sell mx-auto  mt-5 mb-5" onSubmit={handleSubmit}>
								<div className="form-group">
									{getFields(
										DNSMPIConstant,
										FormData,
										error,
										handleFieldChange
									)}
								</div>
								<p className="small">
								</p>
								<div className="text-center">
									<Button type="submit" className="btn btn-primary">
										Submit Request to Not Sell My Personal Data
									</Button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</DoNotSell>

          <ModalBox>
            <div className="modal fade" id="DNSMPISuccessfull" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal">
                    &times;
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="info text-center ">
                      {" "}
                      Your request has been submitted{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBox>

		</React.Fragment>
	);
}

export default withRouter(DoNotSellMyPersonalInformation);
const DoNotSell = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
`;
