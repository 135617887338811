import React, { useState } from "react";
import { Button, ModalBox, ErrorLabel } from "../../../theme/css/Global";

import { getFields } from "../../shared/FieldRenderer";
import { ResetPasswordFormConstant } from "../../../constants/ResetPasswordConstant";
import apiClient from "../../../store/actions/ApiInterceptor";
import CircleLoader from "../../shared/CircleLoader";

const _ = require("lodash");

function ResetPasswordModal(props) {
  const [resetPasswordFormData, setResetPasswordFormData] = useState({
    password: "",
  });

  const [error, setError] = useState({ email: null });
  const [isLoading, setIsLoading] = useState(false);

  const handleFieldChange = ({ key, value }) => {
    setResetPasswordFormData({
      ...resetPasswordFormData,
      [key]: value,
    });

    setError({ ...error, [key]: [] });
  };

  const handleSubmit = (event) => {
    event.preventDefault(true);
    setIsLoading(true);
    apiClient
      .post("/reset-password/", {
        password: resetPasswordFormData.password,
        key: localStorage.getItem("resetPassword"),
      })
      .then((res) => {
        setIsLoading(false);
        window.$("#resetPasswordModal").modal("hide");
        window.$("#passwordResetSuccessful").modal("show");
        clearError();
        // props.history.push({
        //   pathname: "/dashboard",
        // });
        props.history.push({
          pathname: "/",
        });
      })
      .catch((error) => {
        setError(_.get(error.response, "data"));
        setIsLoading(false);
      });
  };

  const clearError = () => {
    setError({ email: "", password: "", key: "" });
    setResetPasswordFormData({ password: "" });
    localStorage.removeItem("resetPassword");
  };

  const setPassword = () => {
    return (
      <ModalBox>
        <div className="modal fade" id="resetPasswordModal" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => clearError()}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <h2 className="text-center">Reset Password </h2>
                <form onSubmit={handleSubmit}>
                  <div className="form-label-group pt-2 mt-4">
                    {getFields(
                      ResetPasswordFormConstant,
                      resetPasswordFormData,
                      error,
                      handleFieldChange
                    )}
                    <div>
                      {_.get(error, "key") ? (
                        <ErrorLabel>{_.get(error, "key")}</ErrorLabel>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <Button type="submit" className="btn btn-primary w-100 mt-3">
                    {isLoading ? (
                      <CircleLoader size={20} color="#ffffff" />
                    ) : (
                      "Reset Password"
                    )}
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ModalBox>
    );
  };

  const passwordResetSuccessModal = () => {
    return (
      <ModalBox>
        <div className="modal fade" id="passwordResetSuccessful" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <h2 className="text-center  mt-2 success-heading">Success!</h2>
                <p className="mt-3 mb-3">Your password has been changed!</p>
                <Button
                  type="button"
                  className="btn btn-primary w-100"
                  data-toggle="modal"
                  data-target="#LoginModal"
                  data-dismiss="modal"
                >
                  Log in
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ModalBox>
    );
  };

  return (
    <React.Fragment>
      {setPassword()}
      {passwordResetSuccessModal()}
    </React.Fragment>
  );
}
export default ResetPasswordModal;
