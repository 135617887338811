import React, { useState } from "react";
import apiClient from "./../../../store/actions/ApiInterceptor";
const _ = require("lodash");

function VerifyAccount(props) {
  const [isVerify, setIsVerify] = useState(false);

  if (!isVerify) {
    apiClient
      .post("/verify-email/", {
        key: props.location.search.replace("?key=", ""),
      })
      .then((res) => {
        setIsVerify(true);
        props.history.push(
          {
            pathname: "/",
          },
          { isVerify: true }
        );
      })
      .catch((error) => {
        props.history.push(
          {
            pathname: "/",
          },
          { isVerify: false, key: _.get(error.response, "data.key[0]") }
        );
      });
  }

  return (
    <div></div>
  );
}

export default VerifyAccount;
