import axios from "axios";

const apiClient = axios.create({
  baseURL:
    process.env.REACT_APP_BASE_API_URL + process.env.REACT_APP_API_VERSION,
});

const handleRequest = (config) => {
  const token = localStorage.getItem("token");
  // if(config.method !== "get") {
    if (token) {
      config.headers["Authorization"] = "Token " + token;
    }
  // }
  return config;
};

apiClient.interceptors.request.use((config) => {
  return handleRequest(config);
});

apiClient.interceptors.response.use(
  function (response) {
    return response;
  }
  // function (error) {
  //   if (error?.response?.status === 401) {
  //     localStorage.removeItem("token");
  //     ToastsStore.error("Session expired.");
  //     window.location.href = "/signin";
  //   } else {
  //     return Promise.reject(error);
  //   }
  // }
);

export default apiClient;
