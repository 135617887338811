import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import apiClient from "./../../../store/actions/ApiInterceptor";

import { ModalBox } from "../../../theme/css/Global";
import Logo from "../../../theme/images/logo.svg";
import NotificationImg from "../../../theme/images/3.png";
import CircleLoader from "../../shared/CircleLoader";

const _ = require("lodash");

function Notifications(props) {
  const [notificatioData, setNotificationData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const closeNotificationModal = () => {
    window.$("#notificationModal").modal("hide");
  };
  useEffect(() => {
    // getNotifications();
  }, []);

  const getNotifications = () => {
    setIsLoading(true);
    apiClient
      .get("/notifications/")
      .then((res) => {
        setIsLoading(false);
        setNotificationData(res.data);
        console.log("res", res);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  function renderNotifications() {
    if (!_.get(notificatioData, "results", []).length > 0) {
      return (
        <React.Fragment>
          <li>
            <div className="float-left notification-img">
              <img src={NotificationImg} alt="" className="" />
            </div>
            <div className="float-left text-part">
              <a href="#!" className="expiring-soon-link">
                Expiring Soon
              </a>
              <h5>Free Reverse Mortgage Info Kit from AAG</h5>
              <p className="small">Added 4 days ago</p>
            </div>
            <div className="clearfix" />
          </li>
          <li>
            <div className="float-left notification-img">
              <img src={NotificationImg} alt="" className="" />
            </div>
            <div className="float-left text-part">
              <a href="#!" className="expiring-soon-link">
                Expiring Soon
              </a>
              <h5>Free Reverse Mortgage Info Kit from AAG</h5>
              <p className="small">Added 4 days ago</p>
            </div>
            <div className="clearfix" />
          </li>
          <li>
            <div className="float-left notification-img">
              <img src={NotificationImg} alt="" className="" />
            </div>
            <div className="float-left text-part">
              <a href="#!" className="expiring-soon-link">
                Expiring Soon
              </a>
              <h5>Free Reverse Mortgage Info Kit from AAG</h5>
              <p className="small">Added 4 days ago</p>
            </div>
            <div className="clearfix" />
          </li>
          <li>
            <div className="float-left notification-img">
              <img src={NotificationImg} alt="" className="" />
            </div>
            <div className="float-left text-part">
              <a href="#!" className="expiring-soon-link">
                Expiring Soon
              </a>
              <h5>Free Reverse Mortgage Info Kit from AAG</h5>
              <p className="small">Added 4 days ago</p>
            </div>
            <div className="clearfix" />
          </li>
          <li>
            <div className="float-left notification-img">
              <img src={NotificationImg} alt="" className="" />
            </div>
            <div className="float-left text-part">
              <a href="#!" className="expiring-soon-link">
                Expiring Soon
              </a>
              <h5>Free Reverse Mortgage Info Kit from AAG</h5>
              <p className="small">Added 4 days ago</p>
            </div>
            <div className="clearfix" />
          </li>
        </React.Fragment>
      );
    } else {
      return (
        <span>
          <h5>No Notifications Yet! </h5>
        </span>
      );
    }
  }

  return (
    <React.Fragment>
      <ModalBox>
        <div className="modal fade" id="notificationModal" role="dialog">
          <div className="modal-dialog modal-dialog-notification">
            <div className="modal-content notification">
              <div className="modal-header d-sm-none d-block">
                <img
                  className="logo"
                  src={Logo}
                  alt="OffersClubOnline"
                  onClick={() => closeNotificationModal()}
                />
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body notification-body">
                <React.Fragment>
                  <h2 className="d-sm-none d-block">
                    <i
                      className="fa fa-angle-left pr-4 pb-4 "
                      onClick={() => closeNotificationModal()}
                    ></i>
                    Notifications
                  </h2>
                  <ul className="notification-list">
                    {isLoading ? <CircleLoader /> : renderNotifications()}
                  </ul>
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
      </ModalBox>
    </React.Fragment>
  );
}

export default withRouter(Notifications);
