/** @format */

import React from "react";

import { WelcomeTravelHeaderContent } from "../../../theme/css/Global";
import styled from "styled-components";
import { Theme } from "../../../theme/css/theme";

function WelcomeTravelHeader(props) {
  const sub3 = encodeURIComponent(window.location.hostname.replaceAll('.', '__'));

  return (
    <React.Fragment>
      <WelcomeTravelHeaderContent className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h4 className="welcome-to-text mb-0">Welcome to</h4>
            <h1 className="">{window.location.hostname}</h1>
            <div className="sub-heading domain-courtesy-text mt-2">
              This domain has been parked for free, courtesy of{" "}
              <a target="_blank" href={`https://offersclub.com/?sub3=${sub3}&utm_source=parkpage&utm_medium=banner&utm_campaign=${sub3}`}>
                offersclub.com
              </a>
            </div>
          </div>
          <div className="col-12 text-center">
            <div className="sub-heading pink-background">
              While you are here check our great deals from brands you love.
            </div>
          </div>
        </div>
      </WelcomeTravelHeaderContent>
    </React.Fragment>
  );
}

export default WelcomeTravelHeader;
