export const SignUpFormConstant = [
    {
      type: 'input',
      subType: 'text',
      id: 'inputEmailSignup',
      label: 'Email Address',
      placeholder: 'Email Address',
      key: 'email',
      formClass: 'form-control',
      required: true
    },
    {
      type: 'input',
      subType: 'password',
      id: 'inputPasswordSignup',
      label: 'Password',
      placeholder: 'Password',
      key: 'password',
      formClass: 'form-control',
      required: true
    }
  ];

  export const VerificationFormConstant = [{
      type: 'input',
      subType: 'text',
      id: 'inputEmailVerification',
      label: 'Email Address',
      placeholder: 'Email Address',
      key: 'email',
      formClass: 'form-control',
      required: true
  }]