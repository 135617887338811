import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import MyDetails from "./MyDetails";
import EmailNotifications from "./EmailNotifications";
import Sidebar from './Sidebar';
import { AccountContainerOuter } from "../../../theme/css/Global";

function MyAccountContainer() {
  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-12 pt-3 mt-5 mb-4">
            <h1 className="semi">Account</h1>
          </div>
        </div>
        <AccountContainerOuter>
          <div className="row">
            <Sidebar />
            <Switch>
              <Route path="/account/mydetails" component={MyDetails} />
              <Redirect from="/account/notifications" to="/unsubscribe" />
              <Route exact path="/account/notifications" component={EmailNotifications} />
              <Redirect from="/account" to="/account/mydetails" />
            </Switch>
          </div>
        </AccountContainerOuter>
      </div>
    </React.Fragment>
  );
}

export default MyAccountContainer;

