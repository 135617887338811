export const ResetPasswordFormConstant = [
  {
    type: "input",
    subType: "password",
    id: "resetPassword",
    label: "Password",
    placeholder: "Password",
    key: "password",
    formClass: 'form-control',
    required: true,
  }
  // {
  //   type: "input",
  //   subType: "password",
  //   id: "inputConfirmPassword",
  //   label: "Confirm Password",
  //   placeholder: "Confirm Password",
  //   key: "confirm_password",
  //   formClass: 'form-control',
  //   required: true,
  // }
];
