import React from 'react';
import FormField from './FormField';

export const getFields = (
  fields,
  data,
  error,
  handleOnChange,
  handleOnClick,
  handleOnBlur,
  handleEditField,
  isAdmin,
  defaultOptions = {},
  typeAheadDatas = {},
  condition = {}
) => {
  return fields.map((field) => {
    return !Object.keys(condition).includes(field.key) ||
      condition[field.key] ? (
      <div key={field.key} style={{padding: field.subType === 'checkbox' ? '0 30px' : '0'}}>
        <FormField
          {...field}
          id={field.randomId ? field.id + '-' + Math.random() : field.id}
          inputClass={field.formClass}
          value={data[field.key]}
          onChange={handleOnChange}
          onClick={handleOnClick}
          onBlur={handleOnBlur}
          onEditField={handleEditField}
          fieldKey={field.key}
          error={error && error[field.key]}
          typeAheadData={
            typeAheadDatas[field.key] ? typeAheadDatas[field.key] : () => {}
          }
          options={
            defaultOptions[field.key]
              ? defaultOptions[field.key]
              : field.options
          }
          isAdmin={isAdmin}
          readOnly={field.readOnly}
          showEditButton={field.showEditButton}
          fieldFor={field.fieldFor}
        />
      </div>
    ) : (
      ''
    );
  });
};
