import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

import { ErrorLabel } from "../../theme/css/Global";

/* CSS Theme */
const NumberField = (props) => {
  const fieldChange = (event) => {
    props.onChange({
      key: props.fieldKey,
      value: event.target.value, //.replace(/[^0-9]/g, ""),
    });
  };

  const {
    label,
    name,
    value,
    id,
    format,
    placeholder,
    error,
    fieldFor,
    inputClass,
    decimalScale,
  } = props;

  return fieldFor === "userInfo" ? (
    <React.Fragment>
      <div className="form-group">
        <label htmlFor={id}>{label}</label>
        <NumberFormat
          className={inputClass}
          value={value}
          format={format}
          name={name}
          onChange={(event) => fieldChange(event)}
          placeholder={placeholder}
        />
        {error && error.length !== 0 ? (
          <div>
            <ErrorLabel>{error}</ErrorLabel>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="form-label-group">
        <NumberFormat
          className={inputClass}
          format={format}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={(event) => fieldChange(event)}
          isAllowed={(value) => {
            const { formattedValue, floatValue } = value;
            return formattedValue === "" || floatValue <= 100000;
          }}
          decimalScale={decimalScale}
        />
        {/* <label htmlFor={id}>{label}</label> */}
        {error && error.length !== 0 ? (
          <div>
            <ErrorLabel>{error}</ErrorLabel>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

NumberField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  error: PropTypes.array,
};

export default NumberField;
