import React from "react";
import {withRouter} from "react-router-dom";

function MedicarePartners(props) {
	const redirectToPage = (redirectLink) => {
		window.scrollTo(0, 0);
		props.history.push({
			pathname: redirectLink,
		});
	};
	return (
		<React.Fragment>
			<div className="container pt-5 pb-5">
				<div className="row">
					<div className="col-12">
						<h2 className="pb-5">Partners</h2>
						<p>
							Last updated: Jan 11, 2021
						</p>
                        <ul>
                            <li>5 Mile Media</li>
                            <li>ABS Healthcare</li>
                            <li>Ace Solutions</li>
                            <li>Admaric</li>
                            <li>Admediary</li>
                            <li>Adsparkx Digital</li>
                            <li>Advantage One Insurance</li>
                            <li>Advocator Group</li>
                            <li>Aetna</li>
                            <li>AgedLeadStore.com</li>
                            <li>AHCP</li>
                            <li>AIG</li>
                            <li>All Digital Promotions</li>
                            <li>All Web Leads</li>
                            <li>Allied Insurance</li>
                            <li>Allied Insurance Partners (view list)</li>
                            <li>Allstarz</li>
                            <li>Alphatech Resource Holdings s.r.o.</li>
                            <li>America’s Health Advisors</li>
                            <li>American Continental</li>
                            <li>American Disability Helpline</li>
                            <li>American Family</li>
                            <li>American Health Reform Solutions</li>
                            <li>American Insurance Organization, LLC</li>
                            <li>American Republic</li>
                            <li>American Select Health</li>
                            <li>Andy Montague Region</li>
                            <li>Anthem/BCBS</li>
                            <li>Apollo Interactive</li>
                            <li>Assurant, Bankers Life and Casualty</li>
                            <li>ATI Agency</li>
                            <li>Audience Serv</li>
                            <li>Bankrate</li>
                            <li>BBRS Group</li>
                            <li>Beacon Leads, LLC</li>
                            <li>Benefit Advisors</li>
                            <li>BestQuotes</li>
                            <li>Blue Nile</li>
                            <li>BlueCross/Blue Shield Association Companies</li>
                            <li>Bold Media Group</li>
                            <li>Boost</li>
                            <li>Boost Health Insurance</li>
                            <li>Boost Marketing Inc</li>
                            <li>Btel</li>
                            <li>Btel Communications</li>
                            <li>C3 Data, LLC</li>
                            <li>Call Center Partnerships</li>
                            <li>Cedar Tree Media</li>
                            <li>Cege Media</li>
                            <li>Choice Direct</li>
                            <li>Cigna, Coventry, Fortis</li>
                            <li>Citirex</li>
                            <li>Citizens Disability</li>
                            <li>Clean Energy Concepts</li>
                            <li>Clearlink</li>
                            <li>Connect Plus</li>
                            <li>Connect Rite Bpo</li>
                            <li>Contactability</li>
                            <li>Corner Shop Media</li>
                            <li>Crosspointe Insurance Advisors</li>
                            <li>D and D Investment Specialist</li>
                            <li>Datalot</li>
                            <li>Datwerx</li>
                            <li>Digital Market Media</li>
                            <li>Digital Market Media</li>
                            <li>Disability Advisor</li>
                            <li>DiscoverBPO</li>
                            <li>DKS Marketing</li>
                            <li>e-TeleQuote Insurance, Inc.</li>
                            <li>Emerald Enterprise Corp</li>
                            <li>Energy Alliance</li>
                            <li>Equoto</li>
                            <li>Excel Impact</li>
                            <li>Family First Health Plans</li>
                            <li>FCHCN Insurance Agency</li>
                            <li>Field of Leads Ltd.</li>
                            <li>Fields of Leads</li>
                            <li>Final Expense Assistant</li>
                            <li>Financial Grade</li>
                            <li>First Family</li>
                            <li>FirstAmericanFinancial</li>
                            <li>Flyhigh</li>
                            <li>Genworth Financial</li>
                            <li>Gerber Life</li>
                            <li>GHI</li>
                            <li>GigUp</li>
                            <li>Globalsky BPO</li>
                            <li>GoHealth</li>
                            <li>Golden Rule</li>
                            <li>Grey Peaks</li>
                            <li>Guardian Benefits</li>
                            <li>Guide to Insure</li>
                            <li>Guidestar Marketing Group LLC</li>
                            <li>HasTraffic</li>
                            <li>HauteProspects</li>
                            <li>Health Benefits Center</li>
                            <li>Health Benefits One</li>
                            <li>Health Dime</li>
                            <li>Health Insurance Advantage</li>
                            <li>Health Insurance Services</li>
                            <li>Health Net</li>
                            <li>Healthcare.com</li>
                            <li>HealthIQ</li>
                            <li>HealthMarkets/Insphere</li>
                            <li>HealthPlanOne</li>
                            <li>HealthSpire Calls</li>
                            <li>Heard and Smith</li>
                            <li>Highmark</li>
                            <li>HK Flavors Limited</li>
                            <li>Home Bundles</li>
                            <li>Home Path</li>
                            <li>Home Savings</li>
                            <li>HP One</li>
                            <li>HPO</li>
                            <li>Humana</li>
                            <li>iCall Solutions</li>
                            <li>Ideal Concepts</li>
                            <li>Ideal Concepts, Inc</li>
                            <li>Innovate Financial Group</li>
                            <li>Inside Response</li>
                            <li>Insphere</li>
                            <li>Insuralife</li>
                            <li>Insurance Guide</li>
                            <li>Insurance Line One</li>
                            <li>Insurance Services</li>
                            <li>Insurance Solutions Direct LLC</li>
                            <li>Insurance Solutions LLC</li>
                            <li>Kaiser, Medicare Solutions</li>
                            <li>Kallstar</li>
                            <li>Kevin Ferrell Inc</li>
                            <li>Lead Origins</li>
                            <li>Lead Realm LLC</li>
                            <li>Legends United Insurance Agency</li>
                            <li>Lender Daily</li>
                            <li>Lendmarx</li>
                            <li>Lighthouse Insurance Group</li>
                            <li>Line East Leads</li>
                            <li>ListenClear</li>
                            <li>LizDev</li>
                            <li>Media Alpha</li>
                            <li>Medicare Concepts, LLC</li>
                            <li>MT Leads</li>
                            <li>Mutual of Omaha</li>
                            <li>My Health Angel</li>
                            <li>Narrate LLC</li>
                            <li>National Disability</li>
                            <li>National Health Connect LLC</li>
                            <li>National Health Hub LLC</li>
                            <li>National Healthcare Advisors</li>
                            <li>Nationwide Health Advisors</li>
                            <li>Next Wave Marketing</li>
                            <li>Next Wave Marketing Strategies, Inc</li>
                            <li>Northwest Medical</li>
                            <li>Number One Health Insurance Agency</li>
                            <li>OFFERweb</li>
                            <li>Onyx Digital Media</li>
                            <li>OpenJar Concepts</li>
                            <li>Otis Production LLC</li>
                            <li>Palo Media Group</li>
                            <li>PCS</li>
                            <li>Peak Performance</li>
                            <li>Ping Leads</li>
                            <li>Pinnacle Publishers LLC</li>
                            <li>Policy Scout</li>
                            <li>PolicyBind</li>
                            <li>PolicyFetch Inc</li>
                            <li>PolicyScout</li>
                            <li>Power Credit Solutions</li>
                            <li>Power Source Marketing</li>
                            <li>Power Source Marketing LLC</li>
                            <li>Practical Marketing</li>
                            <li>Precise Leads</li>
                            <li>Premier Disability</li>
                            <li>Premier Health Advisors, Inc.</li>
                            <li>Premier Health Associates</li>
                            <li>Presidio Interactive</li>
                            <li>Presidio Interactive Corp</li>
                            <li>Purple Dog Marketing LLC</li>
                            <li>PX</li>
                            <li>Q3M Insurance Solutions</li>
                            <li>Qatalyst</li>
                            <li>Quantum 3 Media LLC</li>
                            <li>Quantum Digital Media</li>
                            <li>QuickQuote</li>
                            <li>QuinStreet</li>
                            <li>Quote Velocity</li>
                            <li>QuoteFire</li>
                            <li>QuoteStorm</li>
                            <li>Quotewizard</li>
                            <li>RateMarketPlace</li>
                            <li>Reliable Partners (Trips Marketing)</li>
                            <li>Resource Marketing Corp.</li>
                            <li>ReviMedia</li>
                            <li>Rogue E-Cigs</li>
                            <li>S.B. Fintech Ltd</li>
                            <li>Seascape</li>
                            <li>Seascape Health</li>
                            <li>Seascape Life</li>
                            <li>Secure Horizons</li>
                            <li>Select Quote</li>
                            <li>SelectQuote</li>
                            <li>SeniorCare USA</li>
                            <li>Simple Health</li>
                            <li>Simple Health Plans Inc.</li>
                            <li>Simple Insurance Leads</li>
                            <li>Singularity Media</li>
                            <li>Smart Match Insurance Agency</li>
                            <li>SolidQuote</li>
                            <li>Spring EQ</li>
                            <li>Spring Venture Group</li>
                            <li>SS Telemarketing</li>
                            <li>Sterling</li>
                            <li>SubjectWell</li>
                            <li>Sun City Financial LLC</li>
                            <li>Support First</li>
                            <li>SVG</li>
                            <li>Synergy Marketing Associates</li>
                            <li>Tallac Media</li>
                            <li>The Medicare Assistant</li>
                            <li>Thrive Marketing</li>
                            <li>Tiger Health Solutions</li>
                            <li>Torchlight Technology Group LLC</li>
                            <li>TradeMarc Global, LLC</li>
                            <li>Traffic Panda</li>
                            <li>Trips Marketing LLC</li>
                            <li>Trusted Consumer Advisors</li>
                            <li>TZ Insurance Solutions LLC</li>
                            <li>United Access insurance</li>
                            <li>United Healthcare</li>
                            <li>United Insurance Group Agency, Inc.</li>
                            <li>United Medicare</li>
                            <li>United Medicare Advisors</li>
                            <li>Universal American</li>
                            <li>US Health Advisors</li>
                            <li>US Insurance Advisors, LLC</li>
                            <li>US Marketing Group</li>
                            <li>USHEALTH Advisors</li>
                            <li>Vertigen LLC</li>
                            <li>Visiqua</li>
                            <li>Voxify</li>
                            <li>Voxify LLC</li>
                            <li>WeCall</li>
                            <li>WellPoint</li>
                            <li>West Coast Closers</li>
                            <li>Yasha Marketing</li>
                            </ul>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default withRouter(MedicarePartners);
