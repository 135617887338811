import React, { useState } from "react";
import PropTypes from "prop-types";
import Pagination from "react-js-pagination";
import { PaginationStyled } from "../../theme/css/Global";

export const PaginationComponent = (props) => {
  const [ActivePage, setActivePage] = useState(1);
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    props.onChange({
      pageNumber: pageNumber,
    });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <PaginationStyled>
            <Pagination
              hideDisabled
              itemClass="page-item"
              linkClass="page-link"
              activePage={props.activePage ? props.activePage : ActivePage}
              itemsCountPerPage={25}
              totalItemsCount={props.totalItemsCount}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
          </PaginationStyled>
        </div>
      </div>
    </div>
  );
};

PaginationComponent.propTypes = {
  activePage: PropTypes.number,
  totalItemsCount: PropTypes.number,
  onChange: PropTypes.func,
};
