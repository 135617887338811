import React from "react";
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";
import { ErrorLabel } from '../../theme/css/Global';

const ChoiceField = (props) => {
  const {
    placeholder,
    name,
    options,
    value,
    subType,
    error,
    id,
    label,
    inputClass,
    // disabled,
    // question,
    isAdmin
  } = props;

  const fieldChange = (event, subType) => {
    if (subType === "multiChoice") {
      props.onChange({
        key: props.fieldKey,
        value: event.target.value,
      });
    } else {
      props.onChange({
        key: props.fieldKey,
        value: event.target.value,
      });
    }
  };

  const getOptionLabel = (option) => {
    return props.keyLabel ? option[props.keyLabel] : option.label;
  };

  const getOptionValue = (option) => {
    return props.keyValue ? option[props.keyValue] : option.value;
  };

  const getOptions = () => {
    return options.map((option) => {
      return (
        <option key={getOptionValue(option)} value={getOptionValue(option)}>
          {getOptionLabel(option)}
        </option>
      );
    });
  };
  const renderValue = (selected) => {
    if (subType === "multiChoice") {
      return (
        <div style={{ display: "grid", margin: "-7px 0px" }}>
          {selected.map((value) => (
            <Chip key={value} label={value} style={{ marginBottom: 1 }} />
          ))}
        </div>
      );
    }
    return selected;
  };

  return (
    <React.Fragment>
    <div className="form-group">
      <label htmlFor={id}>{label}</label>
      <select
        className={inputClass}
        name={name}
        id={id}
        placeholder={placeholder}
        value={subType === "multiChoice" ? (value ? value : []) : value}
        onChange={(event) => fieldChange(event, subType)}
        multiple={subType === "multiChoice"}
        rendervalue={renderValue()}
        disabled={isAdmin}
      >
        {getOptions()}
      </select>
      <div>{error ? <ErrorLabel>{error}</ErrorLabel> : ''}</div>
    </div>
    </React.Fragment>
  );
};

ChoiceField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  subType: PropTypes.string,
  keyValue: PropTypes.string,
  keyLabel: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  question: PropTypes.bool,
};

export default ChoiceField;
