import React, { useEffect, useState } from "react";
import { ToastsStore } from "react-toasts";
import apiClient from "../../../store/actions/ApiInterceptor";
import ImageSlider from "../../shared/imageslider/ImageSlider";
import useShuffledData from "../../../hooks/useShuffleData/useShuffledData";

const _ = require("lodash");

function Brands(props) {
  // const [isLoading, setIsLoading] = useState(false);
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    getBrands();
  }, []);

  const getBrands = () => {
    // setIsLoading(true);
    apiClient
      .get("brand/")
      .then((res) => {
        // setIsLoading(false);
        setBrands(res.data);
      })
      .catch((error) => {
        // setIsLoading(false);
        ToastsStore.error("Unable to fetch brands.");
      });
  };

  const shuffledBrands = useShuffledData(brands.results)

  return (
    <React.Fragment>
      {_.get(brands, "results") ? (
        <div>
          <div className="container">
            <div className="row">
              <div className="col-12 pt-3">
                <h4>Popular Brands</h4>
              </div>
            </div>
          </div>
          <div className="shadow-padding brand-slider">
            <ImageSlider
              brands={true}
              sliderData={shuffledBrands}
              sliderFor="circle"
              sliderType="brand_name"
              {...props}
            />
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
}

export default Brands;
