export const SignInFormConstant = [
  {
    type: 'input',
    subType: 'text',
    id: 'inputEmail',
    label: 'Email Address',
    placeholder: 'Email',
    key: 'email',
    formClass: 'form-control',
    required: true
  },
  {
    type: 'input',
    subType: 'password',
    id: 'inputPassword',
    label: 'Password',
    placeholder: 'Password',
    key: 'password',
    formClass: 'form-control',
    required: true
  }
];