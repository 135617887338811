export const ForgotPasswordFormConstant = [
  {
    type: 'input',
    subType: 'text',
    id: 'inputEmailForgot',
    label: 'Confirm Email',
    placeholder: 'Email',
    key: 'email',
    formClass: 'form-control',
    required: true
  }
];