import React from "react";
import PropTypes from "prop-types";

// Components
import InputField from "./InputField";
import ChoiceField from "./ChoiceField";
import NumberField from "./NumberField";
import DatePicker from "./DatePicker";
import CheckboxField  from "./CheckboxField";

const FormField = (props) => {
  const { type } = props;
  switch (type) {
    case "input":
      return <InputField {...props} />;
    case "choices":
      return <ChoiceField {...props} />;
    case "number":
      return <NumberField {...props} />;
    case "date":
      return <DatePicker {...props} />;
      case "checkbox":
      return <CheckboxField {...props} />;
    default:
      return null;
  }
};

FormField.propTypes = {
  type: PropTypes.string,
};

export default FormField;
