import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactCrop from "react-image-crop";

import CircleLoader from "../shared/CircleLoader";
import "react-image-crop/dist/ReactCrop.css";
import * as commonAction from "../../store/actions/CommonActions";
import * as actions from "../../store/actions";
import { Button, ErrorLabel } from "../../theme/css/Global";
import { Theme } from "../../theme/css/theme";

// const customStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     zIndex: '9999999 !important',
//     width: '50%',
//     height: '60%'
//   }
// };

function FileCrop(props) {
  const [imageRef, setImageRef] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [imageBlob, setImageBlob] = useState(null);
  const [imageCropped, setImageCropped] = useState(null);
  const [imageFileName, setImageFileName] = useState(null);
  const [profilePhotoLoader, setprofilePhotoLoader] = useState(false);
  const [invalidPhotoError, setInvalidPhotoError] = useState(null);
  const [imageStyle, setImageStyle] = useState({});
  const state = { mediaId: null };

  const [crop, setCrop] = useState({
    unit: "%",
    x: 20,
    y: 20,
    width: 30,
    // height: 30,
    aspect: 16/16,
  });

  useEffect(() => {
    if (imageSrc === null) {
      setImageSrc(imageSrc);
    }
    if (props.isClear) {
      clearFile();
      props.onClick();
    }
  }, [imageSrc, props.isClear]);

  const clearFile = () => {
    setImageRef(null);
    setImageSrc(null);
    setImageBlob(null);
    setImageCropped(null);
    setInvalidPhotoError(null);
    window.$("#fileInput").val("");
  };

  const onImageLoaded = (image) => {
    setImageRef(image);
  };

  const onCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  const getCroppedImage = (croppedImage) => {
    setImageStyle({
      height: croppedImage.height,
      width: croppedImage.width,
    });
    if (
      imageRef &&
      imageRef.naturalHeight &&
      imageRef.naturalWidth &&
      imageRef.width &&
      imageRef.height
    ) {
      const canvas = document.createElement("canvas");
      const scaleX = imageRef.naturalWidth / imageRef.width;
      const scaleY = imageRef.naturalHeight / imageRef.height;
      canvas.width = croppedImage.width;
      canvas.height = croppedImage.height;
      const ctx = canvas.getContext("2d");

      ctx.drawImage(
        imageRef,
        croppedImage.x * scaleX,
        croppedImage.y * scaleY,
        croppedImage.width * scaleX,
        croppedImage.height * scaleY,
        0,
        0,
        croppedImage.width,
        croppedImage.height
      );
      canvas.toBlob(function (blob) {
        if (blob) {
          blob.name = imageFileName;
          setImageBlob(blob);
          setImageCropped(window.URL.createObjectURL(blob));
        }
      }, "image/" + imageFileName.split(".", 2).pop());
    }
  };

  const onCropComplete = (completeCrop, percentCrop) => {
    const cropcroppedUrl = getCroppedImage(completeCrop); //eslint-disable-line
  };

  const uploadCroppedFile = async () => {
    setprofilePhotoLoader(true);
    if (imageBlob && imageCropped) {
      let uploadFile = new File([imageBlob], imageBlob.name)
      await commonAction.uploadProfilePhoto(props.userId, uploadFile)
      setprofilePhotoLoader(false);
      props.updateProfilePhoto();
      window.$("#profilePhotoModal").modal("hide");
      await props.getUserDetail();
    }
  };

  const onSelectFile = (e) => {
    const fileType = e.target.files[0].type.split("/")[1];
    const validFileTypes = ["png", "jpg", "jpeg"];
    if (validFileTypes.includes(fileType)) {
      setInvalidPhotoError(null);
      if (e.target.files && e.target.files.length > 0) {
        const reader = new FileReader();
        setImageFileName(e.target.files[0].name);
        reader.addEventListener("load", () => setImageSrc(reader.result));
        reader.readAsDataURL(e.target.files[0]);
      }
    } else {
      setInvalidPhotoError("Invalid file type");
    }
  };

  return profilePhotoLoader ? (
    <CircleLoader />
  ) : (
    <CropImage>
      <div className="App text-left">
        <div className="mb-2">
          <input
            id="fileInput"
            type="file"
            accept="image/*"
            onChange={onSelectFile}
          />
        </div>
        <div className="row">
          <div className="col-lg-6 mt-4">
            {imageSrc && (
              <ReactCrop
                src={imageSrc}
                crop={crop}
                ruleOfThirds
                onImageLoaded={onImageLoaded}
                onComplete={onCropComplete}
                onChange={onCropChange}
                className="react-crop-img"
              />
            )}
          </div>
          <div className="col-lg-6 mt-lg-0 mt-4">
            {imageCropped && (
              <React.Fragment>
                <label>
                  <b>Image Preview</b>
                </label>
                <div>
                  <ImagePreview
                    alt="Crop"
                    imageStyle={imageStyle}
                    src={imageCropped}
                  />
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        {invalidPhotoError ? <ErrorLabel>{invalidPhotoError}</ErrorLabel> : ""}
        {imageBlob ? (
          <Button
            type="button"
            className="btn btn-primary mt-4"
            onClick={uploadCroppedFile}
          >
            Update
          </Button>
        ) : (
          ""
        )}
      </div>
    </CropImage>
  );
}

FileCrop.propTypes = {
  userDetails: PropTypes.object,
  updateProfilePhoto: PropTypes.func,
  updatePresignedUrl: PropTypes.func,
  getUserDetail: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserDetail: () => dispatch(actions.getUserDetails()),
  };
};

export default connect(null, mapDispatchToProps)(FileCrop);

const CropImage = styled.div`
  .img-crop {
    width: 100%;
    vertical-align: top;
    height: 250px;
  }

  .btn-primary {
    font-size: calc(${Theme["$medium-f-size"]} - 1px);
  }
`;

const ImagePreview = styled.img`
  height: ${(props) => props.imageStyle.height}px;
  width: ${(props) => props.imageStyle.width}px;
`;
