import React, {useState, useEffect} from "react";
import {BodyContent, NewsletterSignUp} from "../../../theme/css/Global";
import HeaderSlider from "../imageSlider/HeaderSlider";
import Categories from "./Categories";
import SmallImageSliderComponent from "./SmallImageSlider";
import Brands from "./Brands";
import BigImageSlider from "./BigImageSlider";
import OffersDetailsModal from "./OffersDetailsModal";
import CircleLoader from "../../shared/CircleLoader";
import * as actions from "../../../store/actions";
import {useDispatch, useSelector} from "react-redux";
import SendImgBlue from "../../../theme/images/sedb.png";
import {ErrorLabel} from "../../../theme/css/Global";
import WelcomeTravelHeader from './WelcomeTravelHeader';

const _ = require("lodash");

function OffersDashboard(props) {
	const [offerDetailsData, setOfferDetailsData] = useState({});
	const [isClear, setIsClear] = useState(false);
	// const [emailId, setEmailId] = useState({email: ""});
	const [showNewsletterPopup, setShowNewsletterPopup] = useState(false);
	const dispatch = useDispatch();
	// const [bounce, setBounce] = useState(null);
	let emailId = {email: ""};
	useEffect(() => {
		localStorage.setItem("refreshPageForMobile", false);
		const data = localStorage.getItem('newsletter_shown')
		if (!JSON.parse(data)) {
			setTimeout(() => {
				// setShowNewsletterPopup(true);
				localStorage.setItem('newsletter_shown', true);
			}, 1000 * 15);
		}
	}, []);

	/*useEffect(() => {
		if (window.mobileCheck()) {
			const calculateScrollSpeed = (function () { //Function that checks the speed of scrolling
				let last_position, new_position, timer, delta, delay = 50;

				function clear() {
					last_position = null;
					delta = 0;
				}

				clear();
				return function () {
					new_position = window.scrollY;
					if (last_position != null) {
						delta = new_position - last_position;
					}
					last_position = new_position;
					clearTimeout(timer);
					timer = setTimeout(clear, delay);
					return delta;
				};
			})();
			const checkScroll = (event) => {
				if (calculateScrollSpeed() < -150) {
					if (!showNewsletterPopup) {
						setShowNewsletterPopup(true)
					}
				}
			}
			if (!showNewsletterPopup) {
				document.addEventListener('scroll', checkScroll);
				// window.addEventListener('scroll', checkScroll);
			} else {
				document.removeEventListener('scroll', checkScroll);

			}
			return () => document.removeEventListener('scroll', checkScroll);
		}

	}, [showNewsletterPopup, setShowNewsletterPopup]);*/

	/*useEffect(() => {
		const onLeaving = (event) => {
			setBounce(setTimeout(() => setShowNewsletterPopup(true), 5000));
		}
		if (window.mobileCheck()) {

		} else {

			if (!showNewsletterPopup) {
				document.addEventListener('mouseleave', onLeaving);
			} else {
				document.removeEventListener('mouseleave', onLeaving)
			}

		}
		return () => document.removeEventListener('mouseleave', onLeaving);
	}, [showNewsletterPopup, setShowNewsletterPopup]);*/

	/*useEffect(() => {
		const onEnter = (event) => {
			if (bounce) {
				clearTimeout(bounce);
				setBounce(null);
			}
		}
		if (window.mobileCheck()) {

		} else {

			if (bounce) {
				document.addEventListener('mouseenter', onEnter);
			} else {
				document.removeEventListener('mouseenter', onEnter);
			}
		}
		return () => document.removeEventListener('mouseenter', onEnter);

	}, [bounce, setBounce]);*/

	const {emailDetails, isLoading} = useSelector((state) => ({
		emailDetails: state.userState.registerEmailDetails,
		isLoading: state.userState.registerEmailLoading,
	}));
	let error = useSelector((state) => state.userState.registerEmailError);
	// console.log(emailDetails, isLoading, error)
	const handleFieldChange = (event) => {
		emailId = {email: event.target.value};
		error[event.target.id] = "";
	};

	const handleSubmit = (event) => {
		event.preventDefault(true);
		dispatch(actions.registerEmailId(emailId.email));
	};

	const clearError = () => {
		emailId = {email: ""};
		dispatch(actions.clearEmailError());
	};

	useEffect(() => {
		if (_.get(emailDetails, 'id') && showNewsletterPopup) {
			setShowNewsletterPopup(false);
		}
	}, [emailDetails])
	useEffect(() => {
		setTimeout(() => {
			if (_.get(error, "email")) {
				clearError();
			}
		}, 5000)
	}, [error])


	const handleClick = ({selectedOffer}) => {
		const sub3 = encodeURIComponent(window.location.hostname.replaceAll('.', '__'));

		window.gtag('event', 'select_promotion', {
			promotion_id: selectedOffer.id,
			promotion_name: selectedOffer.title,
		});
		setOfferDetailsData(selectedOffer);
		setIsClear(true);
		// window.$("#offerDetailsModal").modal("show");
		window.open(selectedOffer.link + `&sub3=` + sub3);
		// console.log(selectedOffer)
		
	};

	const newsletterPopup = <div className="popup_newsletter_overlay">
		<div className={window.mobileCheck() ? "column popup_newsletter" : "row popup_newsletter"}>
							<span className="closeBtn"
								  onClick={() => setShowNewsletterPopup(false)}>&times;</span>
			<div className="col-md-6 col-sm-12 popup_newsletter__photo">

			</div>
			<div className="col-md-6 col-sm-12 d-flex">

				<NewsletterSignUp className="h-100">
					<div
						className="d-flex flex-column align-items-start justify-content-center flex-wrap  h-100">
						<p className="join-newsletter_normal">
							Before you go...
						</p>
						<p className="join-newsletter_title" style={{
							fontSize: 30,
							color: '#fff',
							fontFamily: 'Gilroy-Bold'
						}}>
							Join Our Newsletter!
						</p>
						<p className="join-newsletter_normal">
							Stay up to date on all the latest offers
						</p>
						<form onSubmit={handleSubmit}>
							<div className="form-group mt-4">
								<input
									type="email"
									id="email_dash"
									placeholder="Enter your email address"
									className="form-control"
									// value={emailId.email}
									style={{backgroundColor: '#ffffff', color: '#000000', padding: '0 40px 0 25px'}}
									onChange={(event) => handleFieldChange(event)}
								/>
								<button type="submit" className="send-btn">
									{isLoading ? (
										<CircleLoader size={20}/>
									) : (
										<img src={SendImgBlue} alt="Send button"/>
									)}
								</button>
								{_.get(error, "email") ? (
									<div>
										<ErrorLabel>{error.email}</ErrorLabel>
									</div>
								) : null}
							</div>
						</form>
					</div>
				</NewsletterSignUp>
			</div>
		</div>
	</div>

return (
		<React.Fragment>
			<BodyContent className="home-page">

				{/* <HeaderSlider onClick={handleClick}/> */}
				{/* <div className="container">
					<div className="row">
						<div className="col-12 text-center">
							<h1 className="mt-md-3 mt-4">
								Great deals from <br className="d-lg-none d-block"/> brands
								you love.
							</h1>
							<h4 className="sub-heading">
								Search and save your favorite
								<br className="d-md-none d-block"/> products and services.{" "}
							</h4>
						</div>
					</div>
				</div> */}

				<WelcomeTravelHeader />

				<div className="slider-outer">
					<Categories {...props} />
					<SmallImageSliderComponent
						offerType={"Top Offers"}
						filters={{is_top_offer: true, page: 1}}
						{...props}
						onClick={handleClick}
					/>
					<Brands {...props} />
					<SmallImageSliderComponent
						offerType={"Retail Offers"}
						filters={{category_name: "Retail", page: 1}}
						{...props}
						onClick={handleClick}
					/>
					{localStorage.getItem("token") ? (
						<BigImageSlider
							offerType={"My Saved Offers"}
							filters={{page: 1}}
							{...props}
							onClick={handleClick}
						/>
					) : null}
					<SmallImageSliderComponent
						offerType={"Financial Offers"}
						filters={{category_name: "Financial", page: 1}}
						{...props}
						onClick={handleClick}
					/>
					<SmallImageSliderComponent
						offerType={"Health & Beauty Offers"}
						filters={{category_name: "Health & Beauty", page: 1}}
						{...props}
						onClick={handleClick}
					/>
					<SmallImageSliderComponent
						offerType={"Technology Offers"}
						filters={{category_name: "Technology", page: 1}}
						{...props}
						onClick={handleClick}
					/>
					<SmallImageSliderComponent
						offerType={"Home Services Offers"}
						filters={{category_name: "Home Services", page: 1}}
						{...props}
						onClick={handleClick}
					/>
					<SmallImageSliderComponent
						offerType={"Legal Offers"}
						filters={{category_name: "Legal", page: 1}}
						{...props}
						onClick={handleClick}
					/>
					<SmallImageSliderComponent
						offerType={"Insurance Offers"}
						filters={{category_name: "Insurance", page: 1}}
						{...props}
						onClick={handleClick}
					/>
					<SmallImageSliderComponent
						offerType={"Travel Offers"}
						filters={{category_name: "Travel", page: 1}}
						{...props}
						onClick={handleClick}
					/>
					<SmallImageSliderComponent
						offerType={"All Offers"}
						filters={{page: 1}}
						{...props}
						onClick={handleClick}
					/>
				</div>

				<OffersDetailsModal
					offerDetails={offerDetailsData}
					isClear={isClear}
					changeFlag={() => setIsClear(false)}
				/>
				{showNewsletterPopup && !localStorage.getItem("token") && !_.get(emailDetails, "id") && newsletterPopup}
			</BodyContent>
		</React.Fragment>
	);
}

export default OffersDashboard;
