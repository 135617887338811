import axios from "axios";
import * as actionTypes from "./ActionTypes";
import apiClient from "./ApiInterceptor";

export const loaderState = (flag) => {
  return {
    type: actionTypes.REGISTER_EMAIL_ID_LOADER_STATE,
    isLoading: flag,
  };
};

export const clearRegisterEmailId = () => {
  return {
    type: actionTypes.REGISTER_EMAIL_ID,
    emailDetails: {},
    isLoading: false,
    errorData: {}
  };
};

export const clearEmailError = () => {
  return {
    type: actionTypes.REGISTER_EMAIL_ID,
    isLoading: false,
    errorData: {}
  };
};

export const getUserDetails = () => {
  return (dispatch) => {
    apiClient
      .get("user/me/")
      .then((res) => {
        dispatch({
          type: actionTypes.GET_USER_DETAILS,
          UserDetails: res.data,
        });
      })
      .catch((error) => {});
  };
};

export const registerEmailId = (emaiId) => {
  return (dispatch) => {
    dispatch(loaderState(true));
    apiClient
      .post("/newsletter/", {
        email: emaiId,
      })
      .then((res) => {
        dispatch({
          type: actionTypes.REGISTER_EMAIL_ID,
          emailDetails: res.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SET_ERROR,
          errorData: error.response.data,
          isLoading: false,
        });
      });
  };
};

export const generatePreSignedUrl = async fileObj => {
  const data = {
    original_name: fileObj.name,
    status: 'requested',
    size: fileObj.size,
    mime_type: 'image/' + fileObj.name.split('.', 2).pop(),
    object_id: fileObj.object_id,
    content_type: fileObj.content_type
  };
  const preSignedUrl = await apiClient
    .post('/document/', data)
    .then(res => {
      return res.data;
    })
    .catch(() => {
      return {};
    });
  return preSignedUrl;
};

export const uploadFileOnAWS = async (preSignedUrl, file) => {
  const requestData = {
    url: preSignedUrl,
    headers: {
      'Content-type': 'image/' + file.name.split('.', 2).pop()
    },
    body: file
  };
  const awsResponse = await axios
    .put(requestData.url, requestData.body, {
      headers: requestData.headers
    })
    .then(res => {
      // updateDocumentStatus();
      return res;
    })
    .catch(error => {
      return {};
    });
  return awsResponse;
};

export const updateMediaStatus = async imageId => {
  const data = {
    status: 'completed'
  };
  const result = await apiClient //eslint-disable-line
    .patch('/document/' + imageId + '/', data)
    .then(res => {
      return res.data;
    })
    .catch(error => {});
};

export const uploadProfilePhoto = async (userId, imageObj) => {
  var formData = new FormData();
  formData.append("profile_photo", imageObj);
  await apiClient
    .patch(`user/${userId}/`, formData)
    .then(res => {
      return res.data;
    })
    .catch(error => {});
};

