import * as actionTypes from "../actions/ActionTypes";

const initialStates = {
  savedOfferDetails: {},
  isLoading: false,
  isSavedOfferDeleted: {},
  isUpdated: false,
  showToaster: null,
};

const MyOfferReducer = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.SAVE_OFFER:
      return {
        ...state,
        savedOfferDetails: action.offerDetails,
        isLoading: action.isLoading,
        isSavedOfferDeleted: action.deletedOffer,
        isUpdated: action.isUpdated,
        showToaster: action.showToaster
      };

    case actionTypes.UNSAVED_OFFER:
      return {
        ...state,
        isSavedOfferDeleted: action.deletedOffer,
        isLoading: action.isLoading,
        isUpdated: action.isUpdated,
        showToaster: action.showToaster
      };

    case actionTypes.LOADER_STATE:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case actionTypes.INITIALIZATION:
      return {
        ...state,
        savedOfferDetails: action.offerDetails,
        isSavedOfferDeleted: action.deletedOffer,
      };

    case actionTypes.TOGGLE_IS_UPDATED:
      return {
        ...state,
        isUpdated: action.isUpdated,
      };

    case actionTypes.TOGGLE_TOASTER:
      return {
        ...state,
        showToaster: action.showToaster,
      };
    default:
      return {
        ...state,
      };
  }
};

export default MyOfferReducer;
