import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { Theme } from "../../../theme/css/theme";
import { Button } from "../../../theme/css/Global";
import USAMapComponent from "../../shared/usamap/USAMapComponent";

import bbbLogo from "../../../theme/images/bbblogo.png";
import BackgroundImage from "../../../theme/images/featured-offer-medicaid-1.jpeg";
import Star5 from "../../../theme/images/6-0star.png";
import Star4 from "../../../theme/images/4-star.png";
import StarHalf4 from "../../../theme/images/5star.png";
import StarHalf3 from "../../../theme/images/3star.png";
import StarHalf2 from "../../../theme/images/2star.png";
import StarHalf1 from "../../../theme/images/1star.png";
import PlusIcon from "../../../theme/images/pluse.svg";
import MinusIcon from "../../../theme/images/minus.svg";

import { CompareOfferConstant } from "../../../constants/CompareOfferConstant";
import { getFields } from "../../shared/FieldRenderer";
import {Helmet} from "react-helmet";

function CompareOffer(props) {
  const [topzipCodeFormData, setTopzipCodeFormData] = useState({ zipCode: "" });
  const [bottompzipCodeFormData, setBottomzipCodeFormData] = useState({
    zipCode: "",
  });
  const [error, setError] = useState({ zipCode: "" });
  const [errorBotton, setErrorBotton] = useState({ zipCode: "" });
  const [checked, setChecked] = useState(false);

  const redirectToPage = (redirectLink) => {
    props.history.push({
      pathname: redirectLink,
    });
  };

  const handleFieldChange = ({ key, value }) => {
    setTopzipCodeFormData({ zipCode: value });
    setError({ zipCode: [] });
  };

  const handleFieldChangeBotton = ({ key, value }) => {
    setBottomzipCodeFormData({ zipCode: value });
    setErrorBotton({ zipCode: [] });
  };

  const handleSubmitTop = (event) => {
    event.preventDefault(true);
    redirectToPage(`/offer/medicaid/landing/${topzipCodeFormData.zipCode}`);
  };

  const handleSubmitBotton = (event) => {
    event.preventDefault(true);
    redirectToPage(`/offer/medicaid/landing/${bottompzipCodeFormData.zipCode}`);
  };

  const onStateClick = ({ state, zipCode }) => {
    props.history.push(
      {
        pathname: `/offer/medicaid/landing/${zipCode}`,
      },
      { state: state }
    );
    window.scrollTo(0, 0);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Medicare Supplement</title>
        <meta name="description" content="" />

      </Helmet>
      <CompareOfferContainer>
        <div className="banner-panel-outer">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-5 text-lg-left col-12">
                <div className="banner-text">
                  <div className="pink-link-btn">FREE Medigap Quote</div>
                  <h1>Compare 2021 Medicare Plan Options</h1>
                  <h4 className="sub-heading">
                    Enter zip code & compare rates to see how much you can save!
                  </h4>
                  <form onSubmit={handleSubmitTop}>
                    <div className="form-group start-my-qoute">
                      {getFields(
                        CompareOfferConstant,
                        topzipCodeFormData,
                        error,
                        handleFieldChange
                      )}
                      <Button
                        type="submit"
                        className="btn btn-primary"
                        disabled={topzipCodeFormData?.zipCode?.length < 5}
                      >
                        Start My Quote
                      </Button>
                    </div>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck"
                        name="example1"
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                        required
                      />
                      <label className="custom-control-label" for="customCheck">
                        I'm over 65 years old
                      </label>
                    </div>
                  </form>
                  <div className="mt-5">
                    <div className="float-left ml-3">
                      <p className="small">
                        By clicking the Button you are confirming that you are
                        agree with our Terms and Conditions
                      </p>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="to-shop-over">
                <h2 className="heading">
                  To shop over the phone call <span>855-848-0196</span>
                </h2>
                <div className="speak-with common-subtitle">
                  Speak with a licensed agent and get expert advice on choosing
                  a plan that's right for you
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <div className="map-content pb-2 pb-md-5">
                <h2 className="mb-0">Click Your State Below</h2>
                <div className="map-subtitle">
                  To get FREE medicare insurance quotes & save!
                </div>
                <USAMapComponent onClick={onStateClick} />
              </div>
            </div>
          </div>
        </div>

        <div className="medicare-supplement-plan">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="table-panel mx-auto">
                  <div className="plans-table mr-4">
                    <h6 className="text-center">
                      Medicare Supplement Plan Types
                    </h6>
                    <div className="table-radius">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th width="80%">Plan Letter</th>
                              <th width="2%">B</th>
                              <th width="2%">c</th>
                              <th width="2%">A</th>
                              <th width="2%">d</th>
                              <th width="2%">f</th>
                              <th width="2%">g</th>
                              <th width="2%">k</th>
                              <th width="2%">l</th>
                              <th width="2%">m</th>
                              <th width="2%">n</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>Basic Benefits</th>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                            </tr>
                            <tr>
                              <th>Part a deductible</th>
                              <td></td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td>50%</td>
                              <td>75%</td>
                              <td>50%</td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                            </tr>
                            <tr>
                              <th>skill nursing</th>
                              <td></td>
                              <td></td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td>50%</td>
                              <td>75%</td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                            </tr>

                            <tr>
                              <th>part b deductible</th>
                              <td></td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td></td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <th>part b excess</th>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <th>Foreign Travel</th>
                              <td></td>
                              <td></td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td></td>
                              <td></td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                              <td>
                                <span className="red-circle"></span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="plans-table plan-popularity">
                    <h6 className="text-center">
                      Medicare Supplement Plan Popularity
                    </h6>
                    <div className="table-radius">
                      <div className="table-responsive">
                        <table className="table plan-type">
                          <thead>
                            <tr>
                              <th width="55%">Plan Letter</th>
                              <th width="45%">Popularity</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>plan F</th>
                              <td>
                                <img src={Star5} alt="" />
                              </td>
                            </tr>
                            <tr>
                              <th>plan c</th>
                              <td>
                                <img src={Star4} alt="" />
                              </td>
                            </tr>
                            <tr>
                              <th>plan g</th>
                              <td>
                                <img src={StarHalf4} alt="" />
                              </td>
                            </tr>
                            <tr>
                              <th>plan n</th>
                              <td>
                                <img src={StarHalf3} alt="" />
                              </td>
                            </tr>
                            <tr>
                              <th>plan m</th>
                              <td>
                                <img src={StarHalf2} alt="" />
                              </td>
                            </tr>
                            <tr>
                              <th>plan a</th>
                              <td>
                                <img src={StarHalf1} alt="" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faq-panel">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="big-paragraph">
                  OffersClub.Com is not an insurance or operating
                  company but connects individuals with insurance providers.
                  Products and services are provided exclusively by the
                  providers. Descriptions are for informational purposes only
                  and subject to change. Insurance plans may not be available in
                  all states. For a complete description, please call
                  855-848-0196 to determine eligibility and to request a copy
                  of the applicable policy. OffersClub.Com is not
                  affiliated with any insurance plan nor does it represent or
                  endorse any plan. OffersClub.Com is not affiliated
                  with or endorsed by the United States government or the
                  federal Medicare program . By using this site, you acknowledge
                  that you have read and agree to the{" "}
                  <a href="/terms-and-conditions"> Terms of Service</a> and
                  <a href="/privacy-policy"> Privacy Policy.</a>
                  <br />
                  We are committed to protect your privacy. If you do not want
                  to share your information please click on Do Not Sell My
                  Personal Information for more details. Copyright © 2020-2021
                  OffersClub.Com. All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="compare-medigap-plans">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 text-center">
                <h2>Compare Medigap Plans</h2>
                <form onSubmit={handleSubmitBotton}>
                  <div className="form-group start-my-qoute mx-auto mt-4">
                    {getFields(
                      CompareOfferConstant,
                      bottompzipCodeFormData,
                      errorBotton,
                      handleFieldChangeBotton
                    )}
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={() => window.scrollTo(0, 0)}
                      disabled={bottompzipCodeFormData?.zipCode?.length < 5}
                    >
                      Start My Quote
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-lg-6 text-center circle">
                <h2>Speak with a licensed agent</h2>
                <h2>
                  <span>855-848-0196</span>
                </h2>
                <div className="common-subtitle">
                  Monday-Friday, 8am-8pm EST
                </div>
              </div>
            </div>
          </div>
        </div>
      </CompareOfferContainer>
    </React.Fragment>
  );
}

export default withRouter(CompareOffer);

const CompareOfferContainer = styled.div`
  #us-map {
    display: block;

    width: 100%;
    height: 100%;
  }
  path:hover,
  circle:hover {
    stroke: #3d8fff !important;
    stroke-width: 2px;
    stroke-linejoin: round;
    fill: #3d8fff !important;
    cursor: pointer;
  }
  #path67 {
    fill: none !important;
    stroke: #a9a9a9 !important;
    cursor: default;
  }

  #map-svg {
    height: 100%;
    width: 100%;
    position: relative;
  }
  #info-box {
    display: none;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.9);
    border: 2px solid #3d8fff;
    border-radius: 5px;
    color: #fff;
    padding: 5px;
  }
  rect:hover {
    stroke: #3d8fff !important;
    stroke-width: 2px;
    stroke-linejoin: round;
    fill: #3d8fff !important;
    cursor: pointer;
  }

  text {
    color: red;
  }

  .container {
    max-width: 1145px;
  }

  /* Header CSS START */

  .nav-up {
    top: -60px;
  }

  header.main-header {
    padding: 18px 0 18px 0;
  }

  header.main-header.nav-down {
    background: rgba(14, 17, 26, 0.7);
  }

  ul.header-icon-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  ul.header-icon-list li {
    display: inline-block;
    vertical-align: top;
  }
  ul.header-icon-list li:first-child {
    padding-right: 25px;
  }

  ul.header-icon-list li label {
    cursor: pointer;
  }

  ul.header-icon-list li label .hamburger:before {
    top: -7px;
    right: 0;
    content: "";
    width: 25px;
    height: 3px;
    border-radius: 3px;
    background: #fcfcfc;
    position: absolute;
    transition: 0.5s;
  }

  ul.header-icon-list li label .hamburger:after {
    top: 7px;
    right: 0;
    content: "";
    width: 25px;
    height: 3px;
    border-radius: 3px;
    background: #fcfcfc;
    position: absolute;
    transition: 0.5s;
  }

  header #menu-toggle {
    display: none;
  }

  ul.header-icon-list li label span {
    display: block;
    position: relative;
    margin: 0 0 3px 0;
    width: 20px;
    height: 2px;
    background: #fcfcfc;
    transition: 0.5s;
    z-index: 99999;
    transform-origin: 11px 0px;
    cursor: pointer;
  }

  ul.header-icon-list li label span:first-child {
    transform-origin: 0% 0%;
  }

  ul.header-icon-list li label span:nth-last-child(2) {
    transform-origin: 60% 100%;
  }

  header #menu-toggle:checked ~ .main-navigation {
    display: block;
  }

  header #menu-toggle:checked + label span {
    opacity: 1;
    transform: rotate(45deg) translate(-3px, -2px);
  }

  header #menu-toggle:checked + label span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  header #menu-toggle:checked + label span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }

  header .main-navigation {
    display: none;
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;
    width: 50%;
    height: 100%;
    border-radius: 0px;
    padding: 40px 25px 20px;
    text-align: left;
    box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.2);
    font-family: "Helvetica-Neue-Reg";
    color: #ffffff;
  }

  header .main-navigation ul {
    list-style-type: none;
    margin: 0;
    padding: 30px 0 0 0;
  }

  header .main-navigation ul.nav-links li {
    padding: 2px 0px;
    display: block;
  }
  header .main-navigation ul.nav-links li a {
    transition: 0.3s;
    color: #ffffff;
    font-size: 32px;
  }

  header .main-navigation ul.nav-links li a:hover {
    padding-left: 5px;
    color: #64a3ff;
  }

  header .bottom-navigation {
    position: absolute;
    bottom: 10px;
  }

  header .main-navigation ul.social-icons li {
    display: inline-block;
    padding: 0;
    margin-right: 8px;
  }

  header .main-navigation ul.rights li {
    display: inline-block;
    color: #4d5160;
    font-size: 12px;
  }

  header .main-navigation ul.rights li a {
    color: #4d5160;
  }

  header .main-navigation ul.rights li a:hover {
    color: #ffffff;
  }

  header .main-navigation ul.rights li {
    padding: 0 0 0 10px;
  }

  header .main-navigation ul.rights li:first-child {
    padding-left: 0;
    padding-right: 0;
  }
  /* Header CSS END */

  /* Footer START */
  footer.footer {
    padding: 40px 0 50px;
    background-color: #151723;
  }

  footer .container {
    max-width: 1100px;
  }

  footer.footer .logo {
    margin-bottom: 34px;
    display: block;
  }

  header.main-header img.header-logo {
    width: 130px;
  }

  footer.footer p {
    color: #646a7a;
    padding-bottom: 10px;
    max-width: 280px;
    font-size: 13px;
  }

  footer.footer .logo img.footer-logo {
    width: 130px;
  }

  footer.footer .form-group {
    position: relative;
    max-width: 290px;
  }

  footer.footer .form-group .form-control {
    background-color: #2a2e3d;
    border: 0;
    border-radius: 0;
    padding: 10px 16px;
    color: #686e81;
  }

  footer.footer .form-group .form-control:focus {
    box-shadow: none;
    background-color: #3b4051;
  }

  footer.footer .form-group .send-btn {
    position: absolute;
    top: 2px;
    right: 0px;
    font-size: 12px;
    padding: 5px 8px;
    background: transparent;
    border: 0;
  }
  footer.footer .form-group .send-btn:focus {
    outline: 0;
  }
  footer.footer ul.social-media {
    list-style: none;
    padding: 0;
    margin: 20px 0;
  }

  footer.footer ul.social-media li {
    color: #fff;
    padding: 7px 0;
    vertical-align: top;
  }

  footer.footer ul.social-media li span {
    font-size: 14px;
    color: #9a9b9d;
    vertical-align: middle;
    margin-left: 7px;
  }

  footer.footer ul.social-media li i {
    margin-right: 10px;
    color: #c2c4c6;
    font-size: 18px;
  }

  footer.footer ul.footer-link {
    padding: 0 20px;
    margin: 20px 0;
    float: left;
    list-style-type: none;
    text-align: left;
  }

  footer.footer ul.footer-link li {
    padding: 6px 0;
  }
  footer.footer ul.footer-link li a {
    color: #e7e7e8;
  }

  footer.footer ul.footer-link li a:hover {
    color: #62a4fb;
  }

  footer.bottom-footer {
    background-color: #2a2e3d;
    padding: 20px 0;
    font-size: 14px;
    color: #565c6c;
  }

  footer.bottom-footer ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  footer.bottom-footer ul li {
    display: inline-block;
    padding: 0 12px;
  }

  footer.bottom-footer ul li a {
    color: #565c6c;
  }

  footer.bottom-footer ul li a:hover {
    color: #62a4fb;
  }

  /* Footer END */

  .form-group.start-my-qoute {
    position: relative;
    max-width: 450px;

    .form-control {
      padding: 12px 16px;
      border-radius: 0;
      border: 0;
      font-size: 17px;
      height: 49px;

      &::placeholder {
        color: #3e4045;
        font-size: 17px;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  .start-my-qoute .btn.btn-primary {
    font-family: ${Theme["$medium-font-family"]};
    padding: 12px 16px;
    width: 170px;
    border-radius: 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 49px;

    &:focus {
      box-shadow: none;
    }
  }

  .banner-panel-outer {
    background-image: linear-gradient(to right, #0d1016 20%, #0d101640),
      url(${BackgroundImage});
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: top left;
    // margin-top: -63px;
    height: calc(95vh);
  }

  .banner-text {
    padding: 160px 0 120px;
  }

  .pink-link-btn {
    display: inline-block;
    padding: 7px 5px;
    background: #ff0c62;
    color: #f6d4e0;
    font-size: 13px;
    text-align: center;
    border-radius: 2px;
    font-family: "Gilroy-Medium";
  }

  .banner-text .pink-link-btn {
    padding: 6px 8px;
    font-size: 14px;
  }

  .banner-text h1 {
    color: #fff;
    font-weight: 600;
    font-size: 47px;
    max-width: 543px;
    margin: 20px 0 15px;
    font-family: "Gilroy-Medium";
    letter-spacing: 1.5px;
  }

  .banner-text h4 {
    font-size: 24px;
    color: #ffffff;
    max-width: 400px;
    font-weight: bold;
    padding: 10px 0 30px;
    font-family: "Gilroy-Thin";
  }

  .banner-text p.small {
    max-width: 212px;
    color: #7c7c80;
    font-size: 12px;
  }

  .form-group.start-my-qoute {
    position: relative;
    max-width: 450px;
  }

  .form-group.start-my-qoute .form-control {
    padding: 12px 16px;
    border-radius: 0;
    border: 0;
    font-size: 17px;
    height: 49px;
  }

  .form-group.start-my-qoute .form-control::placeholder {
    color: #3e4045;
    font-size: 17px;
  }

  .form-group.start-my-qoute .form-control:focus {
    box-shadow: none;
  }

  .banner-text .custom-control.custom-checkbox label {
    color: #ffffff;
    font-size: 18px;
    font-family: "Gilroy-Thin";
    font-weight: bold;
  }

  .custom-control input:focus {
    background-color: transparent;
    box-shadow: none;
  }

  .custom-control-input:focus {
    outline: none;
    box-shadow: none;
  }

  input[type="radio" i],
  input[type="checkbox" i] {
    background-color: transparent;
  }

  .custom-control-label {
    padding-left: 10px;
  }

  .custom-checkbox .custom-control-label::before {
    border-radius: 0;
  }

  .custom-control-label::before {
    width: 20px;
    height: 20px;
    background-color: transparent;
  }
  .custom-control-label::after {
    width: 20px;
    height: 20px;
    border: 1px solid #fff;
  }

  .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::before {
    background-color: transparent;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: transparent;
    color: #fff;
  }

  .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::after {
    background-size: 100% 65%;
  }

  .start-my-qoute button.btn.btn-primary {
    padding: 11px 16px;
    font-size: 17px;
    width: 170px;
    border-radius: 0;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #3e8efc;
    color: #f2f7fe;
    font-family: "Gilroy-Medium";
  }

  .start-my-qoute button.btn.btn-primary:hover {
    background-color: #0062cc;
    border-color: #005cbf;
  }

  .start-my-qoute button.btn.btn-primary:focus {
    box-shadow: none;
    background-color: #0062cc;
    border-color: #005cbf;
  }

  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
  }

  .banner-text.right-side {
    padding-top: calc(42%);
  }

  .banner-text.right-side h4 {
    padding-bottom: 0;
    margin-bottom: 0;
    color: #ffffff;
    font-family: "Gilroy-Thin";
  }

  .banner-text h4 span {
    font-family: "Helvetica-Neue-Reg";
    color: #ffffff;
  }

  .banner-text ul.brands-list {
    padding: 0;
    margin: 20px 0 0 0;
  }

  .banner-text ul.brands-list li {
    display: inline-block;
    vertical-align: top;
  }

  .banner-text ul.brands-list li:not(:first-child) {
    padding-left: 20px;
  }

  .banner-text ul.brands-list li .white-box {
    background-color: #fff;
    border-radius: 5px;
    width: 130px;
    height: 104px;
    padding: 15px;
    position: relative;
  }

  .banner-text ul.brands-list li .white-box img {
    max-width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .to-shop-over {
    font-family: "Gilroy-Medium";
    background-color: #272a38;
    padding: 30px;
    text-align: center;
    max-width: 1000px;
    margin: -55px auto 0;
    border-radius: 10px;
    color: #dfd9d9;
  }

  .to-shop-over h2 {
    color: #fff;
    font-weight: bold;
    letter-spacing: 1.5px;
    font-family: "Gilroy-Medium";
    font-size: 35px;
  }

  .to-shop-over h2 span {
    color: #3e8efc;
  }

  .common-subtitle {
    font-size: 18px;
    font-weight: bold;
    font-family: "Gilroy-Thin";
    color: #fff;
  }
  .map-content {
    padding-top: 90px;
    font-family: "Gilroy-Medium";
  }

  .map-content h2 {
    color: #fff;
    font-weight: 600;
    letter-spacing: 1.5px;
  }

  .map-content .map-subtitle {
    font-size: 24px;
    color: #ffffff;
    font-family: "Gilroy-Thin";
    margin-bottom: 40px;
    font-weight: bold;
    margin-top: 7px;
  }

  .medicare-supplement-plan {
    background-color: #151723;
    padding: 90px 0 90px;
  }

  .medicare-supplement-plan .plans-table {
    float: left;
    width: 51%;
    color: #fbfafb;
  }

  .medicare-supplement-plan .plans-table h6 {
    padding-bottom: 20px;
    font-size: 23px;
    font-weight: 600;
    letter-spacing: 0.7px;
    font-family: "Gilroy-Medium";
  }

  .medicare-supplement-plan .plans-table.plan-popularity {
    width: 44%;
  }

  .medicare-supplement-plan .table-panel {
    max-width: 986px;
  }

  .medicare-supplement-plan .table-panel .table-radius {
    border-radius: 10px;
    overflow: hidden;
  }

  .medicare-supplement-plan .plans-table .table {
    color: #fff;
    margin: 0;
  }
  .medicare-supplement-plan .plans-table .table tr:first-child td {
    padding-top: 25px;
  }

  .medicare-supplement-plan .plans-table .table tbody tr:first-child th {
    padding-top: 25px;
  }

  .medicare-supplement-plan .plans-table table thead {
    background-color: #3e8efc;
  }

  .medicare-supplement-plan .plans-table table tbody th {
    font-size: 14px;
  }

  .medicare-supplement-plan .plans-table table thead th {
    padding: 10px;
  }

  .medicare-supplement-plan .plans-table table th {
    text-transform: capitalize;
    padding: 10px 0px 10px 9px;
    border: 0;
    font-family: "Helvetica-Neue-Reg";
    letter-spacing: 0.3px;
    font-weight: normal;
  }

  .medicare-supplement-plan .plans-table table th:first-child {
    padding-left: 20px;
  }

  .medicare-supplement-plan .plans-table table th:last-child {
    padding-right: 25px;
  }

  .medicare-supplement-plan .plans-table table td:last-child {
    padding-right: 16px;
  }

  .medicare-supplement-plan .plans-table .table td {
    padding: 10px 0px 10px 9px;
    vertical-align: middle;
    text-align: center;
    border-top: 0;
    border-left: 1px solid #3b3e4e;
    font-size: 12px;
    margin: 0 auto;
  }

  .medicare-supplement-plan .plans-table .table td span.red-circle {
    background-color: #ff0c62;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    display: block;
  }

  .medicare-supplement-plan .plans-table table tbody {
    background-color: #2a2e3d;
  }
  .medicare-supplement-plan .plans-table.plan-popularity table tbody th {
    text-transform: uppercase;
  }

  .medicare-supplement-plan .plans-table.plan-popularity .table td {
    border: 0;
    padding: 9px 10px;
    text-align: left;
  }

  .medicare-supplement-plan .plans-table.plan-popularity .table tbody th {
    padding: 10px 0px 10px 9px;
  }

  .faq-panel {
    padding: 130px 0 0;
  }

  .faq-panel h2 {
    color: #fff;
    font-weight: 600;
    letter-spacing: 1.5px;
    font-family: "Gilroy-Medium";
  }
  #accordion {
    max-width: 800px;
    margin: 0 auto;
  }

  #accordion .card {
    border: 0;
    background-color: transparent;
    color: #d5d6d7;
    font-size: 18px;
  }

  #accordion .card .card-header {
    background-color: #232732;
    border-radius: 5px;
    margin-top: 25px;
    position: relative;
    padding: 14px 16px;
  }

  #accordion .card .card-header .btn-link {
    color: #fefefd;
    font-size: 23px;
    text-align: left;
    font-family: "Gilroy-Medium";
  }

  #accordion .card .card-header .btn-link img {
    position: absolute;
    right: 18px;
    top: 40%;
    font-size: 26px;
    color: #979797;
    transition: 3s;
  }

  #accordion .card .card-header .btn-link img.plus {
    display: none;
  }

  #accordion .card .card-header .btn-link.collapsed img.plus {
    display: block;
    transition: 3s;
    top: 29%;
  }

  #accordion .card .card-header .btn-link.collapsed img.minus {
    display: none;
  }

  #accordion .card .card-header .btn-link:hover {
    text-decoration: none;
  }

  #accordion .card .card-header .btn-link:focus {
    text-decoration: none;
  }

  #accordion .card .card-body {
    padding: 20px 43px;
  }

  p.big-paragraph {
    color: #5e606c;
    max-width: 1050px;
    margin: 120px auto 100px;
    font-size: 13px;
    font-size: 14px;
  }

  .compare-medigap-plans {
    background-color: #292c3b;
    padding: 30px 0;
  }

  .compare-medigap-plans h2 {
    color: #fff;
    font-family: "Gilroy-Medium";
  }

  .compare-medigap-plans h2 span {
    color: #3e8efc;
  }

  .compare-medigap-plans .container {
    max-width: 1220px;
  }
  .compare-medigap-plans .circle {
    border-left: 1.2px solid #4d5465;
  }

  .compare-medigap-plans .circle:after {
    background-color: #ff0c62;
    content: "OR";
    color: #fff;
    position: absolute;
    padding: 7px;
    top: 48px;
    left: -16px;
    display: block;
    font-size: 14px;
    border-radius: 50%;
  }

  @media only screen and (max-width: 1500px) {
    .banner-panel-outer {
      height: 100%;
    }
  }

  @media only screen and (max-width: 1400px) {
    .banner-panel-outer .container {
      max-width: 1145px;
    }

    .banner-text ul.brands-list li:not(:first-child) {
      padding-left: 5px;
    }

    .banner-text.right-side {
      padding-top: calc(55%);
    }
  }

  @media only screen and (max-width: 1400px) {
    .container {
      max-width: 1145px;
    }

    .banner-panel-outer .container {
      max-width: 1145px;
    }

    .banner-text ul.brands-list li:not(:first-child) {
      padding-left: 10px;
    }

    .banner-text ul.brands-list li .white-box {
      width: 122px;
      height: 100px;
    }

    .banner-text ul.brands-list li .white-box img {
      padding: 37px 17px;
    }

    .banner-text ul.brands-list li .white-box img.small-img {
      padding: 12px 20px;
    }

    .banner-text ul.brands-list li:not(:first-child) {
      padding-left: 5px;
    }

    #carousel-banner .slide-content .banner-content {
      top: 16%;
    }
  }

  @media only screen and (max-width: 1020px) {
    .banner-text.right-side ul.brands-list li .white-box {
      width: 125px;
      height: 104px;
    }
  }

  @media only screen and (max-width: 992px) {
    #carousel-banner .slide-content .banner-content {
      max-width: 395px;
    }

    .banner-content h2 {
      font-size: 29px;
      margin-top: 10px;
    }

    .banner-content p {
      font-size: 16px;
      font-weight: bold;
    }

    .banner-content .pink-btn {
      padding: 4px 10px;
      font-size: 11px;
    }

    .banner-text {
      padding: 90px 0 45px;
    }

    .banner-text.right-side {
      padding: 0 0 100px;
    }

    .medicare-supplement-plan .plans-table {
      float: none;
      margin: 0 auto 30px !important;
      width: 60%;
    }

    .medicare-supplement-plan .plans-table.plan-popularity {
      width: 51%;
    }

    footer.footer ul.footer-link {
      padding: 0 20px;
    }

    .compare-medigap-plans .circle:after {
      background-color: #ff0c62;
      content: "OR";
      color: #fff;
      position: absolute;
      padding: 7px;
      top: -16px;
      left: 47%;
      display: block;
      font-size: 14px;
      border-radius: 50%;
    }

    .compare-medigap-plans .circle {
      padding-top: 30px;
      margin: 30px auto 0;
      width: 90%;
      border-left: 0;
      border-top: 1.2px solid #4d5465;
    }

    .medicare-supplement-plan .plans-table.plan-popularity {
      padding-top: 60px;
    }
  }

  @media only screen and (max-width: 768px) {
    .to-shop-over h2 span {
      display: block;
    }

    #carousel-banner .slider-img {
      display: none;
    }

    #carousel-banner .slider-img-mobile {
      display: block;
      position: relative;
      left: 50%;
      top: 50%;
      width: 98%;
      /* height: 350px; */
      z-index: -1;
      transform: translate(-50%, 0%);
      box-shadow: 0 16px 28px rgba(14, 16, 24, 0.5);
    }

    .medicare-supplement-plan .plans-table {
      width: 100%;
    }

    .medicare-supplement-plan .plans-table.plan-popularity {
      width: 100%;
    }

    .banner-text ul.brands-list li .white-box {
      width: 100px;
      height: 80px;
    }

    .banner-text ul.brands-list li .white-box img {
      padding: 30px 17px;
    }

    .banner-text ul.brands-list li .white-box img.small-img {
      padding: 8px 15px;
    }

    footer.footer ul.footer-link {
      padding: 0 30px 0 0;
    }

    #accordion .card {
      font-size: 14px;
    }

    #accordion .card .card-header .btn-link {
      font-size: 18px;
    }

    .medicare-supplement-plan {
      padding: 60px 0;
    }

    .faq-panel {
      padding: 50px 0;
    }

    ul.logo-icon li img {
      max-width: 100%;
      width: 80%;
    }
  }

  @media only screen and (max-width: 630px) {
    header .main-navigation {
      width: 80%;
    }

    header .main-navigation ul.nav-links li a {
      font-size: 21px;
    }

    .banner-text ul.brands-list li:not(:first-child) {
      padding-left: 3px;
    }

    #accordion .card .card-header .btn-link i {
      font-size: 18px;
    }

    .banner-content p {
      margin: 0;
    }

    .banner-content h2 {
      font-size: 26px;
      max-width: 300px;
    }

    .banner-content p {
      font-size: 16px;
    }

    .form-panel {
      padding: 0;
    }
  }

  @media only screen and (max-width: 585px) {
    #accordion .card .card-header {
      padding: 14px 26px;
    }

    #accordion .card .card-header .btn-link img {
      width: 27px;
    }

    .head-tittle {
      font-size: 26px;
    }

    .banner-text.right-side ul.brands-list li {
      width: 35%;
      padding-top: 20px;

      &:not(: first-child) {
        padding-left: 0px;
      }
    }
  }

  @media only screen and (max-width: 541px) {
    #carousel-banner .slide-content .banner-content {
      max-width: 320px;
    }

    .banner-text h1 {
      font-size: 35px;
    }

    .banner-text h3 {
      font-size: 20px;
    }

    .to-shop-over h2 {
      font-size: 20px;
    }

    .to-shop-over h2 span {
      font-size: 26px;
    }

    .common-subtitle {
      font-size: 16px;
    }

    .to-shop-over h2 span {
      font-size: 26px;
    }

    .map-content h2 {
      font-size: 26px;
    }

    .map-content .map-subtitle {
      font-size: 16px;
    }

    .faq-panel h2 {
      font-size: 30px;
    }

    .compare-medigap-plans h2 {
      font-size: 30px;
    }
    .banner-text ul.brands-list li {
      margin-top: 20px;
    }

    .start-my-qoute button.btn.btn-primary {
      width: 150px;
    }

    .start-my-qoute button.btn.btn-primary {
      font-size: 16px;
      padding: 12px 16px;
    }

    .form-outer {
      padding-left: 30px;
      padding-right: 30px;
    }

    .question .heading .expert {
      display: block;
    }
    .bottom-info-panel {
      padding-bottom: 0;
    }
  }

  @media only screen and (max-width: 400px) {
    #carousel-banner .slide-content .banner-content {
      max-width: 270px;
    }

    #carousel-banner .slide-content .banner-content {
      top: 15%;
    }

    .compare-plan-form .radio-input label {
      width: 110px;
    }

    .banner-text.right-side ul.brands-list li {
      width: 50%;
    }
  }
`;
