import * as actionTypes from "../ActionTypes";
import apiClient from "../ApiInterceptor";

export const loaderState = (flag) => {
  return {
    type: actionTypes.LOADER_STATE,
    isLoading: flag,
  };
};

export const closeToaster = (value) => {
  return {
    type: actionTypes.TOGGLE_TOASTER,
    showToaster: value
  };
}

export const initialization = () => {
  return {
    type: actionTypes.INITIALIZATION,
    offerDetails: {},
    deletedOffer: { flag:false, offerId: null}
  };
};

export const reloadSavedSlider = () => {
  return {
    type: actionTypes.TOGGLE_IS_UPDATED,
    isUpdated: false
  };
};

export const saveOffer = (offerId, userId) => {
  return (dispatch) => {
    dispatch(loaderState(true));
    apiClient
      .post("saved-offer/", {
        offer: offerId,
        user: userId,
      })
      .then((res) => {
        dispatch({
          type: actionTypes.SAVE_OFFER,
          offerDetails: res.data,
          deletedOffer: { flag:false, offerId: null},
          isLoading: false,
          isUpdated: true,
          showToaster: 'Saved'
        });
      })
      .catch((error) => {
        dispatch(loaderState(false));
      });
  };
};

export const unSaveOffer = (savedOfferId, offerId) => {
  return (dispatch) => {
    dispatch(loaderState(true));
    apiClient
      .delete(`saved-offer/${savedOfferId}`)
      .then((res) => {
        dispatch({
          type: actionTypes.UNSAVED_OFFER,
          deletedOffer: { flag:true, offerId: offerId},
          isLoading: false,
          isUpdated:  true,
          showToaster: 'Unsaved'
        });
      })
      .catch((error) => {
        dispatch(loaderState(false));
      });
  };
};
