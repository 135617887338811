import * as actionTypes from "../actions/ActionTypes";

const initialStates = {
  registerEmailDetails: {},
  registerEmailLoading: false,
  registerEmailError: {}
};

const CommonReducer = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_DETAILS:
      return {
        ...state,
        UserDetails: action.UserDetails,
      };

    case actionTypes.REGISTER_EMAIL_ID:
      return {
        ...state,
        registerEmailDetails: action.emailDetails || state.registerEmailDetails,
        registerEmailLoading: action.isLoading,
        registerEmailError: action.errorData  || state.registerEmailError
      };

      case actionTypes.REGISTER_EMAIL_ID_LOADER_STATE:
      return {
        ...state,
        registerEmailLoading: action.isLoading
      };

      case actionTypes.SET_ERROR:
      return {
        ...state,
        registerEmailError: action.errorData,
        registerEmailLoading: action.isLoading
      }
    default:
      return {
        ...state,
      };
  }
};

export default CommonReducer;
