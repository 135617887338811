import React from "react";
import {withRouter} from "react-router-dom";
import {Table} from "../../../theme/css/Global";

function PrivacyPolicy() {
	return (
		<React.Fragment>
			<div className="container pt-5 pb-5">
				<div className="row">
					<div className="col-12">
						<h2 className="pb-5">Privacy Policy</h2>
						<p>
							Last updated: Jan 11, 2021
						</p>
						<p>
							You must be at least 18 years old to use www.offersclub.com (the “Site”).
						</p>
						<p>
							We take your privacy very seriously. Please read this Privacy Policy carefully as it
							contains important information on how and why we collect, store, use, and share your
							personal information when you use this Site. It also explains your rights in relation to
							your personal information and how to contact us.
						</p>
						<p>By accessing, using, or submitting information to the Site, you agree to this Privacy
							Policy and our Terms & Conditions.
						</p>
						<h4 className="pb-2 pt-3">Personal Information We Collect About You</h4>
						<p>
							We collect, use and are responsible for certain personal information about you, that
							identifies, relates to, describes, is reasonably capable of being associated with you, or
							that which could reasonably be linked, directly or indirectly, with a particular consumer or
							household. We collect, and have collected personal information about our visitors within the
							preceding 12 months in the following categories:
						</p>
						<Table cellSpacing="0" cellPadding="8">
							<thead>
							<tr>
								<th>
									<p><strong>Categories of Personal Information</strong></p>
								</th>
								<th>
									<p><strong>Examples of Specific Types of Personal Information Collected</strong>
									</p>
								</th>
								<th>
									<p><strong>Categories of Sources from Which PI is Collected</strong></p>
								</th>
							</tr>
							</thead>
							<tbody>

							<tr>
								<td>
									<p>Identifiers (e.g., a real name, alias, postal address, unique personal
										identifier, online
										identifier, Internet Protocol address, email address, phone number, account name, social
										security number,
										driver&rsquo;s license number, passport number, or other similar
										identifiers), or information that
										identifies, relates to, describes, or is capable of being associated with, a
										particular
										individual</p>
								</td>
								<td>
									<p>First name;</p>
									<p>Last name;</p>
									<p>Postal address;</p>
									<p>Email address;</p>
									<p>Phone number;</p>
									<p>Your Internet Protocol (&ldquo;IP&rdquo;) address; and</p>
									<p>Age range/age/DOB</p>
								</td>
								<td>
									<p>Individual through the Site;</p>
									<p>Advertising networks;</p>
									<p>Data analytics providers/web analytics services;</p>
									<p>Data resellers/brokers; and</p>
									<p>Identity resolution providers</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Characteristics of protected classifications under California or federal
										law</p>
								</td>
								<td>
									<p>Race;</p>
									<p>Religion;</p>
									<p>Sex/gender;</p>
									<p>Gender identity;</p>
									<p>Marital status;</p>
									<p>Military or veteran status; and</p>
									<p>Age (over 40)</p>
								</td>
								<td>
									<p>Individual through the Site;</p>
									<p>Data analytics providers/web analytics services;</p>
									<p>and</p>
									<p>Identity resolution providers</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Commercial information (e.g., records of personal property, products or
										services purchased,
										obtained, or considered, or other purchasing or consuming histories or
										tendencies)</p>
								</td>
								<td width="128">
									<p>Products or services purchased, obtained, considered, or other purchasing or
										consuming
										histories;</p>
									<p>Personal and online interests; and</p>
									<p>Information you volunteer to the Site or through other means of
										communication, such as
										subscription, comments/posts, written correspondence or other electronic
										submissions, and
										communications sent by you to the Site</p>
								</td>
								<td>
									<p>Individual through the Site; and</p>
									<p>Data analytics providers/web analytics services</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Internet or other electronic network activity information (e.g., browsing
										history, search history,
										and information regarding a consumer&rsquo;s interaction with a website,
										application, or
										advertisement)</p>
								</td>
								<td>
									<p>Domain server;</p>
									<p>Mobile and/or operating device identifier;</p>
									<p>Clickstream data;</p>
									<p>Computer operating system;</p>
									<p>Source URL;</p>
									<p>Referring and exit pages;</p>
									<p>Web browser version and type</p>
									<p>Pages viewed;</p>
									<p>Internet service provider (ISP);</p>
									<p>Date and time stamp;</p>
									<p>Mobile carrier;</p>
									<p>Time spent on Site;</p>
									<p>Behavioral data (opens, clicks); and</p>
									<p>User-specific and aggregate information on areas of the Site used</p>
								</td>
								<td>
									<p>Individual through the Site;</p>
									<p>Data analytics providers/web analytics services;</p>
									<p>and</p>
									<p>Identity resolution providers</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Geolocation data</p>
								</td>
								<td>
									<p>Geographic region based on IP address and/or web browser type;</p>
									<p>Time zone; and</p>
									<p>Area code and zip code associated with your web server</p>
								</td>
								<td>
									<p>Individual through the Site; and</p>
									<p>Geolocation and proxy detection web service providers</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Professional or employment-related information</p>
								</td>
								<td>
									<p>Occupation;</p>
									<p>Industry; and</p>
									<p>Employment status</p>
								</td>
								<td>
									<p>Individual through the Site;</p>
									<p>Identity resolution providers</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Education information</p>
								</td>
								<td>
									<p>Education level</p>
								</td>
								<td>
									<p>Individual through the Site;</p>
									<p>Identity resolution providers</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Inferences drawn from any of the information identified above to create a
										profile about a consumer
										reflecting their preferences, characteristics, psychological trends,
										predispositions, behavior,
										attitudes, intelligence, abilities, and aptitudes</p>
								</td>
								<td>
									<p>Inferences about an individual&rsquo;s propensity to respond to a particular
										advertisement</p>
								</td>
								<td>
									<p>Internal algorithms; and</p>
									<p>Lead generation forms aggregators</p>
								</td>
							</tr>
							</tbody>
						</Table>
						<p>
							As noted in the above chart, we collect the above personal information directly from you via
							the Site, and from your interactions with the Site, including any personal information which
							you provide directly or indirectly.
						</p>
						<p><b>Indirectly</b></p>
						<p>
							Cookies and Tracking Pixels on the Site: We use and store cookies and tracking pixels on the
							Site, as described further. A cookie is a small data text file containing an identifier (a
							string of letters and numbers) that is sent by a web server to a web browser and which is
							stored on your computer's web browser. The identifier is then sent back to the server each
							time the browser requests a page from the server. Cookies can later be retrieved to identify
							you to us. Personal information that we store about you may be linked to the information
							stored in and obtained from cookies. Cookies make your use of the Site easier, make the Site
							run more smoothly, and help us to maintain a secure Site. Tracking pixels (also known as web
							beacons, 1x1 pixels, or clear GIFs) are code requiring a call back to a server to render,
							and which deliver information to us about your IP address and information from your browser.
							Cookies and tracking pixels are used to track your interaction with the Site, including
							clicks. Cookies and tracking pixels may collect information about you, such as your IP
							address, domain server, mobile and/or operating device identifier, clickstream data,
							computer operating system, source URL, referring and exit pages, web browser version and
							type, pages viewed, ISP, data and time stamp, mobile carrier, time spent on the site,
							behavioral data (opens, clicks), and user-specific and aggregate information on areas of the
							Site used. We also collect information on whether you responded to a particular
							offer/advertisement, or a particular product or service featured on the Site, and which
							links you click through the Site through the use of tracking pixels.
						</p>
						<p>
							<i>Tracking and How We Respond to Do Not Track Signals</i>
						</p>
						<p>
							You can instruct your web browser to refuse all cookies or to indicate when a cookie is
							being sent, by visiting the preferences or settings page of your web browser. In the event
							that your browser requests that we do not track you or store a cookie, unless otherwise
							required by law to honor the requests, we do not guarantee that our Site will honor such
							requests. Please note that we do not guarantee that the Site will work properly in the event
							that you elect to opt out of our tracking or use of cookies.
						</p>
						<p>
							<b>Directly</b>
						</p>
						<p>
							To the extent that you like or follow one of our social media profiles by clicking on the
							button featured on the Site, we may link such activities and any personal information with
							personal information we have collected.
						</p>
						<p>
							To the extent that you contact us through the Site or through other method (email or mail),
							we collect the personal information you submit in conjunction with your communication or
							request.
						</p>
						<p>
							<b>From Third Parties</b>
						</p>
						<p>
							As noted above in the chart, in addition to collecting personal information through the Site
							and our internal algorithms, we also collect personal information from through third party
							data advertising networks, data analytics providers/web analytics services, data
							resellers/brokers, identity resolution providers, geolocation and proxy detection web
							service providers, and lead generation form aggregators.
						</p>
						<h4 className="pb-2 pt-3">How We Use Collected Personal Information</h4>
						<p>
							We may use information collected through your use of the Site to:
						</p>
						<ul style={{
							fontFamily: "Gilroy-Regular",
							fontSize: "16px !important",
							color: "#9798aa"
						}}>
							<li>Provide you with the ability to access and interact with the Site;</li>
							<li>Maintain your account;</li>
							<li>Track Site access and interaction;</li>
							<li>Fulfill requests submitted through the Site;</li>
							<li>Personalize and improve the Site;</li>
							<li>Advertising/marketing;</li>
							<li>Update our internal database;</li>
							<li>Analytics;</li>
							<li>Connect you with the particular products and/or services featured on the Site;</li>
							<li>Detect security incidents, and protect against malicious, deceptive, fraudulent, or
								illegal activity;
							</li>
							<li>Debug to identify and repair errors that affect functionality of the Site;</li>
							<li>Track consumer interaction with the Site (counting ad impressions, verifying
								positioning and quality of ad impressions, etc.);
							</li>
							<li>Internal research;</li>
							<li>Verify or maintain quality and safety; and</li>
							<li>Legal compliance</li>
						</ul>
						<p>
							We display third party offers/advertisements on the Site. We use the information collected
							through the Site to track your access and interaction with the Site and with the third party
							offers/advertisements featured on the Site. We may share the information collected through
							your use of the Site with our third party advertising partners who own the brands, products,
							and services which are featured on our Site, and lead purchasers. We and our third party
							advertisers and lead purchasers may collect and use non-personally identifiable information
							about you through cookies and tracking pixels, including the keywords leading you to the
							Site and the referring website address and whether you responded to a particular
							offer/advertisement featured on the Site or any email promoting the Site, and which third
							party offers/advertisements and links you click through the Site and/or any emails promoting
							the Site. We also automatically collect information when an individual opens an email
							marketing the Site, and/or clicks on any links within such emails, through the use of
							cookies and tracking pixels. We may receive a commission based on your clicks and/or
							purchases made through the links featured on the Site and in any email communications or
							newsletters. As noted above, in the event that your web browser requests that we do not
							track you or store a cookie, unless otherwise required by law to do so, we do not guarantee
							that our Site will honor such requests.
						</p>
						<h4 className="pb-2 pt-3">With Whom Do We Share Your Information </h4>
						<p>
							We may share the above information in all of the above categories internally with our parent
							company and any subsidiary and affiliated companies. We may also share the above information
							in all of the above categories with our third party advertising partners who own the brands,
							products, and services which are featured on our Site, and lead purchasers. Also, if you
							request to unsubscribe from receiving email newsletters from us, we may share your email
							address internally with our parent company and any subsidiary and affiliated companies, as
							well as with our third party advertising partners who own the brands, products, and services
							which are featured on our Site, to effectuate your request to unsubscribe and to comply with
							CAN-SPAM. </p>
						<p>
							Also, on occasion, we may be required by law enforcement or judicial authorities to provide
							information to the appropriate governmental authorities. In the event of a merger,
							acquisition, or sale of our business, your data may also be transferred. In the event we
							become the subject of an insolvency proceeding, whether voluntary or involuntary, we or our
							liquidator, administrator, receiver or administrative receiver may sell, license or
							otherwise dispose of, such information in a transaction approved by the court.
						</p>
						<h4 className="pb-2 pt-3">Security</h4>
						<p>
							The security of your data is important to us but remember that no method of transmission
							over the Internet or method of electronic storage is 100% secure. While we strive to use
							commercially acceptable means to protect your data, we cannot guarantee its absolute
							security. As a result, you acknowledge that there are security and privacy limitations of
							the Internet which are beyond our control; the security, integrity and privacy of any and
							all information and data exchanged between you and us through this Site cannot be
							guaranteed; and any such information and data may be viewed or tampered with in transit by a
							third party. We make no warranty, express, implied, or otherwise, regarding the security of
							the Site and your personal information.
						</p>
						<h4 className="pb-2 pt-3">Third Party Links and Advertisements</h4>
						<p>
							The Site contains links to third party websites. We are not responsible for the privacy
							practices or the content of such third party websites. This Privacy Policy only addresses
							our use and disclosure of information which we collect from you. The third party websites
							which are accessible through our Site, and companies to whom your information is
							transferred, have their own privacy policies and data collection, use and disclosure
							practices. Please consult each third party website's privacy policy. We are not responsible
							for the policies or practices of third parties or their sites. Additionally, third party
							advertisers and lead purchasers featured on the Site may collect information about you when
							you view or click on their advertising displayed on the Site, through the use of cookies and
							tracking pixels. We cannot control this collection of information. You should contact these
							third parties directly if you have any questions about their use of the information that
							they collect.
						</p>
						<h4 className="pb-2 pt-3">Children</h4>
						<p>
							You must be an adult of 18 years or older to use the Site. We do not target the Site to
							children. Consistent with the federal Children’s Online Privacy Protection Act of 1998
							(“COPPA”), we will never knowingly collect personal information from anyone under the age of
							13 without requesting parental consent. If you are aware that your minor child under the age
							of 13 has provided us with personal information, please contact us.
						</p>
						<h4 className="pb-2 pt-3">United States Only</h4>
						<p>
							This Site is intended to be used in the United States only. Your data may be transferred to
							and maintained on computers located outside of your state, country, or other governmental
							jurisdiction where the data protection laws may differ from those of your jurisdiction. If
							you are located outside United States, please note that the data we collect through the Site
							may be processed in and outside of the United States. By using the Site, you consent to the
							transfer of such information to countries outside or your country of residence, which may
							have different data protection rules than those of your country. We will take all the steps
							reasonably necessary to ensure that your data is treated securely and in accordance with
							this Privacy Policy and that no transfer of your data will take place to an organization or
							a country unless there are adequate controls in place including the security of your data.
							Your consent to this Privacy Policy followed by your submission of such information
							represents your agreement to that transfer.
						</p>
						<h4 className="pb-2 pt-3" id="california-residents">California Residents – Your Privacy
							Rights</h4>
						<p>
							<i>California Rights Under Shine the Light</i>
						</p>
						<p>
							Under California’s Shine the Light law (California Civil Code § 1789.83), California
							residents have the right to request, once a year, a list of the categories of personal
							information disclosed to third parties for their direct marketing purposes during the
							immediately preceding calendar year; and the names and addresses of such third parties with
							whom we have shared the personal information. You have agreed to allow us to share
							information with third parties for their direct marketing purposes until you request to
							opt-out of such sharing. To request information, please submit your request to us at
							helpdesk@offersclub.com. Your request must include the email
							address or mailing address, as applicable, for us to send a response. Within thirty days of
							receiving such a request, we will provide a list of the categories of personal information
							disclosed to third parties for third-party direct marketing purposes during the immediately
							preceding calendar year, along with the names and addresses of such third parties. This
							request may be made no more than once per calendar year. We reserve our right not to respond
							to requests that are not submitted in accordance with this section.
						</p>
						<p>
							<i>California Rights Under the CCPA</i>
						</p>
						<p>
							In addition, if you are a California resident under the meaning of Section 17014 of Title 18
							of the Cal. Code of Regulations, your use of the Site is subject to the California Consumer
							Privacy Act (“CCPA”), and all final, implementing regulations. Under the CCPA, up to twice
							in a twelve (12) month period, a consumer has the right to request that a business disclose
							what personal information it collects, uses, discloses, and sells. Information regarding our
							collection and disclosure practices are detailed herein, in addition to instructions on how
							to exercise your rights regarding your personal information.
						</p>
						<h4 className="pb-2 pt-3">What Personal Information Do We Collect?</h4>
						<p>
							We collect, and have collected personal information about our visitors within the preceding
							12 months in the following categories, and for the following business or commercial
							purposes:
						</p>
						<Table cellSpacing="0" cellPadding="8">
							<thead>
							<tr>
								<th>
									<p><strong>Categories of Personal Information</strong></p>
								</th>
								<th>
									<p><strong>Business or Commercial Purpose for Which Each Category Will be
										Used</strong></p>
								</th>
							</tr>
							</thead>
							<tbody>

							<tr>
								<td width="151">
									<p>Identifiers (e.g., a real name, alias, postal address, unique personal
										identifier, online identifier, Internet Protocol address, email address,
										account name, social security number, driver&rsquo;s license number,
										passport number, or other similar identifiers), or information that
										identifies, relates to, describes, or is capable of being associated with, a
										particular individual</p>
								</td>
								<td width="451">
									<p>Provide you with the ability to access and interact with the Site;</p>
									<p>Maintain your account;</p>
									<p>Track Site access and interaction;</p>
									<p>Fulfill requests submitted through the Site;</p>
									<p>Personalize and improve the Site;</p>
									<p>Advertising/marketing;</p>
									<p>Update our internal database;</p>
									<p>Analytics;</p>
									<p>Connect you with the particular products and/or services featured on the
										Site;</p>
									<p>Detect security incidents, and protect against malicious, deceptive,
										fraudulent, or illegal activity;</p>
									<p>Debug to identify and repair errors that affect functionality of the
										Site;</p>
									<p>Track consumer interaction with the Site (counting ad impressions, verifying
										positioning and quality of ad impressions, etc.);</p>
									<p>Internal research;</p>
									<p>Verify or maintain quality and safety;</p>
									<p>and</p>
									<p>Legal compliance</p>
								</td>
							</tr>
							<tr>
								<td width="151">
									<p>Characteristics of protected classifications under California or federal
										law</p>
								</td>
								<td width="451">
									<p>Personalize and improve the Site;</p>
									<p>Advertising/marketing;</p>
									<p>Update our internal database;</p>
									<p>Analytics;</p>
									<p>Connect you with the particular products and/or services featured on the
										Site;</p>
									<p>Track consumer interaction with the Site (counting ad impressions, verifying
										positioning and quality of ad impressions, etc.);</p>
									<p>Internal research;</p>
									<p>and</p>
									<p>Legal compliance</p>
								</td>
							</tr>
							<tr>
								<td width="151">
									<p>Commercial information (e.g., records of personal property, products or
										services purchased, obtained, or considered, or other purchasing or
										consuming histories or tendencies)</p>
								</td>
								<td width="451">
									<p>Personalize and improve the Site;</p>
									<p>Advertising/marketing;</p>
									<p>Update our internal database;</p>
									<p>Analytics;</p>
									<p>Connect you with the particular products and/or services featured on the
										Site;</p>
									<p>Track consumer interaction with the Site (counting ad impressions, verifying
										positioning and quality of ad impressions, etc.);</p>
									<p>Internal research;</p>
									<p>and</p>
									<p>Legal compliance</p>
								</td>
							</tr>
							<tr>
								<td width="151">
									<p>Internet or other electronic network activity information (e.g., browsing
										history, search history, and information regarding a consumer&rsquo;s
										interaction with a website, application, or advertisement)</p>
								</td>
								<td width="451">
									<p>Personalize and improve the Site;</p>
									<p>Advertising/marketing;</p>
									<p>Update our internal database;</p>
									<p>Analytics;</p>
									<p>Connect you with the particular products and/or services featured on the
										Site;</p>
									<p>Track consumer interaction with the Site (counting ad impressions, verifying
										positioning and quality of ad impressions, etc.);</p>
									<p>Internal research;</p>
									<p>and</p>
									<p>Legal compliance</p>
								</td>
							</tr>
							<tr>
								<td width="151">
									<p>Geolocation data</p>
								</td>
								<td width="451">
									<p>Personalize and improve the Site;</p>
									<p>Advertising/marketing;</p>
									<p>Update our internal database;</p>
									<p>Analytics;</p>
									<p>Connect you with the particular products and/or services featured on the
										Site;</p>
									<p>Track consumer interaction with the Site (counting ad impressions, verifying
										positioning and quality of ad impressions, etc.);</p>
									<p>Internal research;</p>
									<p>and</p>
									<p>Legal compliance</p>
								</td>
							</tr>
							<tr>
								<td width="151">
									<p>Professional or employment-related information</p>
								</td>
								<td width="451">
									<p>Personalize and improve the Site;</p>
									<p>Advertising/marketing;</p>
									<p>Update our internal database;</p>
									<p>Analytics;</p>
									<p>Connect you with the particular products and/or services featured on the
										Site;</p>
									<p>Track consumer interaction with the Site (counting ad impressions, verifying
										positioning and quality of ad impressions, etc.);</p>
									<p>Internal research;</p>
									<p>and</p>
									<p>Legal compliance</p>
								</td>
							</tr>
							<tr>
								<td width="151">
									<p>Education information</p>
								</td>
								<td width="451">
									<p>Personalize and improve the Site;</p>
									<p>Advertising/marketing;</p>
									<p>Update our internal database;</p>
									<p>Analytics;</p>
									<p>Connect you with the particular products and/or services featured on the
										Site;</p>
									<p>Track consumer interaction with the Site (counting ad impressions, verifying
										positioning and quality of ad impressions, etc.);</p>
									<p>Internal research;</p>
									<p>and</p>
									<p>Legal compliance</p>
								</td>
							</tr>
							<tr>
								<td width="151">
									<p>Inferences drawn from any of the information identified above to create a
										profile about a consumer reflecting their preferences, characteristics,
										psychological trends, predispositions, behavior, attitudes, intelligence,
										abilities, and aptitudes</p>
								</td>
								<td width="451">
									<p>Personalize and improve the Site;</p>
									<p>Advertising/marketing;</p>
									<p>Update our internal database;</p>
									<p>Analytics;</p>
									<p>Connect you with the particular products and/or services featured on the
										Site;</p>
									<p>Track consumer interaction with the Site (counting ad impressions, verifying
										positioning and quality of ad impressions, etc.);</p>
									<p>Internal research;</p>
									<p>and</p>
									<p>Legal compliance</p>
								</td>
							</tr>
							</tbody>
						</Table>
						<p>
							We collect, use, and are responsible for certain personal information about you, that
							identifies, relates to, describes, is reasonably capable of being associated with you, or
							that which could reasonably be linked, directly or indirectly, with a particular consumer or
							household. Additional information about our collection of information through the Site can
							be found under the “Personal Information We Collect About You” heading above. In addition to
							collecting personal information through the Site and our internal algorithms, we also
							collect personal information from the following sources: third party data advertising
							networks, data analytics providers/web analytics services, data resellers/brokers, identity
							resolution providers, geolocation and proxy detection web service providers, and lead
							generation form aggregators.
						</p>
						<h4 className="pb-2 pt-3">What Personal Information Do We Disclose?</h4>
						<p>
							Within the preceding 12 months, we have disclosed personal information for a business
							purpose in all of the above categories to the third parties identified under the “With Whom
							Do We Share Your Information” heading above. We may disclose your personal information in
							any of the above categories if legally required to do so, in our sole discretion, pursuant
							to a request from a governmental entity, or if we believe in good faith that such action is
							necessary to (a) conform to legal requirements or comply with legal process; (b) protect our
							rights or property, or our affiliated companies; (c) prevent a crime or protect national
							security; or (d) protect the personal safety of users or the public. Also, in the event that
							we, or substantially all of our assets, are acquired, personal information in all of the
							above categories may be included within the transferred assets. In the event we become the
							subject of an insolvency proceeding, whether voluntary or involuntary, we or our liquidator,
							administrator, receiver or administrative receiver may sell, license or otherwise dispose
							of, such personal information in all of the above categories in a transaction approved by
							the court.
						</p>
						<h4 className="pb-2 pt-3">What Personal Information Do We Sell?</h4>
						<p>
							We may sell personal information in all of the above categories to third party advertising
							partners who own the brands, products, and services which are featured on our Site, and lead
							purchasers. We may receive a commission based on your clicks and/or purchases made through
							the links featured on the Site and in any email communications or newsletters. We do not
							have any actual knowledge of the sale of personal information of minors under 16 years of
							age. There are no financial incentives to disclose.
						</p>
						<h4 className="pb-2 pt-3">Your Right to Request to Know and How to Submit a Verified Request to
							Know</h4>
						<p>
							A “request to know” is a request related to the personal information that a business has
							about such consumer. It may include a request for any or all of the following from us:
						</p>
						<ul style={{
							fontFamily: "Gilroy-Regular",
							fontSize: "16px !important",
							color: "#9798aa"
						}}>
							<li>(1) specific pieces of personal information that we have collected about you;</li>
							<li>(2) categories of personal information that we have collected about you;</li>
							<li>(3) categories of sources from which the personal information was collected;</li>
							<li>(4) categories of personal information that we have sold or disclosed for a business
								purpose about you;
							</li>
							<li>(5) categories of third parties to whom the personal information was sold or
								disclosed for a business purpose; and
							</li>
							<li>(6) the business or commercial purpose for collecting or selling personal
								information.
							</li>
						</ul>

						<p>The CCPA permits requests to know from the same consumer up to twice in a twelve (12) month
							period.</p>
						<p>
							To submit a request to know, you may email us at helpdesk@offersclub.com To process your
							request,
							we may require that you submit the following information in conjunction with your request:
							your first name, last name, California address, phone number, and email address. The
							information which you provide will be used to verify your request and to attempt to identify
							you within our data records. If you elect to use an authorized agent to exercise your right
							to submit a request for information, we may require the following before processing the
							request: 1) provide proof of your written and signed permission with the authorized agent to
							act on your behalf; 2) directly verify your own identity with us; and 3) directly confirm
							with us that you provided the authorized agent permission to submit the request on your
							behalf. The information which you submit in conjunction with your request to know will be
							used for the express purposes of verification and providing you with responsive information
							to your request, if required.
						</p>
						<h4 className="pb-2 pt-3">Your Right to Request Deletion and How to Submit a Verified Request to
							Delete</h4>
						<p>
							You have the right to request the deletion of any personal information which we have
							collected about you. To submit a verifiable request to delete, you may email us at
							helpdesk@offersclub.com, or send us your request to delete at OffersClub.com, 2803
							Philadelphia
							Pike, Suite B #259, Claymont, DE 19703, United States of America. To process your request,
							we
							may require that you submit the following information in conjunction with your request: your
							first name, last name, California address, phone number and email address. The information
							which you provide will be used to verify your request and to attempt to identify you within
							our data records. If you elect to use an authorized agent to exercise your right to submit a
							request for deletion, we may require the following before processing the request: 1) provide
							proof of your written and signed permission with the authorized agent to act on your behalf;
							2) directly verify your own identity with us; and 3) directly confirm with us that you
							provided the authorized agent permission to submit the request on your behalf. The
							information which you submit in conjunction with your request to delete will be used for the
							express purposes of verification and fulfilling your request, if required.
						</p>
						<p>
							To the extent that your personal information is necessary for us to maintain for a reason
							delineated in Cal. Civ. Code § 1798.105(d), including but not limited to complete a
							transaction for which the personal information was collected, provide a good or service you
							have requested, detect security incidents, protect against malicious, deceptive, fraudulent,
							or illegal activity, debug, etc., or to comply with a legal obligation, we are not required
							to comply with your request for deletion. For instance, we are not required to delete
							personal information which is necessary to maintain suppression files for those individuals
							who have opted out of receiving email marketing from us. If we are unable to verify your
							request, we will notify you as well.
						</p>
						<h4 className="pb-2 pt-3">Your Right to Opt-Out</h4>
						<p>
							You have the right to opt-out of the sale of your personal information. You have the right
							to direct a business that sells (or may in the future sell) your personal information to
							stop selling your personal information, and to refrain from doing so in the future.
						</p>
						<p>
							The Site features a “Do Not Sell My Personal Information” link, which you can click to
							submit your request to opt-out of the sale of your personal information. You may also email
							us at helpdesk@offersclub.com, to submit your request to opt-out of the sale of your
							personal
							information. In conjunction with your request, we may request that you provide your first
							name, last name, California address, phone number, and email address to verify and process
							your request. The information which you provide will be used to verify your request and to
							attempt to identify you within our data records. If you elect to use an authorized agent to
							exercise your right to submit a request to opt-out of the sale of your personal information,
							we may require that you provide proof that the authorized agent has been authorized to act
							on your behalf. The information which you submit in conjunction with your request to opt-out
							will be used for the express purposes of verification and complying with the opt-out
							request, if required.
						</p>
						<h4 className="pb-2 pt-3">Your Right to Non-Discrimination for the Exercise of Your Privacy
							Rights</h4>
						<p>
							California affords consumers with the right not to receive discriminatory treatment by a
							business for the exercise of their privacy rights conferred by the CCPA. This means that you
							cannot be denied goods or services; be charged different prices or rates; be provided with a
							different level or quality of goods or services; or have it suggested that you will receive
							a different price or rate or different level or quality of goods or services. However, a
							business may offer a financial incentive or price or service difference if it is reasonably
							related to the value of the consumer’s data. A business shall notify consumers of any
							financial incentive or price or service difference.
						</p>
						<h4 className="pb-2 pt-3">Updates</h4>
						<p>
							This Privacy Policy was published and last updated on Jan 11, 2021. We may update this
							Privacy Policy from time to time. Updates to this Privacy Policy are effective when they are
							posted. You acknowledge and agree that it is your responsibility to review this Site and
							this Privacy Policy periodically and to be aware of any modifications. Your use of the Site
							after such modifications will constitute your acknowledgment of the modified Privacy Policy;
							and agreement to abide and be bound by the modified Privacy Policy.
						</p>
						<h4 className="pb-2 pt-3">Contact Us</h4>
						<p>
							If you have any questions or concerns regarding your privacy rights or our Privacy Policy or
							practices, you may contact us via email at helpdesk@offersclub.com.
						</p>
						<h4 className="pb-2 pt-3">Need More Information or Do You Need Extra Help? </h4>
						<p>
							Further, if you have a disability which interferes with your ability to access this policy,
							we can make accommodations to present the policy in an alternative format to assist you. If
							you would like to receive this Privacy Policy in another format that is accessible to your
							needs (for example: audio, large print, etc.), please contact us.
						</p>
						<p>
							© 2020-2021 www.offersclub.com. All rights reserved.
						</p>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default withRouter(PrivacyPolicy);
