import React, { useState } from "react";
import "react-multi-carousel/lib/styles.css";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { BigBoxImageSlider } from "../../../theme/css/Global";
import { Theme } from "../../../theme/css/theme";
import * as actions from "../../../store/actions";

import Flickity from "react-flickity-component";
import "../../../theme/css/flickity.css";

const _ = require("lodash");
const moment = require("moment");

function RectangleImageSlider(props) {
  let { sliderData } = props;
  const [offerIndex, setOfferIndex] = useState(null);
  const dispatch = useDispatch();

  const handleOnClick = (slider, index) => {
    setOfferIndex(index);
    slider.offer_detail.is_saved.flag = true;
    slider.offer_detail.is_saved.saved_offer_id = slider.id;
    slider.offer_detail.is_from_saved_offer = true;
    props.onClick({
      selectedOffer: slider.offer_detail,
    });
  };

  ////////// favourite funtionality start //////////
  const { isSavedOfferDeleted } = useSelector((state) => ({
    isSavedOfferDeleted: state.savedOfferState.isSavedOfferDeleted,
  }));

  if (_.get(isSavedOfferDeleted, "flag")) {
    //from saved slider
    if (
      _.get(sliderData[offerIndex], "id") ===
      _.get(isSavedOfferDeleted, "offerId")
    ) {
      delete sliderData[offerIndex];
      // dispatch(actions.initialization());
      window.$("#offerDetailsModal").modal("hide");
    }

    //from other slider
    sliderData.map((slider, index) => {
      if (
        _.get(slider, "offer_detail") &&
        _.get(slider.offer_detail, "id") ===
          _.get(isSavedOfferDeleted, "offerId")
      ) {
        delete sliderData[index];
        // dispatch(actions.initialization());
      }
    });
  }

  // after delete offer
  // if (_.get(isSavedOfferDeleted, "flag")) {
  //   if (
  //     _.get(sliderData[offerIndex], "id") ===
  //     _.get(isSavedOfferDeleted, "offerId")
  //   ) {
  //     delete sliderData[offerIndex];
  //     dispatch(actions.initialization());
  //     window.$("#offerDetailsModal").modal("hide");
  //   }
  // }

  const toggleFavourite = (offerData, index) => {
    setOfferIndex(index);
    dispatch(actions.unSaveOffer(offerData.id, offerData.id));
  };
  ////////// favourite funtionality end //////////

  return (
    <Flickity
      options={{
        pageDots: false,
        cellAlign: "left",
        contain: true,
        groupCells: '90%',
        freeScroll: true,
        freeScrollFriction: 0.02
      }}
    >
      {sliderData
        ? sliderData.map((slider, index) => (
            <React.Fragment key={slider.id + "_" + (Math.floor(Math.random() * 9999) + 999)}>
              <BigBoxImageSlider
                className={sliderData.length - 1 !== index ? "" : "last-slide"}
              >
                <div className="slide-inner" style={{position: 'relative'}} key={index}>
                  <img
                    src={
                      _.get(slider, "offer_detail")
                        ? slider?.offer_detail?.image
                        : null
                    }
                    alt={slider?.offer_detail?.title}
                    onClick={() => handleOnClick(slider)}
                  />
                  <div
                    className="heart-circle"
                    onClick={() => toggleFavourite(slider, index)}
                  >
                    <i className="fa fa-heart" aria-hidden="true"></i>
                  </div>
                  <BigSliderText onClick={() => handleOnClick(slider, index)}>
                    <div className="from-text">
                      From:: <b>{slider?.offer_detail?.brand?.name}</b>
                    </div>
                    <h3 className="heading  d-md-block d-none">
                      {slider?.offer_detail?.title}
                    </h3>
                    <h5 className="heading d-md-none d-block">
                      {slider?.offer_detail?.title}
                    </h5>

                    <div className="days-ago">
                      Added{" "}
                      {moment(new Date()).diff(
                        moment(slider?.offer_detail?.created_at),
                        "days"
                      )}{" "}
                      days ago
                    </div>
                  </BigSliderText>
                </div>
              </BigBoxImageSlider>
              {sliderData.length - 1 === index ? (
                <BigBoxImageSlider className="empty-slide"></BigBoxImageSlider>
              ) : (
                ""
              )}
            </React.Fragment>
          ))
        : ""}
    </Flickity>
  );
}

export default RectangleImageSlider;

const BigSliderText = styled.div`
 /* position: absolute;
  bottom: 10px;
  left: -15px;*/
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 20px;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,0.6474964985994398) 0%, rgba(0,0,0,0.0) 100%);

  h3 {
    max-width: 280px;
    margin: 5px 0 5px;
  }

  .from-text {
    font-weight: bold;
    font-family: ${Theme["$thin-font-family"]};
  }

  @media only screen and (max-width: 1040px) {
    position: absolute;
    bottom: 10px;
    left: -1px;
  }

  @media only screen and (max-width: 767px) {
    position: relative !important;
    bottom: unset;
    left: unset;
    background-color: ${Theme["$gray-103"]};
    padding: 17px 25px;
    max-width: 100%;
    width: 100%;
    min-height: 130px;
    position: relative;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-top: -40px;
    h5 {
      margin: 6px 0;
      line-height: 18px;
      font-size: 16px;
    }

    .from-text {
      color: ${Theme["$gray-25"]};
      font-family: ${Theme["$base-font-family"]};
    }

    .days-ago {
      color: ${Theme["$gray-25"]};
      position: absolute;
      bottom: 25px;
      font-family: ${Theme["$medium-font-family"]};
    }
  }

  @media only screen and (max-width: 575px) {
    h5 {
      font-size: calc(${Theme["$base-f-size"]} + 1px);
    }
  }
`;
