import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, ModalBox } from "../../../theme/css/Global";
import styled from "styled-components";
import apiClient from "../../../store/actions/ApiInterceptor";
import { UnsubscribeConstant } from "../../../constants/FooterConstant";
import { getFields } from "../../shared/FieldRenderer";
import { Form } from "react-bootstrap";

const _ = require("lodash");

function UnsubscribeComponent(props) {
	const [FormData, setFormData] = useState({
		email: "",
		'checkbox-daily': false,
		'checkbox-weekly': false,
		'checkbox-monthly': false,
		'checkbox-unsubscribe': false,
	});
	const [error, setError] = useState({ email: null });
	const [_, setIsLoading] = useState(false);
	/*	const [checkedDaily, setcheckedDaily] = useState(false);
		const [checkedWeekly, setCheckedWeekly] = useState(false);
		const [checkedMonthly, setCheckedMonthly] = useState(false);
		const [checkedUnsubscribe, setCheckedUnsubscribe] = useState(false);*/

	const handleFieldChange = ({ key, value }) => {
		if (key !== 'email') {
			if (key === 'checkbox-unsubscribe') {
				const newFormData = {
					email: FormData.email,
					'checkbox-daily': false,
					'checkbox-weekly': false,
					'checkbox-monthly': false,
					'checkbox-unsubscribe': true,
				}
				setFormData(newFormData);
			} else {
				// if(FormData["checkbox-daily"])
				const newFormData = {
					...FormData,
					email: FormData.email,
					'checkbox-unsubscribe': false,
					[key]: value
				}
				if(!newFormData['checkbox-daily'] &&
				!newFormData['checkbox-monthly'] && !newFormData['checkbox-weekly'])
				{
					newFormData['checkbox-unsubscribe'] = true;
				}
				setFormData(newFormData);
			}
		}
		else {
			setFormData({
				...FormData,
				[key]: value,
			});

		}

		setError({ ...error, [key]: [] });
	};
	function validateEmail (email) {
		const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return regexp.test(email);
	  }
	const handleSubmit = (event) => {
		event.preventDefault(true);
		if(!validateEmail(FormData.email))
		{
			alert('Please provide a valid email address!');
			return;
		}

		setIsLoading(true);
		apiClient
			.post("/unsubscribe/", {
				FormData
			})
			.then((res) => {
				setIsLoading(false);
				window.$("#UnsubscribeSuccessfull").modal("show");
			})
			.catch((error) => {
				setError(_.get(error.response, "data"));
				setIsLoading(false);
			});
	};

	const redirectToPage = (redirectLink) => {
		window.scrollTo(0, 0);
		props.history.push({
			pathname: redirectLink,
		});
	};

	return (
		<React.Fragment>
			<DoNotSell>
				<div className="container pt-5 pb-5">
					<div className="row">
						<div className="col-12">
							<h2>Update Your Email Communication Preferences</h2>
							<h4>
								Select only the marketing messages you would like to receive from OffersClub.com. You will still receive transactional emails including but not limited to information about your account, and certain other updates such as those related to safety and privacy.
							</h4>
							<p>If you don't want to receive any more messages, enter your email address and submit your request.</p>
							<form className="mx-auto  mt-5 mb-5" onSubmit={handleSubmit}>
								<div className="form-group">
									{getFields(
										UnsubscribeConstant,
										FormData,
										error,
										handleFieldChange
									)}
								</div>
								{/*<div>
										<input
											type="checkbox"
											className="custom-control-input"
											id="checkbox-daily"
											name="checkbox-daily"
											checked={checkedDaily}
											onChange={() => setcheckedDaily(!checkedDaily)}
										/>
										<label className="custom-control-label" htmlFor="checkbox-daily">
											Daily Deals. One email a day with a summary of our best deals.
										</label>
									</div>*/}

								{/*<div>
										<input
											type="checkbox"
											className="custom-control-input"
											id="checkbox-weekly"
											name="checkbox-weekly"
											checked={checkedWeekly}
											onChange={() => setCheckedWeekly(!checkedWeekly)}
										/>
										<label className="custom-control-label" htmlFor="checkbox-weekly">
											Weekly Digest. One email a week with a summary of the week's best deals.
										</label>
									</div>*/}

								{/*<div>
										<input
											type="checkbox"
											className="custom-control-input"
											id="checkbox-monthly"
											name="checkbox-monthly"
											checked={checkedMonthly}
											onChange={() => setCheckedMonthly(!checkedMonthly)}
										/>
										<label className="custom-control-label" htmlFor="checkbox-monthly">
											Monthly Digest. One email a month with a summary of the month's best deals.
										</label>
									</div>*/}

								{/*<div>
										<input
											type="checkbox"
											className="custom-control-input"
											id="checkbox-unsubscribe"
											name="checkbox-unsubscribe"
											checked={checkedUnsubscribe}
											onChange={() => setCheckedUnsubscribe(!checkedUnsubscribe)}
										/>
										<label className="custom-control-label" htmlFor="checkbox-unsubscribe">
											Unsubscribe me from all email communication.
										</label>
									</div>*/}



								<div className="text-center">
									<Button type="submit" className="btn btn-primary">
										Submit Request
									</Button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</DoNotSell>

			<ModalBox>
				<div className="modal fade" id="UnsubscribeSuccessfull" role="dialog">
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-header">
								<button type="button" className="close" data-dismiss="modal">
									&times;
                    </button>
							</div>
							<div className="modal-body">
								<div className="info text-center ">
									{" "}
                      Your preferences have been updated.{" "}
								</div>
							</div>
						</div>
					</div>
				</div>
			</ModalBox>

		</React.Fragment>
	);
}

export default withRouter(UnsubscribeComponent);
const DoNotSell = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
`;
