import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastsStore } from "react-toasts";
import ProfilePhoto from "../../../theme/images/default-user.png";
import { withRouter } from "react-router-dom";
import apiClient from "../../../store/actions/ApiInterceptor";
import CircleLoader from "../../shared/CircleLoader";
import ProfilePhotoModal from "./ProfilePhotoModal";
import * as actions from "../../../store/actions";
import MyDetailsGray from "../../../theme/images/my-detail-gray.svg";
import MyDetailsBlue from "../../../theme/images/my-detail-blue.svg";
import EmailGray from "../../../theme/images/email-gray.svg";
import EmailBlue from "../../../theme/images/email-blue.svg";
import LogoutGray from "../../../theme/images/logout-gray.svg";
import LogoutBlue from "../../../theme/images/logout-blue.svg";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
const _ = require("lodash");

function Sidebar(props) {
  const [pathName, setPathName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isClear, setIsClear] = useState(false);
  const dispatch = useDispatch();
  const { UserData } = useSelector((state) => ({
    UserData: state.userState.UserDetails,
  }));

  useEffect(() => {
    setPathName(window.location.pathname);
    localStorage.setItem("refreshPageForMobile", false);
  }, [window.location.pathname]);

  const redirectToPage = (redirectLink) => {
    props.history.push({
      pathname: redirectLink,
    });
  };

  const logout = () => {
    const options = {
      title: 'Log Out',
      message: 'Are you sure you want to log out?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            setIsLoading(true);
            apiClient
                .post("/logout/", {})
                .then((res) => {
                  setIsLoading(true);
                  localStorage.removeItem("token");
                  localStorage.removeItem("resetPassword");
                  // redirectToPage("/dashboard");
                  redirectToPage("/");
                  window.$("#logOutModal").modal("show");
                })
                .catch((error) => {
                  setIsLoading(true);
                });
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    };

    confirmAlert(options);

  };

  const clearFile = () => {
    setIsClear(true);
  };

  const openProfilePhotoModal = () => {
    clearFile();
    window.$("#profilePhotoModal").modal("show");
  };

  const updateProfilePhoto = () => {
    ToastsStore.success("Profile photo updated successfully");
    dispatch(actions.getUserDetails());
  };
  return (
    <React.Fragment>
      <div className="col-xl-3 col-lg-4">
        <div className="profile-info">
          <div className="profile-photo">
            <img
              src={
                _.get(UserData, "profile_photo")
                  ? _.get(UserData, "profile_photo")
                  : ProfilePhoto
              }
              alt="Profile picture"
            />
            <i className="fa fa-pencil" onClick={openProfilePhotoModal}></i>
          </div>
          <div className="float-left pt-2">
            <i className="welcome-back">Welcome back {(_.get(UserData, "first_name") || _.get(UserData, "last_name")) ? ',' : ''}</i>
            <div className="profile-name">
              <b>
                {_.get(UserData, "first_name")} {_.get(UserData, "last_name")}
              </b>
            </div>
          </div>
          <div className="clearfix" />
        </div>
        <h4 className="dark-bold-heading mb-4 pb-1 pl-2 mt-5">My Account</h4>
        <ul className="account-sidebar">
          <li
            className={pathName.includes("mydetails") ? "active" : null}
            onClick={() => redirectToPage("/account/mydetails")}
          >
            <img src={MyDetailsGray} alt="" className="gray detail" />
            <img src={MyDetailsBlue} alt="" className="blue  detail" />

            <span>My Details</span>
          </li>
          <li
            className={pathName.includes("notifications") ? "active" : null}
            onClick={() => redirectToPage("/account/notifications")}
          >
            <img src={EmailGray} alt="" className="gray" />
            <img src={EmailBlue} alt="" className="blue" />
            <span> Email Notifications</span>
          </li>

          <li
            data-toggle="modal"
            data-target="#logOutModal"
            className={isLoading ? "loader-li" : ""}
            onClick={logout}
          >
            <img src={LogoutGray} alt="" className="gray" />
            <img src={LogoutBlue} alt="" className="blue" />

            <span>
              {" "}
              {isLoading ? (
                <div className="log-out-loader">
                  <CircleLoader size={20} />
                </div>
              ) : (
                "Log Out"
              )}
            </span>
          </li>
        </ul>
      </div>
      <ProfilePhotoModal
        userId={UserData?.id}
        updateProfilePhoto={updateProfilePhoto}
        isClear={isClear}
        onClick={() => setIsClear(false)}
      />
    </React.Fragment>
  );
}

export default withRouter(Sidebar);
