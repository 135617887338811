export const CompareOfferConstant = [
  {
    type: "number",
    subType: "number",
    id: "zipCode",
    label: "",
    placeholder: "Enter Zip Code",
    key: "zipCode",
    formClass: "form-control",
    required: true,
    decimalScale: 5,
    // format: "#####"
  },
];