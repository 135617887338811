import React, { useState } from "react";
import SearchIcon from "../../theme/images/search.svg";

const SearchComponent = (props) => {
  const { history, placeholder } = props;
  const [searchKey, setSearchKey] = useState("");
  const [value, setValue] = useState("");

  const fieldChange = (event) => {
    setSearchKey(event.target.value);
    setValue(event.target.value);
  };

  const submitSearchList = (event) => {
    event.preventDefault();
    setValue("")
    history.push(
      { pathname: "/search" },
      { searchKey: searchKey }
    );
  }

  return (
    <form onSubmit={event => submitSearchList(event)}>
      <div className="form-group ">
        <img src={SearchIcon} alt="" className="search-icon" />
        <input
          type="text"
          className="form-control search-input"
          placeholder={placeholder}
          onChange={(event) => fieldChange(event)}
          value={value}
        />
      </div>
    </form>
  );
};

export default SearchComponent;
