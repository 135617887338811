import React, {useEffect} from "react";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";

import MainContainer from "./components/container/MainContainer";
import VerifyAccount from "./components/container/header/VerifyAccount";
import ResetPassword from "./components/container/header/ResetPassword";

function App() {
    const location = useLocation();
    useEffect(() => {
        window.gtag('event', 'page_view', {
            page_location: window.location.href,
            page_path: window.location.pathname + window.location.search,
            page_title:  document.title
        });
    })


    return (
    <React.Fragment>
        <Switch>
          <Route exact path="/verify-account" component={VerifyAccount} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route path="/" component={MainContainer} />
        </Switch>
        <ToastsContainer
          position={ToastsContainerPosition.TOP_RIGHT}
          store={ToastsStore}
          // lightBackground
          className="d-flex flex-row"
        />
    </React.Fragment>
  );
}

export default App;
