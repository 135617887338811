import React, { useState, useEffect, useRef } from "react";
import { ToastsStore } from "react-toasts";
import { PaginationComponent } from "../../shared/PaginationComponent";
import apiClient from "../../../store/actions/ApiInterceptor";
import CircleLoader from "../../shared/CircleLoader";
import OffersDetailsModal from "./OffersDetailsModal";
import { BodyContent, SmallImageSlider } from "../../../theme/css/Global";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions";
import {Helmet} from "react-helmet";
const _ = require("lodash");
const moment = require("moment");

// Hook
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  return ref.current;
}

const SearchOffersList = (props) => {
  const { location } = props;
  const [activePage, setActivePage] = useState(1);
  const [isSearchOffersListLoading, setIsSearchOffersListLoading] = useState(
    false
  );
  const [searchOffersListData, setSearchOffersListData] = useState([]);
  const [selectedOfferDetailsData, setSelectedOfferDetailsData] = useState({});
  const [offerIndex, setOfferIndex] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const prevSearchKey = usePrevious(props?.location?.state?.searchKey);

  const dispatch = useDispatch();

  useEffect(() => {
    if(prevSearchKey !== undefined && (prevSearchKey !== props?.location?.state?.searchKey)) {
      localStorage.setItem("refreshPageForMobile", false);
    }
    if (location.state === undefined) {
      setActivePage(1)
    }
    getSearchOffersList();
  }, [activePage, props.location]);

  const redirectToPage = (redirectLink) => {
    props.history.push({
      pathname: redirectLink,
    });
  };

  const getSearchOffersList = () => {
    if (_.get(location.state, "searchKey")) {
      setIsSearchOffersListLoading(true);
      apiClient
        .get("offer/", {
          params: {
            search: location?.state?.searchKey,
            page: activePage
          },
        })
        .then((res) => {
          setIsSearchOffersListLoading(false);
          setSearchOffersListData(res.data);
          window.addEventListener("resize", () => setWidth(window.innerWidth));
          if(width <= 991 && (localStorage.getItem("refreshPageForMobile") === "false")) {
            localStorage.setItem("refreshPageForMobile", true);
            window.location.reload(false);
          }
        })
        .catch((error) => {
          setIsSearchOffersListLoading(false);
          ToastsStore.error("Unable to fetch offers.");
        });
    }
  };

  const handleClick = (selectedOffer) => {
    window.gtag('event', 'select_promotion', {
      promotions: [{
        id: selectedOffer.id ,
        name: selectedOffer.title,
        creative_slot: window.location.pathname
      }]
    });
    setSelectedOfferDetailsData(selectedOffer);
    window.$("#offerDetailsModal").modal("show");
    window.open(selectedOffer.link);

  };

  ////////// favourite funtionality start //////////

  const {
    UserData,
    savedOfferDetails,
    isSavedOfferDeleted,
    showToaster,
  } = useSelector((state) => ({
    UserData: state.userState.UserDetails,
    savedOfferDetails: state.savedOfferState.savedOfferDetails,
    isSavedOfferDeleted: state.savedOfferState.isSavedOfferDeleted,
    showToaster: state.savedOfferState.showToaster,
  }));

  if (_.get(searchOffersListData, "results", []).length > 0) {
    //after save offer
    if (_.get(savedOfferDetails, "id")) {
      let sliderData = searchOffersListData.results;
      if (
        _.get(searchOffersListData.results[offerIndex], "id") ===
        _.get(savedOfferDetails, "offer_detail.id")
      ) {
        sliderData[offerIndex].is_saved.flag = true;
        sliderData[offerIndex].is_saved.saved_offer_id = savedOfferDetails.id;
        dispatch(actions.initialization());
        setSearchOffersListData({
          ...searchOffersListData,
          results: sliderData,
        });
      }
    }

    //after delete offer
    if (_.get(isSavedOfferDeleted, "flag")) {
      let sliderData = searchOffersListData.results;
      if (
        _.get(sliderData[offerIndex], "id") ===
        _.get(isSavedOfferDeleted, "offerId")
      ) {
        sliderData[offerIndex].is_saved.saved_offer_id = null;
        sliderData[offerIndex].is_saved.flag = false;
        dispatch(actions.initialization());
        setSearchOffersListData({
          ...searchOffersListData,
          results: sliderData,
        });
      }
    }
  }

  const toggleFavourite = (offerData, index) => {
    if (localStorage.getItem("token")) {
      setOfferIndex(index);
      if (offerData.is_saved.flag) {
        dispatch(
          actions.unSaveOffer(offerData.is_saved.saved_offer_id, offerData.id)
        );
      } else if(_.get(UserData ,'id')) {
        dispatch(actions.saveOffer(offerData.id, UserData.id));
      }
      else{
        ToastsStore.error("Something went wrong. Please refresh the page and try again.");
      }
    } else {
      window.$("#recommendedForSignUp").modal("show");
    }
  };

  const ChangeToaster = () => {
    dispatch(actions.closeToaster(null));
  };

  if (showToaster !== null) {
    setTimeout(ChangeToaster, 3000);
  }

  ////////// favourite funtionality end //////////

  const pageChange = ({ pageNumber }) => {
    setActivePage(pageNumber);
  };

  const renderSearchOffers = () => {
    if (_.get(searchOffersListData, "results", []).length > 0) {
      return searchOffersListData.results.map((offer, index) => (
        <SmallImageSlider key={offer.id} className="offer-list-box">
          {showToaster && offerIndex === index ? (
            <div className="saved-label">{showToaster} </div>
          ) : null}
          <img
            src={
              _.get(offer, "image")
                ? offer?.image
                : null
            }
            alt={offer?.title}
            onClick={() => handleClick(offer)}
          />
          <div
            className="heart-circle"
            onClick={() => toggleFavourite(offer, index)}
          >
            <i
              className={offer.is_saved.flag ? "fa fa-heart" : "fa fa-heart-o"}
              aria-hidden="true"
            ></i>
          </div>
          <br />
          <div className="slide-info-text" onClick={() => handleClick(offer)}>
            <div className="from-text">
              From <b>{offer.brand.name}</b>
            </div>
            <h5 className="heading">{offer.title}</h5>
            <div className="days-ago">
              Added {moment(new Date()).diff(moment(offer?.created_at), "days")}{" "}
              days ago
            </div>
          </div>
        </SmallImageSlider>
      ));
    } else {
      return (
        <span>
          <h5>No Offers Yet! </h5>
        </span>
      );
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{location?.state?.searchKey ? `${_.capitalize(location?.state?.searchKey)} Offers`:'Search Offers'}</title>
        <meta name="description" content="" />

      </Helmet>
      <BodyContent>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="lighter d-sm-none d-block">
                <span className="pr-2" onClick={() => redirectToPage("/")}>
                  Home
                </span>
                /<span className="active pl-2">Search Offers</span>
              </div>
              {location?.state?.searchKey ? (
                <h1 className="list-heading">
                  {_.capitalize(location?.state?.searchKey)} Offers
                  <br className="d-sm-none d-block" />
                  <span className="ml-sm-4">
                    {searchOffersListData?.count
                      ? `${searchOffersListData?.count} Offers Available`
                      : null}
                  </span>
                </h1>
              ) : null}
              <div className="lighter  d-sm-block d-none">
                <span className="pr-2" onClick={() => redirectToPage("/")}>
                  Home
                </span>
                /<span className="active pl-2">Search Offers</span>
              </div>
            </div>
            <div className="col-12 d-sm-none d-block pt-2">
              <div className="description-para">
                AAG believes in better.
                <br /> At American Advisors Group, we strive to deliver the most
                considerate solution and personalized service to our
                customers-all in an effort to help them and all older adults
                have the retirement they deserve.
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {!isSearchOffersListLoading ? (
                renderSearchOffers()
              ) : (
                <CircleLoader />
              )}
              <OffersDetailsModal offerDetails={selectedOfferDetailsData} />
            </div>
          </div>
          <div className="row">
            {_.get(searchOffersListData, "count") &&
            searchOffersListData.count > 25 ? (
              <PaginationComponent
                activePage={activePage}
                totalItemsCount={
                  _.get(searchOffersListData, "count")
                    ? searchOffersListData.count
                    : 0
                }
                onChange={pageChange}
              ></PaginationComponent>
            ) : (
              ""
            )}
          </div>
        </div>
      </BodyContent>
    </React.Fragment>
  );
};

export default SearchOffersList;
