// import "date-fns";
import React from "react";
import PropTypes from "prop-types";
// import DatePicker from "react-datepicker";
import DatePicker from "react-date-picker";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorLabel } from "../../theme/css/Global";

export default function DatePickerField(props) {
  const { value, error } = props;

  return (
    <div className="form-group">
      <label>Birth date</label>
      <br />
      <div>
        <DatePicker
          className="form-control w-100 birth-date"
          calendarClassName="react-calendar"
          format="MM/dd/yyyy"
          onChange={(date) =>
            props.onChange({
              key: props.fieldKey,
              value: date,
            })
          }
          value={value && new Date(value)}
          calendarIcon={null}
          clearIcon={null}
          maxDate={new Date()}
          dayPlaceholder="dd"
          monthPlaceholder="mm"
          yearPlaceholder="yyyy"
          disableCalendar
        />
        {error && error.length !== 0 ? (
          <div>
            <ErrorLabel>{error}</ErrorLabel>
          </div>
        ) : null}
      </div>
    </div>
  );
}

DatePickerField.propTypes = {
  value: PropTypes.string,
};
