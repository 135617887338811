import React from "react";
import { MapPaths, MapRects, MapTexts } from "./MapDimensionConstant";

const USAMapComponent = (props) => {
  const onMapClick = (event, id, zipCode) => {
    props.onClick({
      state: id,
      zipCode: zipCode,
    });
  };

  const renderMapPaths = () => {
    return MapPaths.map((path, index) => (
      <path
        id={path.id}
        data-info={path.dataInfo}
        fill={path.fill}
        d={path.d}
        onClick={(event) => onMapClick(event, path.id, path.zipCode)}
      />
    ));
  };

  const renderMapRects = () => {
    return MapRects.map((rect) => (
      <rect
        id={rect.id}
        data-info={rect.dataInfo}
        x={rect.x}
        y={rect.y}
        width={rect.width}
        height={rect.height}
        r={rect.r}
        rx={rect.rx}
        ry={rect.ry}
        fill={rect.fill}
        stroke={rect.stroke}
        transform={rect.transform}
        stroke-width={rect.strokeWidth}
        fill-opacity={rect.fillOpacity}
        stroke-opacity={rect.strokeOpacity}
        stroke-linejoin={rect.strokeLinejoin}
      ></rect>
    ));
  };

  const renderMapTexts = () => {
    return MapTexts.map((text) => (
      <text
        id={text.id}
        x={text.x}
        y={text.y}
        dx={text.dx}
        fill={text.fill}
        onClick={(event) => onMapClick(event, text.id, text.zipCode)}
      >
        {text.id}
      </text>
    ));
  };

  return (
    <div>
      <svg
        xmlnsCc="http://creativecommons.org/ns#"
        xmlnsDc="http://purl.org/dc/elements/1.1/"
        xmlnsRdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlnsInkscape="http://www.inkscape.org/namespaces/inkscape"
        xmlnsSodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlnsSvg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="us-map"
        preserveAspectRatio="xMinYMin meet"
        sodipodiDocname="Republican_Party_presidential_primaries_results,_2016.svg"
        inkscapeVersion="0.91 r13725"
        x="0px"
        y="0px"
        width="959px"
        height="593px"
        viewBox="174 100 959 593"
        enable-background="new 174 100 959 593"
        xmlSpace="preserve"
      >
        <sodipodiNamedview
          bordercolor="#666666"
          objecttolerance="10"
          pagecolor="#ffffff"
          borderopacity="1"
          gridtolerance="10"
          guidetolerance="10"
          inkscapeCx="509.19152"
          inkscapeCy="282.2353"
          inkscapeZoom="1.2137643"
          showgrid="false"
          id="namedview71"
          inkscapeCurrent-layer="g5"
          inkscapeWindow-maximized="1"
          inkscapeWindow-y="-8"
          inkscapeWindow-x="-8"
          inkscapePageopacity="0"
          inkscapeWindow-height="1017"
          inkscapeWindow-width="1920"
          inkscapePageshadow="2"
        ></sodipodiNamedview>

        <g id="g5">
          {renderMapPaths()}

          {renderMapRects()}
        </g>

        <g
          font-size="18"
          font-family="sans-serif"
          fill="black"
          stroke="none"
          text-anchor="middle"
        >
          {renderMapTexts()}
        </g>
      </svg>
    </div>
  );
};

export default USAMapComponent;
