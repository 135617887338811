import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Button, ModalBox, ErrorLabel } from "../../../theme/css/Global";
import apiClient from "../../../store/actions/ApiInterceptor";
import { getFields } from "../../shared/FieldRenderer";
import { ForgotPasswordFormConstant } from "../../../constants/ForgotPasswordConstant";
import { SignInFormConstant } from "../../../constants/SignInConstant";
import CircleLoader from "../../shared/CircleLoader";
import UserIcon from "../../../theme/images/user.svg";
import * as actions from "../../../store/actions";

import SetPassword from "./ResetPasswordModal";
const _ = require("lodash");

function SignIn(props) {
  const [signInFormData, setSignInFormData] = useState({
    email: "",
    password: "",
  });
  const [forgotPasswordFormData, setForgotPasswordFormData] = useState({
    email: "",
  });
  const [error, setError] = useState({ email: null });
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (_.get(props.state, "isResetPassword")) {
      window.$("#resetPasswordModal").modal("show");
    }
    if (_.get(props, "isClearData")) {
      clearError();
      props.onClick();
    }
  });

  const handleFieldChange = ({ key, value }) => {
    setSignInFormData({
      ...signInFormData,
      [key]: value,
    });

    setError({ ...error, [key]: [] });
  };

  const handleSubmit = (event) => {
    event.preventDefault(true);
    setIsLoading(true);
    apiClient
      .post("login/", {
        email: signInFormData.email,
        password: signInFormData.password,
      })
      .then((res) => {
        setIsLoading(false);
        window.$("#LoginModal").modal("hide");
        localStorage.setItem("token", res.data.token);
        dispatch(actions.getUserDetails());
        props.history.push({
          pathname: "/",
        });
      })
      .catch((error) => {
        setError(_.get(error.response, "data"));
        setIsLoading(false);
      });
  };

  const handleForgotPasswordFieldChange = ({ key, value }) => {
    setForgotPasswordFormData({
      ...forgotPasswordFormData,
      [key]: value,
    });

    setError({ ...error, [key]: [] });
  };

  const handleForgotPasswordSubmit = (event) => {
    event.preventDefault(true);
    setIsLoading(true);
    apiClient
      .post("request-password-reset/", {
        email: forgotPasswordFormData.email,
      })
      .then((res) => {
        window.$("#forgotPassword").modal("hide");
        window.$("#sendResetPasswordEmail").modal("show");
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.response.data);
        setIsLoading(false);
      });
  };

  const clearError = () => {
    setError({ email: "", password: "" });
    setSignInFormData({ email: "", password: "" });
    setForgotPasswordFormData({ email: "" });
  };

  const signInModal = () => {
    return (
      <ModalBox>
        <div className="modal fade" id="LoginModal" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <h2>Offers Club Log In </h2>
                <form onSubmit={handleSubmit}>
                  <div className="form-label-group pt-2 mt-4">
                    {getFields(
                      SignInFormConstant,
                      signInFormData,
                      error,
                      handleFieldChange
                    )}
                    <div>
                      {_.get(error, "detail") ? (
                        <ErrorLabel>{_.get(error, "detail")}</ErrorLabel>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <Button type="submit" className="btn btn-primary w-100">
                    {isLoading ? (
                      <CircleLoader size={20} color="#ffffff" />
                    ) : (
                      "Log In"
                    )}
                  </Button>
                  <div
                    className="forgot-link text-center mt-4"
                    data-toggle="modal"
                    data-target="#forgotPassword"
                    data-dismiss="modal"
                    onClick={clearError}
                  >
                    Forgot your password?
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ModalBox>
    );
  };

  const forgotPasswordModal = () => {
    return (
      <ModalBox>
        <div className="modal fade" id="forgotPassword" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <h2 className="text-center">Forgot Password</h2>

                <p className="small mt-3 mb-3 text-center">
                  Enter your email address below and we'll send a link to you
                  for reset your password.
                </p>

                <form onSubmit={handleForgotPasswordSubmit}>
                  <div className="form-label-group  mt-4">
                    {getFields(
                      ForgotPasswordFormConstant,
                      forgotPasswordFormData,
                      error,
                      handleForgotPasswordFieldChange
                    )}
                  </div>
                  <Button
                    type="submit"
                    className="btn btn-primary w-100 mt-2 mb-3"
                  >
                    {isLoading ? (
                      <CircleLoader size={20} color="#ffffff" />
                    ) : (
                      "Send Email"
                    )}
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ModalBox>
    );
  };

  const forgotPasswordSuccesModal = () => {
    return (
      <ModalBox>
        <div className="modal fade" id="sendResetPasswordEmail" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div className="info text-center ">
                  {" "}
                  A email has been sent to{" "}
                </div>
                <div className="email-data text-center mb-4">
                  {forgotPasswordFormData.email}
                </div>

                <p className="small  mb-3 text-center small">
                  Simply click on the given link in your email <br />
                  to reset your account password.
                </p>
              </div>
            </div>
          </div>
        </div>
      </ModalBox>
    );
  };

  const logoutModal = () => {
    return (
      <React.Fragment>
        <ModalBox className="log-out-modal">
          <div className="modal fade" id="logOutModal" role="dialog">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>

                <div className="modal-body text-center">
                  <img src={UserIcon} alt="" className="mb-3" />
                  <h2 className="log-out-heading">
                    You've Been
                    <br /> Logged Out.
                  </h2>
                  <p className="small pt-2">
                    We're redirecting you to our homepage.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ModalBox>
      </React.Fragment>
    );
  };

  const RecommendedForSignUpModal = () => {
    return (
      <React.Fragment>
        <ModalBox className="log-out-modal">
          <div className="modal fade" id="recommendedForSignUp" role="dialog">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>

                <div className="modal-body text-center">
                  {/* <img src={UserIcon} alt="" /> */}
                  <h3 className="only-heading">
                    Log in or sign up to save
                    <br className="d-sm-block d-none" />
                    your favorite offers!
                  </h3>
                  <Button
                    className="btn btn-primary mt-4 mb-3 mx-auto"
                    data-toggle="modal"
                    data-target="#joinForFree"
                    data-dismiss="modal"
                    onClick={() => goToTop()}
                  >
                    Join for Free
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </ModalBox>
      </React.Fragment>
    );
  };

  const goToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <React.Fragment>
      {signInModal()}
      {forgotPasswordModal()}
      {forgotPasswordSuccesModal()}
      <SetPassword state={_.get(props.location, "state")} />
      {logoutModal()}
      {RecommendedForSignUpModal()}
    </React.Fragment>
  );
}
export default withRouter(SignIn);
