import React from "react";
import PropTypes from "prop-types";

const CheckboxField = (props) => {
  const fieldChange = (event) => {
    props.onChange({
      key: props.fieldKey,
      value: event.target.checked,
    });
  };

  const { value, subType, id, title, description } = props;

  return (
    <React.Fragment>
      <div className="form-group">
        <input
          type={subType}
          className="custom-control-input"
          id={id}
          name={id}
          checked={value}
          onChange={(event) => fieldChange(event)}
        />
        <label className="custom-control-label" htmlFor={id}>
          <span className="label-title">{title}</span>
          <p className="mb-0">{description}</p>
        </label>
      </div>
    </React.Fragment>
  );
};

CheckboxField.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.array,
};

export default CheckboxField;
