import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import apiClient from "../../../store/actions/ApiInterceptor";
import ImageSlider from "../../shared/imageslider/ImageSlider";
import CircleLoader from "../../shared/CircleLoader";
import * as actions from "../../../store/actions";

const _ = require("lodash");

function BigImageSlider(props) {
  const { history, filters, offerType } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [savedOfferListData, setSavedOfferListData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getSavedOffers();
  }, []);

  const { isOfferAdd } = useSelector((state) => ({
    isOfferAdd: state.savedOfferState.isUpdated,
  }));

  const getSavedOffers = () => {
    setIsLoading(true);
    apiClient
      .get("saved-offer/")
      .then((res) => {
        setIsLoading(false);
        setSavedOfferListData(res.data);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  if (isOfferAdd) {
    getSavedOffers();
    dispatch(actions.reloadSavedSlider());
  }

  const redirectToSeeAll = () => {
    history.push(
      { pathname: "/saved-offers" },
      { offerType: offerType, filters: filters }
    );
  };

  const renderSavedOffers = () => {
    return (
      <React.Fragment>
        {_.get(savedOfferListData, "results", []).length > 0 ? (
          <div>
            <div className="container">
              <div className="row mt-3">
                <div className="col-6">
                  <h4>{offerType}</h4>
                </div>
                <Link
                  style={{ color: "#FFF" }}
                  target="_blank"
                  to={{
                    pathname: `https://offersclub.com/saved-offers`,
                    state: {
                      offerType: offerType,
                      filters: filters,
                    },
                  }}
                >
                  <div className="col-6 text-right see-all-cursor">
                    <span
                      className="see-all"
                      // onClick={(event) => redirectToSeeAll(event)}
                    >
                      See All
                    </span>
                  </div>
                </Link>
              </div>
            </div>
            <div className="big-image-slider">
              <ImageSlider
                sliderData={savedOfferListData.results}
                sliderFor="rectangle"
                {...props}
              />
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {!isLoading ? renderSavedOffers() : <CircleLoader />}
    </React.Fragment>
  );
}

export default BigImageSlider;
