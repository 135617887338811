import React, { useEffect, useState } from "react";
import { ToastsStore } from "react-toasts";
import { PaginationComponent } from "../../shared/PaginationComponent";
import apiClient from "../../../store/actions/ApiInterceptor";
import CircleLoader from "../../shared/CircleLoader";
import OffersDetailsModal from "./OffersDetailsModal";
import { BodyContent, SmallImageSlider } from "../../../theme/css/Global";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions";
const _ = require("lodash");
const moment = require("moment");

function SavedOfferList(props) {
  const { location } = props;
  const [isListLoading, setIsListLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [offerListData, setOffersListData] = useState([]);
  const [selectedOfferDetailsData, setSelectedOfferDetailsData] = useState({});
  const [offerIndex, setOfferIndex] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem("refreshPageForMobile", false);
    if (!localStorage.getItem("token")) {
      // return redirectToPage("/dashboard");
      return redirectToPage("/");
    }
    if (location.state === undefined) {
      location["state"] = { filters: { page: 1 } };
    }
    getSavedOfferList();
  }, [activePage, props.location]);

  const redirectToPage = (redirectLink) => {
    props.history.push({
      pathname: redirectLink,
    });
  };

  ////////// favourite funtionality start //////////

  const { isSavedOfferDeleted } = useSelector((state) => ({
    isSavedOfferDeleted: state.savedOfferState.isSavedOfferDeleted,
  }));

  if (_.get(offerListData, "results", []).length > 0) {
    //after delete offer
    if (_.get(isSavedOfferDeleted, "flag")) {
      let sliderData = offerListData.results;
      if (
        _.get(sliderData[offerIndex], "id") ===
        _.get(isSavedOfferDeleted, "offerId")
      ) {
        delete sliderData[offerIndex];
        dispatch(actions.initialization());
        setOffersListData({
          ...offerListData,
          results: sliderData,
        });
        window.$("#offerDetailsModal").modal("hide");
      }
    }
  }

  const toggleFavourite = (offerData, index) => {
    setOfferIndex(index);
    dispatch(actions.unSaveOffer(offerData.id, offerData.id));
  };

  ////////// favourite funtionality end //////////

  const getSavedOfferList = () => {
    setIsListLoading(true);
    location["state"]["filters"]["page"] = activePage;
    apiClient
      .get("saved-offer/", {
        params: location.state.filters,
      })
      .then((res) => {
        setIsListLoading(false);
        setOffersListData(res.data);
      })
      .catch((error) => {
        setIsListLoading(false);
        ToastsStore.error("Unable to fetch offers.");
      });
  };

  const handleClick = (selectedOffer, index) => {
    window.gtag('event', 'select_promotion', {
      promotions: [{
        id: selectedOffer.offer_detail.id ,
        name: selectedOffer.offer_detail.title,
        creative_slot: window.location.pathname
      }]
    });
    setOfferIndex(index);
    selectedOffer.offer_detail.is_saved.flag = true;
    selectedOffer.offer_detail.is_saved.saved_offer_id = selectedOffer.id;
    selectedOffer.offer_detail.is_from_saved_offer = true;
    setSelectedOfferDetailsData(selectedOffer.offer_detail);
    window.$("#offerDetailsModal").modal("show");
    window.open(selectedOffer.offer_detail.link);

  };

  const renderSavedOffers = () => {
    if (_.get(offerListData, "results", []).length > 0) {
      return offerListData.results.map((offer, index) => (
        <SmallImageSlider key={offer.id} className="offer-list-box">
          <img
            src={
              _.get(offer, "offer_detail")
                ? offer?.offer_detail?.image
                : null
            }
            alt={offer?.offer_detail?.title}
            onClick={() => handleClick(offer, index)}
          />
          <div
            className="heart-circle"
            onClick={() => toggleFavourite(offer, index)}
          >
            <i className="fa fa-heart" aria-hidden="true"></i>
          </div>
          <br />
          <div
            className="slide-info-text"
            onClick={() => handleClick(offer, index)}
          >
            <div className="from-text">
              From <b>{offer?.offer_detail?.brand.name}</b>
            </div>
            <h5 className="heading">{offer?.offer_detail?.title}</h5>
            <div className="days-ago">
              Added{" "}
              {moment(new Date()).diff(
                moment(offer?.offer_detail?.created_at),
                "days"
              )}{" "}
              days ago
            </div>
          </div>
        </SmallImageSlider>
      ));
    } else {
      return (
        <span>
          <h5>My Saved Offers is empty!</h5>
        </span>
      );
    }
  };

  const pageChange = ({ pageNumber }) => {
    setActivePage(pageNumber);
  };

  return (
    <React.Fragment>
      <BodyContent>
        <div className="container">
          <div className="row">
            <div className="col-12 mt-md-4 mt-0">
              <div className="lighter d-sm-none d-block">
                <span className="pr-2" onClick={() => redirectToPage("/")}>
                  Home
                </span>
                /
                <span className="active pl-2">
                  {_.get(location.state, "offerType")}
                </span>
              </div>
              <h1 className="list-heading">
                Your {_.get(location.state, "offerType")}
                <br className="d-sm-none d-block" />
              </h1>
              <div className="lighter  d-sm-block d-none">
                <span className="pr-2" onClick={() => redirectToPage("/")}>
                  Home
                </span>
                /
                <span className="active pl-2">
                  {_.get(location.state, "offerType")}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {!isListLoading ? renderSavedOffers() : <CircleLoader />}
              <OffersDetailsModal offerDetails={selectedOfferDetailsData} />
            </div>
          </div>
          <div className="row">
            {_.get(offerListData, "count") && offerListData.count > 10 ? (
              <PaginationComponent
                activePage={activePage}
                totalItemsCount={
                  _.get(offerListData, "count") ? offerListData.count : 0
                }
                onChange={pageChange}
              ></PaginationComponent>
            ) : (
              ""
            )}
          </div>
        </div>
      </BodyContent>
    </React.Fragment>
  );
}

export default SavedOfferList;
