export const EmailNotificationsConstant = [
  {
    type: "checkbox",
    subType: "checkbox",
    id: "daily_offers",
    key: "daily_offers",
    title:'Daily Offers',
    description:'The best offers of the day from our favorite retailers.',
    formClass: "custom-control-input"
  },
  {
    type: "checkbox",
    subType: "checkbox",
    id: "personal_recommendations",
    key: "personal_recommendations",
    title:'Personal Recommendations',
    description:"2-3 emails per week based on offers you've viewed or saved",
    formClass: "custom-control-input"
  },
  {
    type: "checkbox",
    subType: "checkbox",
    id: "offers_club_newsletter",
    key: "offers_club_newsletter",
    title:'Offers Club Newsletter',
    description:"A weekly roundup of what's new as well as featured offers",
    formClass: "custom-control-input"
  },
  {
    type: "checkbox",
    subType: "checkbox",
    id: "offers_club_news",
    key: "offers_club_news",
    title:'Offers Club News',
    description:'Stay up-to-date on the latest tools and features from Offers Club.',
    formClass: "custom-control-input"
  },
];

export const EmailNotificationsUnsubcribeConstant = [
  {
    type: "checkbox",
    subType: "checkbox",
    id: "unsubscribe",
    key: "unsubscribe",
    title:'unsubscribe from all (not recommended)',
    description:'You will still get important emails about your account.',
    formClass: "custom-control-input"
  }
];
