import React, { useState } from "react";
import PropTypes from "prop-types";
import { UpdatePhotoModal } from "../../../theme/css/Global";

import FileCrop from "../../shared/FileCrop";

function ProfilePhotoModal(props) {
  const [isClear, setIsClear] = useState(false);

  const clearFile = () => {
    setIsClear(true);
  };

  return (
    <UpdatePhotoModal>
      <div
        className="modal fade"
        tabindex="-1"
        id="profilePhotoModal"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="profile-photo-heading mb-0">
                Update Profile Photo
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => clearFile()}
              >
                {" "}
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <FileCrop
                updateProfilePhoto={props.updateProfilePhoto}
                userId={props.userId}
                {...props}
              ></FileCrop>
            </div>
          </div>
        </div>
      </div>
    </UpdatePhotoModal>
  );
}
export default ProfilePhotoModal;

ProfilePhotoModal.propTypes = {
  userDetails: PropTypes.object,
  onHide: PropTypes.func,
  profilePhotoUpdate: PropTypes.func,
  show: PropTypes.bool,
};
