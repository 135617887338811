import React, { useEffect, useState } from "react";
import { ToastsStore } from "react-toasts";
import apiClient from "../../../store/actions/ApiInterceptor";
import ImageSlider from "../../shared/imageslider/ImageSlider";
const _ = require("lodash");

function Categories(props) {
  // const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    // setIsLoading(true);
    apiClient
      .get("category/")
      .then((res) => {
        // setIsLoading(false);
        setCategories(res.data);
      })
      .catch((error) => {
        // setIsLoading(false);
        ToastsStore.error("Unable to fetch categories.");
      });
  };

  return (
    <React.Fragment>
      {_.get(categories, "results") ? (
        <div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h4>Categories</h4>
              </div>
            </div>
          </div>
          <div className="shadow-padding">
            <ImageSlider
              sliderData={categories?.results}
              sliderFor="circle"
              sliderType="category_name"
              {...props}
            />
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
}

export default Categories;
