import React, { useEffect, useState } from "react";
import { Button, ModalBox } from "../../../theme/css/Global";

import { getFields } from "../../shared/FieldRenderer";
import {
    SignUpFormConstant,
    VerificationFormConstant,
} from "../../../constants/SignUpConstant";
import apiClient from "../../../store/actions/ApiInterceptor";
import CircleLoader from "../../shared/CircleLoader";
import SuccessIcon from "../../../theme/images/sucess.svg";
import FailIcon from "../../../theme/images/fail.svg";

const _ = require("lodash");

function SignUpPage(props) {
    const [signUpFormData, setSignUpFormData] = useState({
        email: "",
        password: "",
        confirmPassword: "",
    });
    const [error, setError] = useState({ email: null });
    const [isLoading, setIsLoading] = useState(false);
    const [verificationEmailData, setVerificationEmailData] = useState({
        email: "",
    });
    const [step, setStep] = useState('signup');

    useEffect(() => {
        if (_.get(props.state, "isVerify")) {
            window.$("#VerificationSuccessful").modal("show");
        } else if (_.get(props.state, "key")) {
            window.$("#VerificationFail").modal("show");
        }

        if (_.get(props, "isClearData")) {
            clearError();
            props.onClick();
        }
    });

    const handleFieldChange = ({ key, value }) => {
        setSignUpFormData({
            ...signUpFormData,
            [key]: value,
        });

        setError({ ...error, [key]: [] });
    };

    const verificationFieldChange = ({ key, value }) => {
        setVerificationEmailData({
            ...verificationEmailData,
            [key]: value,
        });

        setError({ ...error, [key]: [] });
    };

    const handleSubmit = (event) => {
        event.preventDefault(true);
        setIsLoading(true);
        apiClient
            .post("register/", {
                email: signUpFormData.email,
                password: signUpFormData.password,
            })
            .then((res) => {
                setIsLoading(false);
                setStep('sendVerificationEmail');
            })
        .catch((error) => {
            setError(_.get(error.response, "data"));
            setIsLoading(false);
        });
    };

    const clearError = () => {
        setError({ email: "", password: "" });
        setSignUpFormData({ email: "", password: "" });
    };

    const signUp = () => {
        return (

            <div className="" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 'calc(100% - 300px)' }}>
                <div>
                    <h2>
                        Create your Offers Club account
                    </h2>
                    <div className="modal-sub-heading">
                        Sign up for great deals to save for later or use on the go.{" "}
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="form-label-group pt-sm-2 mt-4">
                            {getFields(
                                SignUpFormConstant,
                                signUpFormData,
                                error,
                                handleFieldChange
                            )}
                        </div>
                        <p className="small pb-3 pb-2 mb-0 pt-sm-0 pt-0">
                            By signing up, you agree to OffersClub.com
                    <br />
                            <a href="/terms-and-conditions"> terms of service </a> and{" "}
                            <a href="/privacy-policy">privacy policy. </a>
                        </p>
                        <Button
                            type="submit"
                            className="btn btn-primary w-100"
                            data-toggle="modal"
                        >
                            {isLoading ? (
                                <CircleLoader size={20} color="#ffffff" />
                            ) : (
                                    "Sign Up"
                                )}
                        </Button>
                    </form>
                </div>
            </div>
        );
    };

    const verificationEmailSendModal = () => {
        return (

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 'calc(100% - 300px)' }}>
                <div className="info text-center">
                    {" "}
                    <h4>
                        A verification email has been sent to{" "}
                    </h4>
                </div>
                <div className="email-data text-center mb-3">
                    <h3>
                        {signUpFormData.email}
                    </h3>
                </div>

                <p className="medium  mb-3 text-center">
                    Simply click on the verification link in your email <br />
                  to verify your account.
                  <br />
                    <br />
                  If you didn’t see our email, check your spam <br />
                  folder. You can also resend it.
                </p>
            </div>

        );
    };

      
    return (
        <React.Fragment>
            {step === 'signup' && signUp()}
            {step === 'sendVerificationEmail' && verificationEmailSendModal()}

        </React.Fragment>
    );
}
export default SignUpPage;
