//comman action
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const REGISTER_EMAIL_ID = 'REGISTER_EMAIL_ID';
export const REGISTER_EMAIL_ID_LOADER_STATE = 'REGISTER_EMAIL_ID_LOADER_STATE';
export const SET_ERROR = 'SET_ERROR';

//saved offer action
export const SAVE_OFFER = 'SAVE_OFFER';
export const UNSAVED_OFFER = 'UNSAVED_OFFER';
export const LOADER_STATE = 'LOADER_STATE';
export const INITIALIZATION  = 'INITIALIZATION';
export const TOGGLE_IS_UPDATED = 'TOGGLE_IS_UPDATED';
export const TOGGLE_TOASTER = 'TOGGLE_TOASTER';