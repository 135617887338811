import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import NumberFormat from "react-number-format";
import "react-datepicker/dist/react-datepicker.css";
import {ToastsStore} from "react-toasts";
import ProgressBar from "react-bootstrap/ProgressBar";

import PhoneIcon from "../../../theme/images/call.svg";
import DownarrowIcon from "../../../theme/images/down-arrow.png";
import RightArrow from "../../../theme/images/right-arrowe.svg";
import GreenRightTick from "../../../theme/images/green-right-tick.png";
import apiClient from "../../../store/actions/ApiInterceptor";
import CircleLoader from "../../shared/CircleLoader";
import {Helmet} from "react-helmet";

const _ = require("lodash");
const moment = require("moment");

function LandingPage(props) {
	const [userDetailsFormData, setUserDetailsFormData] = useState({
		Gender: "Male",
		Plan_Type: "Medicare Supplement",
	});
	const [cityPlaceholder, setCityPlaceholder] = useState({City: ""});
	const [userDetailsError, setUserDetailsError] = useState({
		Gender: "",
		Plan_Type: "",
	});
	const [isLoading, setIsLoading] = useState(false);
	const [progressBarStatus, setProgressBarStatus] = useState(0);
	const [isLoadingProgressBar, setIsLoadingProgressBar] = useState(false);
	let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

	useEffect(() => {
		getZipCodeDetails(props.match.params.zipcode);
	}, []);

	useEffect(() => {
		let field = 'xxTrustedFormCertUrl';
		let provideReferrer = false;
		let invertFieldSensitivity = false;
		let tf = document.createElement('script');
		tf.type = 'text/javascript';
		tf.async = true;
		tf.src = 'http' + ('https:' === document.location.protocol ? 's' : '') +
			'://api.trustedform.com/trustedform.js?provide_referrer=' + escape(provideReferrer) + '&field=' + escape(field) + '&l=' + new Date().getTime() + Math.random() + '&invert_field_sensitivity=' + invertFieldSensitivity;
		let s = document.getElementsByTagName('script')[0];
		s.parentNode.insertBefore(tf, s);
		return () => {
			try {
				s.parentNode.removeChild(tf);
			} catch (_) {

			}
		}
	}, [])

	useEffect(() => {
		window.setUniversalLeadId = (data) => window.leadid_token = data;
		let s = document.createElement("script");
		s.id = "LeadiDscript_campaign";
		s.type = "text/javascript";
		s.async = true;
		s.src =
			"//create.lidstatic.com/campaign/b2897fb6-6a1a-e2c6-067d-85e2fa0ca1ae.js?snippet_version=2&callback=setUniversalLeadId";
		let newScript = document.getElementsByTagName("script")[0];
		newScript.parentNode.insertBefore(s, newScript);
		// const script = document.createElement('script');
		// script.id = 'LeadiDscript_campaign';
		// script.type = 'text/javascript';
		// script.async = true;
		// script.defer = true;
		// script.crossorigin = 'anonymous';
		//
		// script.src = `https://create.lidstatic.com/campaign/B2897FB6-6A1A-E2C6-067D-85E2FA0CA1AE.js?snippet_version=2&callback=setUniversalLeadId`;
		// document.body.appendChild(script);
		return () => {
			try {
				newScript.parentNode.removeChild(newScript);
			} catch (_) {

			}
		}
	}, [])

	const redirectToPage = (redirectLink) => {
		props.history.push({
			pathname: redirectLink,
		});
	};

	const getZipCodeDetails = (zipCode) => {
		axios({
			method: "get",
			url: `https://ziptasticapi.com/${zipCode}`,
		})
			.then((res) => {
				if (_.get(res.data, "error")) {
					setUserDetailsFormData({
						...userDetailsFormData,
						Zip: zipCode,
					});
					setCityPlaceholder({City: ""});
				} else {
					setCityPlaceholder({City: res.data.city});
					setUserDetailsFormData({
						...userDetailsFormData,
						Zip: zipCode,
						State: res.data.state,
					});
				}
				setUserDetailsError({
					...userDetailsError,
					Zip: "",
					City: "",
					State: "",
				});
				setIsLoadingProgressBar(true);
			})
			.catch((_) => {
			});
	};

	const SetprogressState = () => {
		let count = 1;
		for (let element in userDetailsFormData) {
			if (
				userDetailsFormData[element] !== "" &&
				userDetailsFormData[element] !== null
			) {
				if (
					_.has(userDetailsError, element) &&
					_.get(userDetailsError, element) === ""
				) {
					count = count + 9;
				}
			}
		}

		if (_.has(userDetailsFormData, "City")) {
			if (userDetailsFormData.City !== "") {
				count = count + 9;
			} else if (cityPlaceholder.City !== "") {
				count = count + 9;
			}
		} else if (cityPlaceholder.City !== "") {
			count = count + 9;
		}

		setProgressBarStatus(count);
	};

	if (isLoadingProgressBar) {
		SetprogressState();
		setIsLoadingProgressBar(false);
	}

	const fieldChange = (event) => {
		if (
			(event.target.name === "City" &&
				event.target.value === "" &&
				cityPlaceholder.City === "") ||
			(event.target.value === "" && event.target.name !== "City") ||
			(event.target.name === "Zip" && event.target.value.length < 5) ||
			(event.target.name === "Email" && reg.test(event.target.value) === false)
		) {
			setUserDetailsError({
				...userDetailsError,
				[event.target.name]: "This Field is required",
			});
		} else {
			setUserDetailsError({
				...userDetailsError,
				[event.target.name]: "",
			});
		}
		setUserDetailsFormData({
			...userDetailsFormData,
			[event.target.name]: event.target.value,
		});

		if (event.target.name === "Zip" && event.target.value.length === 5) {
			getZipCodeDetails(event.target.value);
		}
	};

	const getAge = (birthDate) => {
		return Math.floor(
			(new Date() - new Date(birthDate).getTime()) / 3.15576e10
		);
	};

	const clearFormData = () => {
		setUserDetailsFormData({Gender: "Male"});
		setUserDetailsError({});
	};

	const CheckValidations = (data) => {
		let flag = false;
		let keySet = {
			Email: "",
			First_Name: "",
			Last_Name: "",
			Primary_Phone: "",
			Date_Of_Birth: "",
			Zip: "",
			City: "",
			State: "",
			Address: "",
			Plan_Type: "",
		};

		for (let element in keySet) {
			if (
				element === "City" &&
				(data[element] === undefined || data[element] === "") &&
				cityPlaceholder.City === ""
			) {
				keySet[element] = "This field is required.";
				flag = true;
			} else if (
				(data[element] === undefined || data[element] === "") &&
				element !== "City"
			) {
				keySet[element] = "This field is required.";
				flag = true;
			} else if (element === "Email" && reg.test(data[element]) === false) {
				keySet[element] = "Invalid email address.";
				flag = true;
			} else if (element === "Zip" && data[element].length < 5) {
				keySet[element] = "Invalid zip code.";
				flag = true;
			} else if (element === "Date_Of_Birth") {
				if (
					moment(data.Date_Of_Birth).format("MM/DD/YYYY") !== "Invalid date"
				) {
					let maxDate = moment(moment().format("MM/DD/YYYY"));
					let minDate = moment(moment("01/01/1900").format("MM/DD/YYYY"));
					let birthDate = moment(
						moment(data.Date_Of_Birth).format("MM/DD/YYYY")
					);

					if (birthDate > maxDate || birthDate < minDate) {
						keySet[element] = "Invalid date";
						flag = true;
					}
				} else {
					keySet[element] = "Invalid date";
					flag = true;
				}
			}
		}
		if (flag === true) {
			setUserDetailsError(keySet);
		}
		setIsLoadingProgressBar(true);
		return flag;
	};

	const formatFormData = () => {
		let data = userDetailsFormData;
		const age = getAge(
			moment(userDetailsFormData.Date_Of_Birth).format("YYYY-MM-DD")
		);
		const current = new Date();
		const Universal = current.toISOString();
		let lead_born_est = current.toISOString().slice(0, -1) + "+00:00";

		data.Date_Of_Birth = moment(userDetailsFormData.Date_Of_Birth).format(
			"MM-DD-YYYY"
		);
		if (
			_.has(userDetailsFormData, "City") &&
			_.get(userDetailsFormData, "City") !== ""
		) {
			data.City = userDetailsFormData.City;
		} else {
			data.City = cityPlaceholder.City;
		}
		data.Age = age;
		data.timestamp = Universal;
		data.Comments = "";
		data.TCPA_Consent = "Yes";
		data.TCPA_Language = "english";
		data.lead_born_est = lead_born_est;
		data.Landing_Page = window.location.origin;
		data.universal_leadid = window.leadid_token;
		data.xxTrustedFormToken = document.getElementsByName('xxTrustedFormToken')[0].value;
		return data;
	};

	const handleSubmit = async (event) => {
		event.preventDefault(true);
		setIsLoading(true);
		const flag = CheckValidations(userDetailsFormData);
		if (!flag) {
			const data = formatFormData();
			apiClient
				.post("medicaid/", {
					data: data,
				})
				.then(() => {
					setIsLoading(false);
					redirectToPage("/offer/medicaid");
					ToastsStore.success(
						"Thank you for submitting your information!",
						4000
					);
					clearFormData();
					window.scrollTo(0, 0);
				})
				.catch((_) => {
					ToastsStore.error(
						"Something went wrong. Please refresh the page and try again."
					);
					setIsLoading(false);
				});
		} else {
			setIsLoading(false);
		}
	};
	const CheckValidationsField = (event) => {
		const fieldKey = event.target.name;
		const fieldValue = event.target.value;
		let flag = false;
		let keySet = {
			Email: "",
			First_Name: "",
			Last_Name: "",
			Primary_Phone: "",
			Date_Of_Birth: "",
			Zip: "",
			City: "",
			State: "",
			Address: "",
			Plan_Type: "",
		};

		if (_.has(keySet, fieldKey)) {
			if (
				fieldKey === "City" &&
				fieldValue === "" &&
				cityPlaceholder.City === ""
			) {
				flag = true;
			} else if (fieldValue === "" && fieldKey !== "City") {
				flag = true;
			} else if (fieldKey === "Email" && reg.test(fieldValue) === false) {
				flag = true;
			} else if (fieldKey === "Zip" && fieldValue.length < 5) {
				flag = true;
			} else if (fieldKey === "Date_Of_Birth") {
				if (moment(fieldValue).format("MM/DD/YYYY") !== "Invalid date") {
					let maxDate = moment(moment().format("MM/DD/YYYY"));
					let minDate = moment(moment("01/01/1900").format("MM/DD/YYYY"));
					let birthDate = moment(moment(fieldValue).format("MM/DD/YYYY"));

					if (birthDate > maxDate || birthDate < minDate) {
						flag = true;
					}
				} else {
					flag = true;
				}
			}
		}
		if (flag === true) {
			setUserDetailsError({
				...userDetailsError,
				[fieldKey]: "invalid",
			});
		}
		setIsLoadingProgressBar(true);
	};

	const handleBlur = (event) => {
		CheckValidationsField(event);
	};

  return (
    <React.Fragment>
		<Helmet>
			<title>Medicare Supplement</title>
			<meta name="description" content="" />

		</Helmet>
      <StartMyQuote>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="form-container">
                <div className="from-title mt-5 mb-2">
                  Need help? <span className="expert"> Call an Expert </span>
                  <span className="call-box">
                    <img className="ml-2" src={PhoneIcon} alt="call" />{" "}
                    855-848-0196
                    <br />
                    <span className="day-time"> Mon-Sat 8AM-8PM EST</span>
                  </span>
                </div>

                <div className="form-outer">
                  <div className="form-panel">
                    <h2 className="head-tittle">
                      Compare Plans that Save Seniors Thousands each Year!
                    </h2>
                    <div className="row mb-4 no-gutters">
                      <div className="col-md-3 text-right">
                        <div className="progress-title pr-3">Progress:</div>
                      </div>
                      <div className="col-md-7 mt-1">
                        <ProgressBar now={progressBarStatus} />
                      </div>
                    </div>

										<form className="compare-plan-form" onSubmit={handleSubmit}>
											<input id="leadid_token" name="universal_leadid" type="hidden" value=""/>
											<div className="row">
												<div className="col-md-6">
													<div className="form-group mb-4 pb-1">
														<label htmlFor="First_Name">First Name*</label>
														<input
															type="text"
															className={
																_.has(userDetailsError, "First_Name")
																	? _.get(userDetailsError, "First_Name")
																	? "border border-danger form-control "
																	: "border border-success form-control"
																	: "form-control"
															}
															id="First_Name"
															name="First_Name"
															value={
																_.get(userDetailsFormData, "First_Name") || ""
															}
															onChange={(event) => fieldChange(event)}
															onBlur={(event) => handleBlur(event)}
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group mb-4 pb-1">
														<label htmlFor="Last_Name">Last Name*</label>
														<input
															type="text"
															className={
																_.has(userDetailsError, "Last_Name")
																	? _.get(userDetailsError, "Last_Name")
																	? "border border-danger form-control "
																	: "border border-success form-control"
																	: "form-control"
															}
															id="Last_Name"
															name="Last_Name"
															value={
																_.get(userDetailsFormData, "Last_Name") || ""
															}
															onChange={(event) => fieldChange(event)}
															onBlur={(event) => handleBlur(event)}
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group mb-4 pb-1">
														<label htmlFor="Gender">Gender*</label>
														<br/>
														<div className="radio-input float-left">
															<input
																type="radio"
																name="Gender"
																className="custom-control-input"
																id="g-mail"
																value="Male"
																checked={userDetailsFormData.Gender === "Male"}
																onChange={(event) => fieldChange(event)}
															/>
															<label htmlFor="g-mail">Male</label>
														</div>

                            <div className="radio-input float-left ml-3">
                              <input
                                type="radio"
                                name="Gender"
                                className="custom-control-input"
                                id="g-female"
                                value="Female"
                                checked={
                                  userDetailsFormData.Gender === "Female"
                                }
                                onChange={(event) => fieldChange(event)}
                              />
                              <label htmlFor="g-female">Female</label>
                            </div>
                            <div className="clearfix"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-4 pb-1">
                            <label htmlFor="Date_Of_Birth">
                              Date of Birth*
                            </label>
                            <NumberFormat
                              className={
                                _.has(userDetailsError, "Date_Of_Birth")
                                  ? _.get(userDetailsError, "Date_Of_Birth")
                                    ? "border border-danger form-control "
                                    : "border border-success form-control"
                                  : "form-control"
                              }
                              id="Date_Of_Birth"
                              format="##/##/####"
                              name="Date_Of_Birth"
                              value={
                                _.get(userDetailsFormData, "Date_Of_Birth") ||
                                ""
                              }
                              placeholder="MM/DD/YYYY"
                              onChange={(event) => fieldChange(event)}
                              onBlur={(event) => handleBlur(event)}
                            />
                            {/* <DatePicker
                              className="form-control"
                              calendarClassName="react-calendar"
                              format="MM/dd/yyyy"
                              name="Date_Of_Birth"
                              id="Date_Of_Birth"
                              onChange={(date) => birthDateFieldChange(date)}
                              onBlur={() => handleBlur()}
                              value={
                                userDetailsFormData.Date_Of_Birth &&
                                new Date(userDetailsFormData.Date_Of_Birth)
                              }
                              calendarIcon={null}
                              clearIcon={null}
                              maxDate={new Date()}
                              dayPlaceholder="dd"
                              monthPlaceholder="mm"
                              yearPlaceholder="yyyy"
                              disableCalendar
                            /> */}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-4 pb-1">
                            <label htmlFor="Email">Email*</label>
                            <small
                              id="passwordHelpInline"
                              className="text-muted  "
                            >
                              You will never get spam
                            </small>
                            <input
                              type="email"
                              className={
                                _.has(userDetailsError, "Email")
                                  ? _.get(userDetailsError, "Email")
                                    ? "border border-danger form-control "
                                    : "border border-success form-control"
                                  : "form-control"
                              }
                              id="Email"
                              name="Email"
                              placeholder="Enter email address"
                              value={_.get(userDetailsFormData, "Email") || ""}
                              onChange={(event) => fieldChange(event)}
                              onBlur={(event) => handleBlur(event)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-4 pb-1">
                            <label htmlFor="Primary_Phone">Phone*</label>
                            <small
                              id="passwordHelpInline"
                              className="text-muted "
                            >
                              Valid number ensures an accurate quote
                            </small>
                            <NumberFormat
                              className={
                                _.has(userDetailsError, "Primary_Phone")
                                  ? _.get(userDetailsError, "Primary_Phone")
                                    ? "border border-danger form-control "
                                    : "border border-success form-control"
                                  : "form-control"
                              }
                              id="Primary_Phone"
                              format="(###)-###-####"
                              name="Primary_Phone"
                              value={
                                _.get(userDetailsFormData, "Primary_Phone") ||
                                ""
                              }
                              placeholder="(___)-___-____"
                              onChange={(event) => fieldChange(event)}
                              onBlur={(event) => handleBlur(event)}
                            />
                          </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="col-md-6">
                          <div className="form-group mb-4 pb-1">
                            <label htmlFor="Plan_Type">
                              Desired Plan Type*
                            </label>
                            <div className="select-outer">
                              <select
                                className="border border-success form-control"
                                id="Plan_Type"
                                name="Plan_Type"
                                onChange={(event) => fieldChange(event)}
                                onBlur={(event) => handleBlur(event)}
                              >
                                <option value="Medicare Supplement">
                                  Medicare Supplement
                                </option>
                                <option value="Not Sure">
                                  {" "}
                                  Medicare Advantage
                                </option>
                                <option value="Other Medicare Plans">Not Sure or Other</option>
                              </select>
                              <img
                                className="select-down-arrow"
                                src={DownarrowIcon}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-4 pb-1">
                            <label htmlFor="Address">Address*</label>
                            <input
                              type="text"
                              className={
                                _.has(userDetailsError, "Address")
                                  ? _.get(userDetailsError, "Address")
                                    ? "border border-danger form-control "
                                    : "border border-success form-control"
                                  : "form-control"
                              }
                              id="Address"
                              name="Address"
                              value={
                                _.get(userDetailsFormData, "Address") || ""
                              }
                              onChange={(event) => fieldChange(event)}
                              onBlur={(event) => handleBlur(event)}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="Zip">Zip Code*</label>
                            <div className="green-tick-input">
                              <NumberFormat
                                className={
                                  _.has(userDetailsError, "Zip")
                                    ? _.get(userDetailsError, "Zip")
                                      ? "border border-danger form-control "
                                      : "border border-success form-control"
                                    : "form-control"
                                }
                                id="Zip"
                                name="Zip"
                                value={_.get(userDetailsFormData, "Zip") || ""}
                                onChange={(event) => fieldChange(event)}
                                onBlur={(event) => handleBlur(event)}
                                isAllowed={(value) => {
                                  const { formattedValue, floatValue } = value;
                                  return (
                                    formattedValue === "" ||
                                    floatValue <= 100000
                                  );
                                }}
                              />
                              {_.get(userDetailsFormData, "Zip") &&
                              _.has(userDetailsError, "Zip") ? (
                                !_.get(userDetailsError, "Zip") ? (
                                  <img
                                    className="green-tick"
                                    src={GreenRightTick}
                                    alt=""
                                  />
                                ) : null
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="City">City*</label>
                            <div className="blue-tick-input">
                              <input
                                type="text"
                                className={
                                  _.has(userDetailsError, "City")
                                    ? _.get(userDetailsError, "City")
                                      ? "border border-danger form-control "
                                      : "border border-success form-control"
                                    : "form-control"
                                }
                                id="City"
                                name="City"
                                value={_.get(userDetailsFormData, "City") || ""}
                                onChange={(event) => fieldChange(event)}
                                onBlur={(event) => handleBlur(event)}
                                placeholder={cityPlaceholder.City}
                              />
                              {_.get(userDetailsFormData, "City") ? (
                                <img
                                  className="green-tick"
                                  src={GreenRightTick}
                                  alt=""
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="State">State*</label>
                            <div className="green-tick-input">
                              <input
                                type="text"
                                className={
                                  _.has(userDetailsError, "State")
                                    ? _.get(userDetailsError, "State")
                                      ? "border border-danger form-control "
                                      : "border border-success form-control"
                                    : "form-control"
                                }
                                id="State"
                                name="State"
                                value={
                                  _.get(userDetailsFormData, "State") || ""
                                }
                                onChange={(event) => fieldChange(event)}
                                onBlur={(event) => handleBlur(event)}
                              />
                              {_.get(userDetailsFormData, "State") ? (
                                <img
                                  className="green-tick"
                                  src={GreenRightTick}
                                  alt=""
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <p className="paragraph mt-4 text-center">
                        {" "}
                        By clicking the 'Get My FREE Quotes' Button, I agree to
                        the <a href="/terms-and-conditions">Terms of Use</a> (incl.
                        the arbitration and E-SIGN provisions) and <a href="/privacy-policy">Privacy Policy</a> I provide my electronic
                        signature expressly consenting to the transfer of my
                        information and to receive offers, emails, calls, mail,
                        and texts, incl. through automated telephone dialing
                        systems, artificial voice, and/or pre-recorded messages, from
                        offersclub.com or <a href="/medicare-partners">one or more of
                        its marketing partners</a> and their agents, even if I am
                        listed on any “Do Not Call” and/or Do Not Email lists,
                        regarding Medicare Supplement, Medicare Advantage,
                        Part D, or other offers. The list of companies participating
                        are subject to change. My wireless carrier may impose charges
                        for calls or texts. I understand that my consent is not a condition
                        of purchase or receipt of services, and that I may revoke it at any
                        time. If I do not wish to consent to the above, but wish to obtain
                        information on Medicare supplemental coverage,
                        I can call 855-848-0196 to request information. I further agree to
                        the Terms of Use (incl. the
                        arbitration and E-SIGN provisions) and Privacy Policy.
                      </p>
                      <div className="text-center pt-1">
                        <button
                          type="submit"
                          className="btn btn-pink"
                          disabled={isLoading}
                        >
                          {" "}
                          {isLoading ? (
                            <CircleLoader size={20} color="#ffffff" />
                          ) : (
                            <React.Fragment>
                              Get my FREE Quotes
                              <img
                                className="arrow-right"
                                src={RightArrow}
                                alt="arrow"
                              />
                            </React.Fragment>
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-info-panel">
          <p className="paragraph-text mb-5">
            {" "}
            OffersClub.Com is not an insurance or operating company
            but connects individuals with insurance providers. Products and
            services are provided exclusively by the providers. Descriptions are
            for informational purposes only and subject to change. Insurance
            plans may not be available in all states. For a complete
            description, please call 855-848-0196 to determine eligibility and
            to request a copy of the applicable policy. OffersClub.Com
            is not affiliated with any insurance plan nor does it represent or
            endorse any plan. OffersClub.Com is not affiliated with or
            endorsed by the United States government or the federal Medicare
            program. By using this site, you acknowledge that you have read and
            agree to the
            <a href="/terms-and-conditions"> Terms of Service </a> and{" "}
            <a href="/privacy-policy"> Privacy Policy </a>. We are committed to
            protect your privacy. If you do not want to share your information
            please click on Do Not Sell My Personal Information for more
            details. Copyright © 2020-2021 OffersClub.Com. All rights
            reserved.
          </p>
        </div>
        <div className="question">
          <h3 className="heading">
            Question?<span className="expert"> Speak with an Expert</span>
            <span> 855-848-0196</span>
          </h3>
        </div>
      </StartMyQuote>
    </React.Fragment>
  );
}

export default withRouter(LandingPage);

const StartMyQuote = styled.div`
  .container {
    max-width: 1145px;
  }
  /* Header CSS START */

  .nav-up {
    top: -60px;
  }

  header.main-header {
    padding: 18px 0 18px 0;
  }

  header.main-header.nav-down {
    background: rgba(14, 17, 26, 0.7);
  }

  ul.header-icon-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  ul.header-icon-list li {
    display: inline-block;
    vertical-align: top;
  }
  ul.header-icon-list li:first-child {
    padding-right: 25px;
  }

  ul.header-icon-list li label {
    cursor: pointer;
  }

  ul.header-icon-list li label .hamburger:before {
    top: -7px;
    right: 0;
    content: "";
    width: 25px;
    height: 3px;
    border-radius: 3px;
    background: #fcfcfc;
    position: absolute;
    transition: 0.5s;
  }

  ul.header-icon-list li label .hamburger:after {
    top: 7px;
    right: 0;
    content: "";
    width: 25px;
    height: 3px;
    border-radius: 3px;
    background: #fcfcfc;
    position: absolute;
    transition: 0.5s;
  }

  header #menu-toggle {
    display: none;
  }

  ul.header-icon-list li label span {
    display: block;
    position: relative;
    margin: 0 0 3px 0;
    width: 20px;
    height: 2px;
    background: #fcfcfc;
    transition: 0.5s;
    z-index: 99999;
    transform-origin: 11px 0px;
    cursor: pointer;
  }

  ul.header-icon-list li label span:first-child {
    transform-origin: 0% 0%;
  }

  ul.header-icon-list li label span:nth-last-child(2) {
    transform-origin: 60% 100%;
  }

  header #menu-toggle:checked ~ .main-navigation {
    display: block;
  }

  header #menu-toggle:checked + label span {
    opacity: 1;
    transform: rotate(45deg) translate(-3px, -2px);
  }

  header #menu-toggle:checked + label span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  header #menu-toggle:checked + label span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }

  header .main-navigation {
    display: none;
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;
    width: 50%;
    height: 100%;
    border-radius: 0px;
    padding: 40px 25px 20px;
    text-align: left;
    box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.2);
    fontFamily: "Helvetica-Neue-Reg";
    color: #ffffff;
  }

  header .main-navigation ul {
    list-style-type: none;
    margin: 0;
    padding: 30px 0 0 0;
  }

  header .main-navigation ul.nav-links li {
    padding: 2px 0px;
    display: block;
  }
  header .main-navigation ul.nav-links li a {
    transition: 0.3s;
    color: #ffffff;
    font-size: 32px;
  }

  header .main-navigation ul.nav-links li a:hover {
    padding-left: 5px;
    color: #64a3ff;
  }

  header .bottom-navigation {
    position: absolute;
    bottom: 10px;
  }

  header .main-navigation ul.social-icons li {
    display: inline-block;
    padding: 0;
    margin-right: 8px;
  }

  header .main-navigation ul.rights li {
    display: inline-block;
    color: #4d5160;
    font-size: 12px;
  }

  header .main-navigation ul.rights li a {
    color: #4d5160;
  }

  header .main-navigation ul.rights li a:hover {
    color: #ffffff;
  }

  header .main-navigation ul.rights li {
    padding: 0 0 0 10px;
  }

  header .main-navigation ul.rights li:first-child {
    padding-left: 0;
    padding-right: 0;
  }
  /* Header CSS END */

  /* Footer START */
  footer.footer {
    padding: 40px 0 50px;
    background-color: #151723;
  }

  footer .container {
    max-width: 1145px;
  }

  footer.footer .logo {
    margin-bottom: 34px;
    display: block;
  }

  header.main-header img.header-logo {
    width: 130px;
  }

  footer.footer p {
    color: #646a7a;
    padding-bottom: 10px;
    max-width: 280px;
    font-size: 13px;
  }

  footer.footer .logo img.footer-logo {
    width: 130px;
  }

  footer.footer .form-group {
    position: relative;
    max-width: 290px;
  }

  footer.footer .form-group .form-control {
    background-color: #2a2e3d;
    border: 0;
    border-radius: 0;
    padding: 10px 16px;
    color: #686e81;
  }

  footer.footer .form-group .form-control:focus {
    box-shadow: none;
    background-color: #3b4051;
  }

  footer.footer .form-group .send-btn {
    position: absolute;
    top: 2px;
    right: 0px;
    font-size: 12px;
    padding: 5px 8px;
    background: transparent;
    border: 0;
  }
  footer.footer .form-group .send-btn:focus {
    outline: 0;
  }
  footer.footer ul.social-media {
    list-style: none;
    padding: 0;
    margin: 20px 0;
  }

  footer.footer ul.social-media li {
    color: #fff;
    padding: 7px 0;
    vertical-align: top;
  }

  footer.footer ul.social-media li span {
    font-size: 14px;
    color: #9a9b9d;
    vertical-align: middle;
    margin-left: 7px;
  }

  footer.footer ul.social-media li i {
    margin-right: 10px;
    color: #c2c4c6;
    font-size: 18px;
  }

  footer.footer ul.footer-link {
    padding: 0 20px;
    margin: 20px 0;
    float: left;
    list-style-type: none;
    text-align: left;
  }

  footer.footer ul.footer-link li {
    padding: 6px 0;
  }
  footer.footer ul.footer-link li a {
    color: #e7e7e8;
  }

  footer.footer ul.footer-link li a:hover {
    color: #62a4fb;
  }

  footer.bottom-footer {
    background-color: #2a2e3d;
    padding: 20px 0;
    font-size: 14px;
    color: #565c6c;
  }

  footer.bottom-footer ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  footer.bottom-footer ul li {
    display: inline-block;
    padding: 0 12px;
  }

  footer.bottom-footer ul li a {
    color: #565c6c;
  }

  footer.bottom-footer ul li a:hover {
    color: #62a4fb;
  }

  /* Footer END */

  .form-container {
    max-width: 814px;
    width: 100%;
    margin: 0 auto;
  }

  .from-title {
    fontFamily: "Gilroy-Bold";
    font-size: 24px;
    color: #ffffff;
    text-align: center;
    letter-spacing: 1px;
  }

  .from-title .expert {
    fontFamily: "Gilroy-Thin";
  }

  .from-title .call-box {
    color: #3d8eff;
    display: inline-block;
    vertical-align: top;
  }

  .from-title .call-box img {
    margin-top: -5px;
  }

  .from-title span.day-time {
    font-size: 12px;
    color: #66687c;
    display: block;
    fontFamily: "Helvetica-Neue-Reg";
    font-weight: normal;
    padding-left: 27px;
    margin-top: -4px;
  }

  .form-outer {
    width: 100%;
    background-color: #2b2f3d;
    padding: 44px 52px;
    border-radius: 10px;
    color: #ffffff;
  }

  .form-panel {
    width: 100%;
    padding: 0 48px;
  }

  .head-tittle {
    color: #ffffff;
    text-align: center;
    font-size: 36px;
    margin-bottom: 40px;
    fontFamily: "Gilroy-Bold";
  }

  .progress-title {
    font-style: italic;
  }

  .progress-bar {
    background-color: #3d8eff;
  }

  .progress {
    border-radius: 9px;
    height: 12px;
  }

  .compare-plan-form .select-outer {
    position: relative;
  }

  .compare-plan-form .select-outer .select-down-arrow {
    position: absolute;
    right: 10px;
    top: 20px;
  }

  .compare-plan-form .green-tick-input {
    position: relative;
  }

  .compare-plan-form .green-tick-input .green-tick {
    position: absolute;
    right: 10px;
    top: 20px;
  }

  .compare-plan-form .green-tick-input.active input {
    border-color: #3fe1b0 !important;
  }

  .compare-plan-form .blue-tick-input {
    position: relative;
  }

  .compare-plan-form .blue-tick-input .green-tick {
    position: absolute;
    right: 10px;
    top: 20px;
  }

  .compare-plan-form .blue-tick-input.active input {
    border-color: #3d8eff !important;
  }

  .compare-plan-form .form-group label {
    color: #fff;
    font-size: 16px;
    fontFamily: "Gilroy-Bold";
    letter-spacing: 0.5px;
    margin-bottom: 4px;
  }

  .compare-plan-form .col-md-6 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .compare-plan-form .form-control {
    border-color: #ffffff;
    fontFamily: "Gilroy-Thin";
    font-weight: bold;
    font-size: 18px;
    color: #66687c;
    border-radius: 5px;
    padding: 9px 15px 10px 15px;
    height: auto;
  }

  .compare-plan-form .form-control:focus {
    box-shadow: none;
  }

  .text-muted {
    font-size: 10px;
    float: right;
    color: #a4a5b6;
    padding-top: 7px;
  }

  select {
    -webkit-appearance: none;
  }

  .compare-plan-form .radio-input label {
    display: inline-block;
    width: 143px;
    height: 48px;
    padding: 9px 10px 10px 15px;
    font-size: 18px;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    fontFamily: "Gilroy-Thin";
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
  }

  .compare-plan-form .radio-input label:hover {
    border-color: #3d8eff;
  }

  .compare-plan-form input[type="radio"]:checked ~ label {
    background: #3d8eff;
    color: #fff;
    fontFamily: "Gilroy-Thin";
    font-weight: bold;
    border-color: #3d8eff;
  }

  .compare-plan-form input[type="radio"] {
    opacity: 0;
  }

  p.paragraph {
    color: #a4a5b6;
    font-size: 13px !important;
  }

  .btn-pink {
    color: #ffffff;
    background: #ff0c62;
    max-width: 294px;
    width: 100%;
    height: 48px;
    margin: 0 auto;
    padding: 8px;
    font-size: 18px;
    border-radius: 0;
    border: none;
    fontFamily: "Gilroy-Bold";
  }

  .btn-pink .arrow-right {
    width: 15px;
    height: 12px;
  }

  .btn-pink:hover {
    color: #ffffff;
    background: #df0a56;
    box-shadow: none;
  }

  p.paragraph-text {
    color: #66687c;
    font-size: 14px;
    line-height: 18px;
  }

  .paragraph-text a {
    color: #62a4fb;
    font-size: 13px;
  }

  .bottom-info-panel {
    max-width: 1060px;
    width: 100%;
    padding: 60px 15px 37px 15px;
    margin: 0 auto;
  }

  .question {
    background: #262938;
    padding: 20px;
    text-align: center;
    width: 100%;
  }

  .question .heading {
    color: #ffffff;
    padding: 10px 0;
    fontFamily: "Gilroy-Bold";
    font-size: 36px;
  }

  .question .heading .expert {
    font-weight: bold;
    color: #ffffff;
    fontFamily: "Gilroy-Thin";
  }

  .question .heading span {
    color: #3d8eff;
  }

  .bottom-info-panel p {
    color: #6b7082;
    font-size: 14px;
    line-height: 18px;
  }

  .bottom-info-panel a {
    color: #3d8eff;
  }

  ul.logo-icon {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  ul.logo-icon li {
    display: inline-block;
    width: 24%;
    vertical-align: middle;
    text-align: center;
  }

  ul.logo-icon li:first-child {
    text-align: left;
  }

  ul.logo-icon li:last-child {
    text-align: right;
  }

  ul.logo-icon li img {
    opacity: 0.7;
  }

  #carousel-banner .slide-content .banner-content.second-slide {
    max-width: 616px;
  }

  .banner-content.second-slide h2 {
    color: #fff;
    margin-bottom: 0;
  }

  .banner-content.second-slide p {
    color: #ffffff;
    margin-top: 8px;
  }

  @media only screen and (max-width: 1500px) {
    .banner-panel-outer {
      height: 100%;
    }
  }

  @media only screen and (max-width: 1400px) {
    .banner-panel-outer .container {
      max-widt: 1145px;
    }

    .banner-text ul.brands-list li:not(:first-child) {
      padding-left: 5px;
    }

    .banner-text.right-side {
      padding-top: calc(55%);
    }
  }

  @media only screen and (max-width: 1249px) {
    .container {
      max-width: 1145px;
    }

    .banner-panel-outer .container {
      max-width: 1145px;
    }

    .banner-text ul.brands-list li:not(:first-child) {
      padding-left: 10px;
    }

    .banner-text ul.brands-list li .white-box {
      width: 122px;
      height: 100px;
    }

    .banner-text ul.brands-list li .white-box img {
      padding: 37px 17px;
    }

    .banner-text ul.brands-list li .white-box img.small-img {
      padding: 12px 20px;
    }

    .banner-text ul.brands-list li:not(:first-child) {
      padding-left: 5px;
    }
    #carousel-banner .slide-content .banner-content {
      top: 16%;
    }
  }

  @media only screen and (max-width: 992px) {
    #carousel-banner .slide-content .banner-content {
      max-width: 395px;
    }

    .banner-content h2 {
      font-size: 29px;
      margin-top: 10px;
    }

    .banner-content p {
      font-size: 16px;
      font-weight: bold;
    }

    .banner-content .pink-btn {
      padding: 4px 10px;
      font-size: 11px;
    }

    .banner-text {
      padding: 90px 0 45px;
    }

    .banner-text.right-side {
      padding: 0 0 100px;
    }

    .medicare-supplement-plan .plans-table {
      float: none;
      margin: 0 auto 30px !important;
      width: 60%;
    }

    .medicare-supplement-plan .plans-table.plan-popularity {
      width: 51%;
    }

    footer.footer ul.footer-link {
      padding: 0 20px;
    }

    .compare-medigap-plans .circle:after {
      background-color: #ff0c62;
      content: "OR";
      color: #fff;
      position: absolute;
      padding: 7px;
      top: -16px;
      left: 47%;
      display: block;
      font-size: 14px;
      border-radius: 50%;
    }

    .compare-medigap-plans .circle {
      padding-top: 30px;
      margin: 30px auto 0;
      width: 90%;
      border-left: 0;
      border-top: 1.2px solid #4d5465;
    }

    .medicare-supplement-plan .plans-table.plan-popularity {
      padding-top: 60px;
    }
  }

  @media only screen and (max-width: 768px) {
    .to-shop-over h2 span {
      display: block;
    }

    #carousel-banner .slider-img {
      display: none;
    }

    #carousel-banner .slider-img-mobile {
      display: block;
      position: relative;
      left: 50%;
      top: 50%;
      width: 98%;
      /* height: 350px; */
      z-index: -1;
      transform: translate(-50%, 0%);
      box-shadow: 0 16px 28px rgba(14, 16, 24, 0.5);
    }

    .medicare-supplement-plan .plans-table {
      width: 100%;
    }

    .medicare-supplement-plan .plans-table.plan-popularity {
      width: 100%;
    }

    .banner-text ul.brands-list li .white-box {
      width: 100px;
      height: 80px;
    }

    .banner-text ul.brands-list li .white-box img {
      padding: 30px 17px;
    }

    .banner-text ul.brands-list li .white-box img.small-img {
      padding: 8px 15px;
    }

    footer.footer ul.footer-link {
      padding: 0 30px 0 0;
    }

    #accordion .card {
      font-size: 14px;
    }

    #accordion .card .card-header .btn-link {
      font-size: 18px;
    }

    .medicare-supplement-plan {
      padding: 60px 0;
    }

    .faq-panel {
      padding: 50px 0;
    }

    ul.logo-icon li img {
      max-width: 100%;
      width: 80%;
    }
  }

  @media only screen and (max-width: 630px) {
    header .main-navigation {
      width: 80%;
    }

    header .main-navigation ul.nav-links li a {
      font-size: 21px;
    }

    .banner-text ul.brands-list li:not(:first-child) {
      padding-left: 3px;
    }

    #accordion .card .card-header .btn-link i {
      font-size: 18px;
    }

    .banner-content p {
      margin: 0;
    }

    .banner-content h2 {
      font-size: 26px;
      max-width: 300px;
    }

    .banner-content p {
      font-size: 16px;
    }

    .form-panel {
      padding: 0;
    }
  }

  @media only screen and (max-width: 585px) {
    #accordion .card .card-header {
      padding: 14px 26px;
    }

    #accordion .card .card-header .btn-link img {
      width: 27px;
    }

    .head-tittle {
      font-size: 26px;
    }
  }

  @media only screen and (max-width: 541px) {
    #carousel-banner .slide-content .banner-content {
      max-width: 320px;
    }

    .banner-text h1 {
      font-size: 35px;
    }

    .banner-text h3 {
      font-size: 20px;
    }

    .to-shop-over h2 {
      font-size: 20px;
    }

    .to-shop-over h2 span {
      font-size: 26px;
    }

    .common-subtitle {
      font-size: 16px;
    }

    .to-shop-over h2 span {
      font-size: 26px;
    }

    .map-content h2 {
      font-size: 26px;
    }

    .map-content .map-subtitle {
      font-size: 16px;
    }

    .faq-panel h2 {
      font-size: 30px;
    }

    .compare-medigap-plans h2 {
      font-size: 30px;
    }
    .banner-text ul.brands-list li {
      margin-top: 20px;
    }

    .start-my-qoute button.btn.btn-primary {
      width: 150px;
    }

    .start-my-qoute button.btn.btn-primary {
      font-size: 16px;
      padding: 12px 16px;
    }

    .form-outer {
      padding-left: 30px;
      padding-right: 30px;
    }

    .question .heading .expert {
      display: block;
    }
    .bottom-info-panel {
      padding-bottom: 0;
    }
  }

  @media only screen and (max-width: 400px) {
    #carousel-banner .slide-content .banner-content {
      max-width: 270px;
    }

    #carousel-banner .slide-content .banner-content {
      top: 15%;
    }

    .compare-plan-form .radio-input label {
      width: 110px;
    }
  }
`;
