export const DNSMPIConstant = [
	{
		type: "input",
		subType: "text",
		id: "first_name",
		placeholder: "First Name",
		key: "first_name",
		formClass: "form-control",
	},
	{
		type: "input",
		subType: "text",
		id: "last_name",
		placeholder: "Last Name",
		key: "last_name",
		formClass: "form-control",
	},
	{
		type: "input",
		subType: "text",
		id: "email",
		placeholder: "Email",
		key: "email",
		formClass: "form-control",
	},
	{
		type: "input",
		subType: "text",
		id: "california_address",
		placeholder: "California Address",
		key: "california_address",
		formClass: "form-control",
	},
	{
		type: "input",
		subType: "text",
		id: "phone_number",
		placeholder: "Phone Number",
		key: "phone_number",
		formClass: "form-control",
	},
];


export const UnsubscribeConstant = [
	{
		type: "input",
		subType: "text",
		id: "email",
		placeholder: "Email",
		key: "email",
		formClass: "form-control",
	},
	{
		type: "checkbox",
		subType: "checkbox",
		id: "checkbox-daily",
		key: "checkbox-daily",
		title: 'Daily Offers',
		description: 'Daily Deals. One email a day with a summary of our best deals.',
		formClass: "custom-control-input"
	},
	{
		type: "checkbox",
		subType: "checkbox",
		id: "checkbox-weekly",
		key: "checkbox-weekly",
		title: 'Weekly Offers',
		description: 'Weekly Digest. One email a week with a summary of the week\'s best deals.',
		formClass: "custom-control-input"
	},
	{
		type: "checkbox",
		subType: "checkbox",
		id: "checkbox-monthly",
		key: "checkbox-monthly",
		title: 'Monthly Offers',
		description: 'Monthly Digest. One email a month with a summary of the month\'s best deals.',
		formClass: "custom-control-input"
	},
	{
		type: "checkbox",
		subType: "checkbox",
		id: "checkbox-unsubscribe",
		key: "checkbox-unsubscribe",
		title: 'Unsubscribe',
		description: 'Unsubscribe me from all email communication.',
		formClass: "custom-control-input"
	},
];
