import React, {useEffect, useState} from "react";
import {ToastsStore} from "react-toasts";
import {PaginationComponent} from "../../shared/PaginationComponent";
import apiClient from "../../../store/actions/ApiInterceptor";
import CircleLoader from "../../shared/CircleLoader";
import OffersDetailsModal from "./OffersDetailsModal";
import ImageSlider from "../../shared/imageslider/ImageSlider";
import {BodyContent, SmallImageSlider} from "../../../theme/css/Global";
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../../store/actions";
import {Helmet} from "react-helmet";
import useShuffledData from "../../../hooks/useShuffleData/useShuffledData";

const _ = require("lodash");
const moment = require("moment");

// I suspect this component is useless as /offers route doens't work. You can comment it out and the app doesn't crash and works the same.
function OfferList(props) {
	let params = new URLSearchParams(new URL(window.location).search);
	const {location} = props;
	const [isListLoading, setIsListLoading] = useState(false);
	const [
		isBottomCircleSliderLoading,
		setIsBottomCircleSliderLoading,
	] = useState(false);
	const [activePage, setActivePage] = useState(1);
	const [offerListData, setOffersListData] = useState([]);
	const [bottomCircleSliderData, setBottomCircleSliderData] = useState(null);
	const [selectedOfferDetailsData, setSelectedOfferDetailsData] = useState({});
	const [offerIndex, setOfferIndex] = useState(null);
	const dispatch = useDispatch();
	useEffect(() => {

		if (location.state === undefined && params.toString()) location.state = {
			filters: {
				[params.get('type')]: params.get('name'),
				page: params.get('page') ?? 1
			}, offerType: params.get('name') || "" + " Offers"
		};
		if (params.get("page")) setActivePage(parseInt(params.get("page")));
		if (location.state === undefined) {
			// return redirectToPage("/dashboard");
			return redirectToPage("/");
		}
		const ac = new AbortController();
		getOfferList();
		if (params.get('brand_name') || ("brand_name" in location?.state?.filters)) {
			getBottomCircleSliderData("brand/");
		} else {
			getBottomCircleSliderData("category/");
		}
		return () => ac.abort(); // Abort both fetches on unmount
	}, [activePage, props.location]);

	const redirectToPage = (redirectLink) => {
		props.history.push({
			pathname: redirectLink,
		});
	};

	////////// favourite funtionality start //////////

	const {
		UserData,
		savedOfferDetails,
		isSavedOfferDeleted,
		showToaster,
	} = useSelector((state) => ({
		UserData: state.userState.UserDetails,
		savedOfferDetails: state.savedOfferState.savedOfferDetails,
		isSavedOfferDeleted: state.savedOfferState.isSavedOfferDeleted,
		showToaster: state.savedOfferState.showToaster,
	}));

	if (_.get(offerListData, "results", []).length > 0) {
		//after save offer
		if (_.get(savedOfferDetails, "id")) {
			let sliderData = offerListData.results;
			if (
				_.get(offerListData.results[offerIndex], "id") ===
				_.get(savedOfferDetails, "offer_detail.id")
			) {
				sliderData[offerIndex].is_saved.flag = true;
				sliderData[offerIndex].is_saved.saved_offer_id = savedOfferDetails.id;
				dispatch(actions.initialization());
				setOffersListData({
					...offerListData,
					results: sliderData,
				});
			}
		}

		//after delete offer
		if (_.get(isSavedOfferDeleted, "flag")) {
			let sliderData = offerListData.results;
			if (
				_.get(sliderData[offerIndex], "id") ===
				_.get(isSavedOfferDeleted, "offerId")
			) {
				sliderData[offerIndex].is_saved.saved_offer_id = null;
				sliderData[offerIndex].is_saved.flag = false;
				dispatch(actions.initialization());
				setOffersListData({
					...offerListData,
					results: sliderData,
				});
			}
		}
	}

	const toggleFavourite = (offerData, index) => {
		if (localStorage.getItem("token")) {
			setOfferIndex(index);
			if (offerData.is_saved.flag) {
				dispatch(
					actions.unSaveOffer(offerData.is_saved.saved_offer_id, offerData.id)
				);
			} else if (_.get(UserData, 'id')) {
				dispatch(actions.saveOffer(offerData.id, UserData.id));
			} else {
				ToastsStore.error("Something went wrong. Please refresh the page and try again.");
			}
		} else {
			window.$("#recommendedForSignUp").modal("show");
		}
	};

	const ChangeToaster = () => {
		dispatch(actions.closeToaster(null));
	};

	if (showToaster !== null) {
		setTimeout(ChangeToaster, 3000);
	}

	////////// favourite funtionality end //////////

	const getBottomCircleSliderData = (url) => {
		setIsBottomCircleSliderLoading(true);
		apiClient
			.get(url)
			.then((res) => {
				setIsBottomCircleSliderLoading(false);
				setBottomCircleSliderData(res.data);
			})
			.catch((error) => {
				setIsBottomCircleSliderLoading(false);
				ToastsStore.error("Unable to fetch data.");
			});
	};

	const getOfferList = () => {
		setIsListLoading(true);
		location["state"]["filters"]["page"] = activePage;
		apiClient
			.get("offer/", {
				params: location.state.filters,
			})
			.then((res) => {
				setIsListLoading(false);
				setOffersListData(res.data);
			})
			.catch((error) => {
				setIsListLoading(false);
				ToastsStore.error("Unable to fetch offers.");
			});
	};

	const handleClick = (selectedOffer) => {
		window.gtag('event', 'select_promotion', {
			promotions: [{
				id: selectedOffer.id ,
				name: selectedOffer.title,
				creative_slot: window.location.pathname
			}]
		});
		setSelectedOfferDetailsData(selectedOffer);

		window.$("#offerDetailsModal").modal("show");

//		const sub3 = encodeURIComponent(window.location.hostname.replaceAll('.', '__'));
//		window.open(selectedOffer.link + "?sub3=" + sub3);

		window.open(selectedOffer.link);

	};

	const shuffledOffers = useShuffledData(offerListData.results);

	const renderOffers = () => {
		if (_.get(offerListData, "results", []).length > 0) {
			return shuffledOffers.map((offer, index) => (
				<SmallImageSlider key={offer.id + "_" + (Math.floor(Math.random() * 9999) + 999)}
								  className="offer-list-box slider-sm">
					{showToaster && offerIndex === index ? (
						<div className="saved-label">{showToaster} </div>
					) : null}
					<img
						src={
							_.get(offer, "image")
								? offer?.image
								: null
						}
						alt={offer?.title}
						onClick={() => handleClick(offer)}
					/>
					<div
						className="heart-circle"
						onClick={() => toggleFavourite(offer, index)}
					>
						<i className={offer.is_saved.flag ? "fa fa-heart" : "fa fa-heart-o"} aria-hidden="true" />
					</div>
					<br/>
					<div className="slide-info-text" onClick={() => handleClick(offer)}>
						<div className="from-text">
							<b>{offer.title}</b>
						</div>
						<h5 className="heading">{offer.description}</h5>
						<div className="days-ago">
							Added {moment(new Date()).diff(moment(offer?.created_at), "days")}{" "}
							days ago
						</div>
					</div>
				</SmallImageSlider>
			));
		} else {
			return (
				<span>
          <h5>No Offers Yet! </h5>
        </span>
			);
		}
	};

	const pageChange = ({pageNumber}) => {
		setActivePage(pageNumber);
		let searchParams = new URLSearchParams(window.location.search);
		searchParams.set('page', pageNumber);
		props.history.push(
			{pathname: window.location.pathname, search: searchParams.toString()},
			{ offerType: location.state.offerType, filters: {...location.state.filters, page: pageNumber}}
		);
	};

	return (
		<React.Fragment>
			<Helmet>
				<title>{_.get(location.state, "offerType")}</title>
				<meta name="description" content="" />

			</Helmet>
			<BodyContent>
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="lighter d-sm-none d-block">
                <span className="pr-2" onClick={() => redirectToPage("/")}>
                  Home
                </span>
								/
								<span className="active pl-2">
                  {_.get(location.state, "offerType")}
                </span>
							</div>
							<h1 className="list-heading">
								{_.get(location.state, "offerType")}
								<br className="d-sm-none d-block"/>
								<span className="ml-sm-4">
                  {offerListData?.count} Offers Available
                </span>
							</h1>
							<div className="lighter  d-sm-block d-none">
                <span className="pr-2" onClick={() => redirectToPage("/")}>
                  Home
                </span>
								/
								<span className="active pl-2">
                  {_.get(location.state, "offerType")}
                </span>
							</div>
						</div>
						<div className="col-12 d-sm-none d-block pt-2">
							<div className="description-para">
								AAG believes in better.
								<br/> At American Advisors Group, we strive to deliver the most
								considerate solution and personalized service to our
								customers-all in an effort to help them and all older adults
								have the retirement they deserve.
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12 flex-wrap d-flex">
							{!isListLoading ? renderOffers() : <CircleLoader/>}
							<OffersDetailsModal offerDetails={selectedOfferDetailsData}/>
						</div>
					</div>
					<div className="row">
						{_.get(offerListData, "count") && offerListData.count > 25 ? (
							<PaginationComponent
								activePage={activePage}
								totalItemsCount={
									_.get(offerListData, "count") ? offerListData.count : 0
								}
								onChange={pageChange}
							/>
						) : (
							""
						)}
					</div>
					<div className="row">
						<div className="col-12 mt-sm-5 mt-5">
							{location?.state?.filters ? (
								<h4>
									Other{" "}
									{"brand_name" in location?.state?.filters || params.get('brand_name')
										? "Brands"
										: "Categories"}
								</h4>
							) : (
								""
							)}
						</div>
					</div>
				</div>
				<div className="shadow-padding">
					{!isBottomCircleSliderLoading && location?.state?.filters ? (
						<ImageSlider
							brand={true}
							sliderData={bottomCircleSliderData?.results}
							sliderFor="circle"
							sliderType={
								"brand_name" in location?.state?.filters
									? "brand_name"
									: "category_name"
							}
							{...props}
						/>
					) : (
						<CircleLoader/>
					)}
				</div>
			</BodyContent>
		</React.Fragment>
	);
}

export default OfferList;
