import React from "react";
import {withRouter} from "react-router-dom";

function TermsAndConditions(props) {
	const redirectToPage = (redirectLink) => {
		window.scrollTo(0, 0);
		props.history.push({
			pathname: redirectLink,
		});
	};
	return (
		<React.Fragment>
			<div className="container pt-5 pb-5">
				<div className="row">
					<div className="col-12">
						<h2 className="pb-5">Terms and Conditions</h2>
						<p>
							Last updated: Jan 11, 2021
						</p>
						<p>
							By accessing, browsing and/or using www.OffersClub.com (the “Site”), you acknowledge that
							you have read, understand, and agree to be bound to these terms (“Terms”) and to comply with
							all applicable laws and regulations. THIS AGREEMENT CONTAINS WARRANTY DISCLAIMERS AND OTHER
							PROVISIONS THAT LIMIT OUR LIABILITY TO YOU. PLEASE READ THIS AGREEMENT IN ITS ENTIRETY. If
							you do not agree to these Terms, please do not use the Site. Your use of the Site
							constitutes your acceptance of these Terms.
						</p>
						<p>
							By using this Site, you represent that you are at least 18 years old and that you are
							legally able to enter into this agreement.
						</p>
						<h4 className="pb-2 pt-3">Personal Information & Privacy</h4>
						<p>
							All personal information that you provide to us via this Site is subject to our <a href="javascript:void(0);"
												 onClick={() => redirectToPage("/privacy-policy")}>Privacy Policy</a>.
							The Privacy Policy is expressly incorporated
							into these Terms by this reference.
						</p>
						<h4 className="pb-2 pt-3">Your Use of the Site</h4>
						<p>
							All content included on this Site, such as text, graphics, logos, button icons, images,
							audio clips, digital downloads, data compilations, and software, is the property of the
							Site’s owner or its third party advertisers, lead purchasers, and/or content suppliers and
							is protected by international copyright laws. The compilation of all content on this Site is
							the exclusive property of the Site, and is protected by copyright laws. You may not use any
							proprietary graphic or trademark featured on the Site without express written permission.
						</p><p>
						The Site hereby grants you a limited license to use the Site subject to these Terms. No other
						use of the Site is authorized, and the design and layout of the Site are specifically excluded
						from the above limited license. Framing of the Site is strictly prohibited.
					</p><p>
						The products, technology, and/or processes described and/or used on the Site may be the subject
						of intellectual property rights reserved by the Site and third parties. Except for the limited
						license granted above, nothing contained herein shall be construed as conferring to you in any
						manner, whether by implication, estoppel or otherwise, any license, title, or ownership of or to
						any intellectual property right of the Site or any third party (including any trademarks).
					</p>

						<p>In conjunction with your use of the Site, you are prohibited from:</p>
						<ol style={{listStyleType: 'lower-alpha', 'color': '#9798aa'}}>
							<li>Using the Site in any manner that is prohibited by law or regulation, or to
								facilitate a violation of any law or regulation;
							</li>
							<li>Using, copying, reproducing, modifying, or creating derivative works of the Site, or
								any content featured therein;
							</li>
							<li>Transferring, leasing, lending, sublicensing, reselling or otherwise distributing or
								allowing any third party to access any portion of the Site intended for your use;
							</li>
							<li>Attempting to or modifying, reverse engineering, decompiling or disassembling the
								Site or converting them to any other format or medium;
							</li>
							<li>Removing, obscuring, or altering any legal notices, including any notices of
								intellectual property rights appearing on the Site;
							</li>
							<li>Making any changes to the content of the Site;</li>
							<li>Transmitting unlawful, fraudulent, offensive, obscene, pornographic, abusive,
								harassing, threatening, discriminatory or otherwise obscene material or sending
								defamatory or libelous messages through the Site;
							</li>
							<li>Accessing content or data not intended for you, or logging onto a server or account
								that you are not authorized to access;
							</li>
							<li>Attempting to probe, scan, or test the vulnerability of the Site, or any associated
								system or network, or to breach security or authentication measures without proper
								authorization, or bypass, modify, defeat, violate or circumvent security features
								that protect the Site, including but not limited to accessing data not intended for
								you or logging into a server or account which you are not authorized to access;
								attempting to probe, scan or test the vulnerability of a system or network or to
								breach security or authentication measures without proper written request and
								authorization; and/or interfering or attempting to interfere with service to any
								user, host or network, including by means of submitting a virus to the Site,
								overloading, flooding, spamming, mail bombing or crashing;
							</li>
							<li>Using, posting, transmitting or introducing any device, software or routine which
								interferes or attempts to interfere with the operation of the Site;
							</li>
							<li>Using any robot, spider, scraper, deep link or other similar automated data
								gathering or extraction tools, program, algorithm or methodology to access, extract,
								acquire, copy or monitor the Site or any portion thereof, without our express
								written consent, which may be withheld in our sole and absolute discretion;
							</li>
							<li>Framing or utilizing framing techniques to enclose any trademark, logo, or other
								proprietary information (including images, text, page layout, or form) of the Site
								without the Site&rsquo;s express written consent;
							</li>
							<li>Using any meta tags or any other &ldquo;hidden text&rdquo; utilizing the
								Site&rsquo;s name or trademarks without the Site&rsquo;s express written consent;
							</li>
							<li>Providing false or misleading information, to obtain unauthorized access to the
								Site;
							</li>
							<li>Interfering with the use of the Site, or encouraging conduct that would give rise to
								civil liability; or
							</li>
							<li>Impersonating any Site personnel or other persons or entities or using any
								electronic mail message, device, software or programming routine that would impair
								or interfere with the operation of this Site.
							</li>
						</ol>
						<p>
							Any violation of system or network security may subject you to civil and/or criminal
							liability.
							We reserve the right, with or without notice, to immediately terminate your access to the
							Site in the event of any attempted violation or violation of these Terms, or any party’s
							intellectual rights; misuse of the Site; or other inappropriate conduct, as determined by
							the Site in its sole discretion, with or without notice to you and without liability to you.
							We may also pursue any other remedies available to it under applicable law.
						</p>
						<h4 className="pb-2 pt-3">Third Party Links</h4>
						<p>
							The Site contains links to third party websites. Similarly, links to third party websites
							may be featured in our newsletters, or in emails promoting the Site. No representations or
							warranties are made with respect to any information contained in or at such third party
							websites and there shall be no liability for any damages or injury arising from the content
							of such third party websites. No endorsement is made regarding the featured brands or
							advertisers, nor regarding their products or services. You agree to hold us harmless from
							any claims which you may have against a third party that you linked to or accessed via the
							Site.
						</p>
						<h4 className="pb-2 pt-3">Disclaimer; Limitation of Liability</h4>
						<p>
							THIS SITE IS PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED,
							INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
							PARTICULAR PURPOSE, AND NON-INFRINGEMENT. THERE SHALL BE NO LIABILITY FOR ANY DIRECT,
							INDIRECT, SPECIAL, CONSEQUENTIAL, PUNITIVE, EXEMPLARY, AND/OR INCIDENTAL DAMAGES OF ANY KIND
							WHATSOEVER (INCLUDING, BUT NOT LIMITED TO, LOST PROFITS OR ATTORNEYS’ FEES) IN ANY WAY DUE
							TO, RESULTING FROM OR ARISING IN CONNECTION WITH YOUR ACCESS TO, INABILITY TO ACCESS, OR USE
							OF THE SITE, OR FROM YOUR RELIANCE ON ANY INFORMATION PROVIDED THROUGH THE SITE, EVEN IF WE
							HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS LIMITATION APPLIES TO ALL CAUSES
							OF ACTION IN THE AGGREGATE INCLUDING, BUT NOT LIMITED TO, BREACH OF CONTRACT, BREACH OF
							WARRANTY, NEGLIGENCE, STRICT LIABILITY, MISREPRESENTATION, AND ANY OTHER TORT.
						</p>
						<p>
							OUR LIABILITY TO YOU IS LIMITED. IN NO EVENT SHALL WE OR ANY OF OUR OWNERS, DIRECTORS,
							OFFICERS, EMPLOYEES, CONSULTANTS, AGENTS, REPRESENTATIVES, PARENT COMPANIES, SUBSIDIARIES,
							AFFILIATES, OR SERVICE PROVIDERS (COLELCTIVELY, THE “PROTECTED ENTITIES”) BE LIABLE FOR ANY
							DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS SITE, OR THE CONTENT, MATERIALS, AND
							FUNCTIONS RELATED THERETO (INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, SPECIAL,
							INCIDENTAL, PUNITIVE, CONSEQUENTIAL DAMAGES, LOST PROFITS, OR LOST DATA, REGARDLESS OF THE
							FORESEEABILITY OF THOSE DAMAGES). THIS LIMITATION SHALL APPLY REGARDLESS OF WHETHER THE
							DAMAGES ARISE OUT OF BREACH OF CONTRACT, TORT, OR ANY OTHER LEGAL THEORY OR FORM OF ACTION.
							CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR
							LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
							DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL
							RIGHTS. IN THE EVENT THE FOREGOING LIMITATION OF LIABILITY SET FORTH HEREIN SHALL BE FOR ANY
							REASON HELD UNENFORCEABLE OR INAPPLICABLE, YOU AGREE THAT OUR AGGREGATE LIABILITY (INCLUDING
							THE PROTECTED ENTITIES) SHALL NOT EXCEED FIVE DOLLARS ($5.00). THE ABOVE LIMITATION MAY NOT
							APPLY IN ALL JURISIDICTIONS OR TO ALL USERS. IF YOU ARE NOT IN AGREEMENT WITH THE FOREGOING,
							YOUR SOLE REMEDY IS TO TERMINATE THIS AGREEMENT AND DISCONTINUE USE OF THE SITE.
						</p>
						<h4 className="pb-2 pt-3">Indemnification</h4>
						<p>
							You hereby indemnify, defend, and hold harmless the Site, its owner, affiliates, and all
							officers, directors, owners, and agents (collectively, the “Indemnified Parties”) from and
							against any and all liability and costs, including, without limitation, reasonable
							attorneys’ fees, incurred by the Indemnified Parties in connection with any claim arising
							out of any breach by you of these Terms. You shall cooperate as fully as reasonably required
							in defense of any such claim. The Site reserves the right, at its own expense, to assume the
							exclusive defense and control of any matter subject to indemnification by you.
						</p>
						<h4 className="pb-2 pt-3">Enforcement</h4>
						<p>
							In the event that we determine, in our sole discretion, that you have violated these Terms,
							we shall have the right to immediately terminate your use of the Site, in addition to
							electing to pursue any other remedies available to it under applicable law.
						</p>
						<h4 className="pb-2 pt-3">Choice of Law</h4>
						<p>
							The law of the State of Nevada without reference to their rules regarding conflicts of law
							shall govern use of the Site, the validity and construction of these Terms and the Privacy
							Policy, and the interpretation of the rights and duties arising under such.
						</p>
						<h4 className="pb-2 pt-3">Arbitration</h4>
						<p>
							Except as otherwise provided by applicable law, any and all controversies and disputes
							arising out of or related to the Site, these Terms, the Privacy Policy, and/or their
							interpretation shall be settled by binding arbitration administered by the American
							Arbitration Association (AAA) in accordance with its Commercial Arbitration Rules, and
							judgment on the award rendered by the arbitrator(s) may be entered in any court having
							jurisdiction thereof. The place of arbitration shall be Las Vegas, Nevada. The United States
							Federal Arbitration Act shall govern the interpretation, enforcement, and proceedings
							pursuant to this arbitration clause. In no event shall the arbitrator(s) have any authority
							to award punitive or other damages not measured by the prevailing party’s actual damages,
							except as may be required by statute. Irrespective of the outcome of arbitration, each party
							shall bear its own costs and expenses, including its own attorneys’ fees, and an equal share
							of the arbitrator(s)’ fees and administrative fees of arbitration. The arbitrator(s) shall
							not determine or award any alternative allocation of costs and expenses, including any
							attorneys’ fees. Judgment upon the arbitration award may be entered in any court having
							jurisdiction thereof. You agree and submit to the personal jurisdiction and venue of any
							such court of competent jurisdiction. In the event that this arbitration clause is deemed to
							be invalid or otherwise unenforceable or illegal, the remaining provisions of these Terms
							shall remain in full force and effect, including the waiver of the right to proceed in a
							class action. THE PARTIES AGREE TO WAIVE THE RIGHT TO BRING OR PARTICIPATE IN ANY
							CONSOLIDATED OR CLASS ACTION AND AGREE THAT ALL FORMS OF CLASS ACTION OR CLASS ARBITRATION
							ARE EXPRESSLY PROHIBITED. THE PARTIES UNDERSTAND THEY WILL NOT HAVE THE RIGHT TO A TRIAL BY
							A COURT OR A JURY AND THAT THE INFORMATION THAT CAN BE OBTAINED IN DISCOVERY FROM EACH OTHER
							OR FROM THIRD PERSONS IN ARBITRATION IS GENERALLY MORE LIMITED THAN IN A LAWSUIT. IN
							ADDITION, OTHER RIGHTS THAT THE PARTIES WOULD HAVE IN COURT MAY NOT BE AVAILABLE IN
							ARBITRATION.
						</p>
						<h4 className="pb-2 pt-3">Severability; Waiver</h4>
						<p>
							If for whatever reason, any term or condition in these Terms is determined to be
							unenforceable, all other terms and conditions will remain unaffected and in full force and
							effect. No waiver of any breach of any provision of these Terms shall constitute a waiver of
							any prior, concurrent, or subsequent breach of the same or any other provisions hereof, and
							no waiver shall be effective unless made in writing and signed by an authorized
							representative of the waiving party.
						</p>
						<h4 className="pb-2 pt-3">Updates to Terms</h4>
						<p>
							These Terms were published and last updated on Jan 11, 2021. The Site shall have the right
							to revise these Terms at any time by updating this posting. By using this Site, you agree to
							be bound by any such revisions and should therefore periodically visit this Site to
							determine the then-current Terms to which you are bound.
						</p>
						<h4 className="pb-2 pt-3">Contact Us</h4>
						<p>
							To contact the Site with any inquiries or complaints, including any regarding these Terms,
							you may contact us via email at helpdesk@offersclub.com.
						</p>
						<p>
							© 2020-2021 www.OffersClub.com. All rights reserved.
						</p>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default withRouter(TermsAndConditions);
