export const PersonalDetailsLeftPanelConstant = [
  {
    type: "input",
    subType: "text",
    id: "first_name",
    label: "FIRST NAME",
    placeholder: "FIRST NAME",
    key: "first_name",
    formClass: "form-control",
    required: true,
    fieldFor: "userInfo",
  },
  {
    type: "number",
    subType: "number",
    id: "inputBirthDate",
    format: "##/##/####",
    placeholder: "MM/DD/YYYY",
    label: "Birth Date",
    key: "birth_date",
    required: true,
    formClass: "form-control",
    fieldFor: "userInfo",
  },
  // {
  //   type: "date",
  //   subType: "date",
  //   id: "inputBirthDate",
  //   label: "Birth Date",
  //   placeholder: "dd/mm/yy",
  //   key: "birth_date",
  //   format: "yyyy-MM-dd",
  //   required: true,
  // },
  {
    type: "number",
    subType: "number",
    id: "phone_number",
    label: "PHONE NUMBER",
    placeholder: "(___)-___-____",
    key: "phone_number",
    formClass: "form-control",
    required: true,
    fieldFor: "userInfo",
    format: "(###)-###-####",
  },
];

export const PersonalDetailsRightPanelConstant = [
  {
    type: "input",
    subType: "text",
    id: "last_name",
    label: "LAST NAME",
    placeholder: "LAST NAME",
    key: "last_name",
    formClass: "form-control",
    required: true,
    fieldFor: "userInfo",
  },
  {
    type: "choices",
    subType: "choices",
    id: "gender",
    label: "Gender",
    placeholder: "Select gender",
    key: "gender",
    formClass: "form-control",
    options: [
      {
        value: "",
        label: "Select gender",
      },
      {
        value: "female",
        label: "Female",
      },
      {
        value: "male",
        label: "Male",
      },
    ],
  },
];

export const EmailPasswordPanelConstant = [
  {
    type: "input",
    subType: "text",
    id: "inputEmail",
    label: "Email",
    placeholder: "Email",
    key: "email",
    formClass: "form-control",
    required: true,
    fieldFor: "userInfo",
    readOnly: true,
  },
  {
    type: "input",
    subType: "password",
    id: "inputPassword",
    label: "Password",
    placeholder: "Password",
    key: "password",
    formClass: "form-control",
    required: true,
    fieldFor: "userInfo",
    readOnly: true,
    showEditButton: true,
  },
];

export const ChangePasswordPanelConstant = [
  {
    type: "input",
    subType: "password",
    id: "currentPassword",
    label: "Current Password",
    placeholder: "Current Password",
    key: "current_password",
    formClass: "form-control",
    required: true,
    fieldFor: "userInfo",
  },
  {
    type: "input",
    subType: "password",
    id: "newPassword",
    label: "New Password",
    placeholder: "New Password",
    key: "new_password",
    formClass: "form-control",
    fieldFor: "userInfo",
  },
  {
    type: "input",
    subType: "password",
    id: "confirmPassword",
    label: "Confirm Password",
    placeholder: "Confirm Password",
    key: "confirm_password",
    formClass: "form-control",
    fieldFor: "userInfo",
  },
];
