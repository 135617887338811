import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Logo from "../../../theme/images/logo.svg";
import SearchIcon from "../../../theme/images/search.svg";
import { withRouter } from "react-router-dom";

import { Theme } from "../../../theme/css/theme";
import { Button } from "../../../theme/css/Global";

import ProfilePhoto from "../../../theme/images/default-user.png";
import SignInIcon from "../../../theme/images/join-free.svg";
import HeartIcon from "../../../theme/images/save-offer.svg";
import NotificationIcon from "../../../theme/images/notification.svg";
import UserIcon from "../../../theme/images/user.svg";

import Login from "./SignIn";
import SignUp from "./SignUp";
import Notifications from "./Notifications";
import SearchComponent from "../../shared/SearchComponent";

const _ = require("lodash");

function HeaderComponent(props) {
  const redirectToPage = (redirectLink) => {
    props.history.push({
      pathname: redirectLink,
    });
    window.$("#notificationModal").modal("hide");
    closeMenu();
  };
  const [isClear, setIsClear] = useState(false);
  const [isLogin, setIsLogin] = useState(localStorage.getItem("token"));
  const { UserData } = useSelector((state) => ({
    UserData: state.userState.UserDetails,
  }));

  useEffect(() => {
    setIsLogin(localStorage.getItem("token"));
  });

  const redirectToSavedOffers = () => {
    props.history.push(
      { pathname: "/saved-offers" },
      { offerType: "Saved Offers", filters: { page: 1 } }
    );
    closeMenu();
  };
  const redirectToMyOffers = () => {
    props.history.push(
      { pathname: "/my-offers" },
      { offerType: "My Offers", filters: { page: 1 } }
    );
    closeMenu();
  };

  const closeMenu = () => {
    window.$("#menu-toggle").prop("checked", false);
  };

  const cancelSearchMenu = () => {
    window.$("#search-header-toggle").prop("checked", false);
  };

  return (
    <React.Fragment>
      <div>
        <Header>
          <div className="container">
            <div className="row">
              <div className="col-8">
                <ul>
                  <li onClick={() => redirectToPage("/")}>
                    <img className="logo" src={Logo} alt="OffersClubOnline" />
                  </li>
                  <li className="search  d-lg-inline-block d-none ml-5 pl-2">
                    {" "}
                    <SearchComponent
                      placeholder="Search brands & categories"
                      {...props}
                    />
                  </li>
                </ul>
              </div>
              {isLogin !== null ? (
                <div className="col-4 text-right">
                  <ul className="d-lg-block d-none">
                    {/*
                    <li onClick={(event) => redirectToMyOffers(event)}>
                      My Offers Y
                    </li>
                    */}
                    <li onClick={(event) => redirectToSavedOffers(event)}>
                      Favorites
                    </li>
                    {/* <li>
                      <i
                        className="fa fa-bell"
                        data-toggle="modal"
                        data-target="#notificationModal"
                      ></i>
                    </li> */}
                    <li onClick={() => redirectToPage("/account/mydetails")}>
                      <div className="profile-photo">
                        <img
                          src={
                            _.get(UserData, "profile_photo")
                              ? _.get(UserData, "profile_photo")
                              : ProfilePhoto
                          }
                          alt="Profile picture"
                        />
                      </div>
                    </li>
                  </ul>
                  <Notifications onClick={closeMenu} />
                  <div className="responsive-mobile-menu d-lg-none d-block">
                    <ul>
                      <li className="mr-0">
                        {/* Search Mobile Screen*/}
                        <SearchMobileScreen>
                          <input type="checkbox" id="search-header-toggle" />
                          <label
                            htmlFor="search-header-toggle"
                            className="mb-0"
                          >
                            <img
                              src={SearchIcon}
                              alt=""
                              className="search-icon"
                            />
                          </label>
                          <div action="" className="search-bar">
                            <SearchComponent
                              placeholder="Search brands & categories"
                              {...props}
                            />
                            <i className="fa fa-search" aria-hidden="true"></i>
                            <button
                              className="btn btn-cancel"
                              type="button"
                              onClick={cancelSearchMenu}
                            >
                              Cancel
                            </button>
                          </div>
                        </SearchMobileScreen>
                      </li>
                      <li>
                        <input type="checkbox" id="menu-toggle" />
                        <label htmlFor="menu-toggle" className="mb-0">
                          <span></span>
                          <span></span>
                          <span></span>
                        </label>

                        <div className="main-mobile-navigation">
                          <div className="menu-top">
                            <img
                              className="logo"
                              src={Logo}
                              alt="OffersClubOnline"
                              onClick={() => closeMenu()}
                            />
                            <div className="clearfix" />
                            <ul>
                              {/*
                              <li
                                  onClick={(event) =>
                                      redirectToMyOffers(event)
                                  }
                              >
                                {" "}
                                <img
                                    src={HeartIcon}
                                    alt=""
                                    className="list-icon img1"
                                />{" "}
                                My Offers X
                              </li>
                              */}
                              <li
                                onClick={(event) =>
                                  redirectToSavedOffers(event)
                                }
                              >
                                {" "}
                                <img
                                  src={HeartIcon}
                                  alt=""
                                  className="list-icon img1"
                                />{" "}
                                Favorites
                              </li>
                              <li onClick={closeMenu}>
                                <span
                                  data-toggle="modal"
                                  data-target="#notificationModal"
                                >
                                  <img
                                    src={NotificationIcon}
                                    alt=""
                                    className="list-icon img2"
                                  />{" "}
                                  Notifications
                                </span>
                              </li>
                              <li
                                onClick={() =>
                                  redirectToPage("/account/mydetails")
                                }
                              >
                                <div onClick={closeMenu}>
                                  <img
                                    src={UserIcon}
                                    alt=""
                                    className="list-icon img3"
                                  />{" "}
                                  My Account
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="menu-bottom text-center">
                            <h6 className="mt-5 pt-3 pb-3">FOLLOW US</h6>
                            <i
                              className="fa fa-facebook mr-5 "
                              aria-hidden="true"
                              onClick={closeMenu}
                            ></i>
                            <i
                              className="fa fa-twitter mr-5 "
                              aria-hidden="true"
                              onClick={closeMenu}
                            ></i>
                            <i
                              className="fa fa-linkedin mr-5"
                              aria-hidden="true"
                              onClick={closeMenu}
                            ></i>
                            <i
                              className="fa fa-youtube-play"
                              aria-hidden="true"
                              onClick={closeMenu}
                            ></i>
                            <p className="small mt-4" onClick={closeMenu}>
                              {" "}
                              &copy; 2020-2021 OffersClub. All right reserved.
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>{" "}
                  </div>
                </div>
              ) : (
                <div className="col-4  text-right">
                  <ul className="d-lg-block d-none">
                    <li>
                      <div
                        className="login-modal-text"
                        data-toggle="modal"
                        data-target="#LoginModal"
                        onClick={() => setIsClear(true)}
                      >
                        Log In
                      </div>
                    </li>
                    <li>
                      {" "}
                      <Button
                        className="btn btn-primary"
                        data-toggle="modal"
                        data-target="#joinForFree"
                        onClick={() => setIsClear(true)}
                      >
                        Join for Free
                      </Button>
                    </li>
                  </ul>

                  <Login
                    state={_.get(props.location, "state")}
                    isClearData={isClear}
                    onClick={() => setIsClear(false)}
                  />
                  <SignUp
                    state={_.get(props.location, "state")}
                    isClearData={isClear}
                    onClick={() => setIsClear(false)}
                  />

                  <div className="responsive-mobile-menu d-lg-none d-block">
                    <ul>
                      <li className="mr-0">
                        {/* Search Mobile Screen*/}
                        <SearchMobileScreen>
                          <input type="checkbox" id="search-header-toggle" />
                          <label
                            htmlFor="search-header-toggle"
                            className="mb-0"
                          >
                            <img
                              src={SearchIcon}
                              alt=""
                              className="search-icon"
                            />
                          </label>
                          <div action="" className="search-bar">
                            <SearchComponent
                              placeholder="Search brands & categories"
                              {...props}
                            />
                            <i className="fa fa-search" aria-hidden="true"></i>
                            <button
                              className="btn btn-cancel"
                              type="button"
                              onClick={cancelSearchMenu}
                            >
                              Cancel
                            </button>
                          </div>
                        </SearchMobileScreen>
                      </li>
                      <li>
                        <input type="checkbox" id="menu-toggle" />
                        <label htmlFor="menu-toggle" className="mb-0">
                          <span></span>
                          <span></span>
                          <span></span>
                        </label>

                        <div className="main-mobile-navigation">
                          <div className="menu-top">
                            <img
                              className="logo"
                              src={Logo}
                              alt="OffersClubOnline"
                              onClick={() => closeMenu()}
                            />
                            <div className="clearfix" />
                            <ul>
                              <li onClick={closeMenu}>
                                <div
                                  className="login-modal-text"
                                  data-toggle="modal"
                                  data-target="#LoginModal"
                                  onClick={() => setIsClear(true)}
                                >
                                  <img
                                    src={UserIcon}
                                    alt=""
                                    className="d-lg-none d-block  list-icon login-img"
                                  />{" "}
                                  Log In
                                </div>
                              </li>
                              <li onClick={closeMenu}>
                                <span
                                  data-toggle="modal"
                                  data-target="#joinForFree"
                                  onClick={() => setIsClear(true)}
                                >
                                  <img
                                    src={SignInIcon}
                                    alt=""
                                    className="list-icon signup-img"
                                  />
                                  Join For Free
                                </span>
                                {/* <SignUp state={_.get(props.location, "state")} /> */}
                              </li>
                            </ul>
                          </div>
                          <div className="menu-bottom text-center">
                            <h6 className="mb-2">CONTACT US</h6>
                            <p className="" onClick={closeMenu}>
                              helpdesk@offersclub.com
                            </p>
                            <h6 className="pb-3 pt-4">FOLLOW US</h6>
                            <i
                              className="fa fa-facebook mr-5 "
                              aria-hidden="true"
                              onClick={closeMenu}
                            ></i>
                            <i
                              className="fa fa-twitter mr-5 "
                              aria-hidden="true"
                              onClick={closeMenu}
                            ></i>
                            <i
                              className="fa fa-linkedin mr-5"
                              aria-hidden="true"
                              onClick={closeMenu}
                            ></i>
                            <i
                              className="fa fa-youtube-play"
                              aria-hidden="true"
                              onClick={closeMenu}
                            ></i>
                            <p className="small mt-4" onClick={closeMenu}>
                              {" "}
                              &copy; 2020-2021 OffersClub. All right reserved.
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Header>
      </div>
    </React.Fragment>
  );
}

export default withRouter(HeaderComponent);

const Header = styled.div`
  font-family: ${Theme["$base-font-family"]};
  background-color: ${Theme["$gray-105"]};
  padding: 15px 0;

  .responsive-mobile-menu {
    display: none;
    position: relative;

    .search-icon {
      position: absolute;
      right: 45px;
      top: 0px;
    }

    .main-mobile-navigation {
      display: none;
      position: fixed;
      top: 0px;
      right: 0;
      background: ${Theme["$gray-91"]};
      z-index: 9998;
      width: 100%;
      height: 100%;

      .menu-top {
        .logo {
          width: 140px;
          margin: 17px 0 0 15px;
          float: left;
        }

        ul {
          padding: 30px 0;
          max-width: 220px;
          text-align: left;
          margin: 0 auto;

          li {
            display: block;
            padding: 12px;
            font-size: ${Theme["$base-title-f-size"]};
            font-family: ${Theme["$title-font-family"]};
            color: ${Theme["$white-color"]};
            letter-spacing: 1px;

            .login-modal-text {
              font-size: ${Theme["$extra-medium-f-size"]};
              font-family: ${Theme["$title-font-family"]};
              letter-spacing: 1px;
            }

            .btn {
              &.btn-primary {
                font-size: ${Theme["$extra-medium-f-size"]};
                background: none !important;
                letter-spacing: 1px;

                padding: 0;
              }
            }

            .list-icon {
              float: left;
              margin-right: 10px;

              &.img1 {
                width: 21px;
                padding-top: 6px;
              }

              &.img2 {
                width: 19px;
                padding-top: 2px;
                margin-right: 12px;
              }

              &.img3 {
                width: 22px;
                padding-top: 5px;
                margin-right: 12px;
              }

              &.login-img {
                width: 22px;
                padding-top: 5px;
                margin-right: 12px;
              }

              &.signup-img {
                width: 22px;
                padding-top: 5px;
                margin-right: 12px;
              }
            }
          }
        }
      }

      .menu-bottom {
        position: absolute;
        bottom: 5px;
        text-align: center;
        width: 100%;

        i {
          font-size: ${Theme["$e-large-f-size"]};
          color: ${Theme["$primary-color"]};
        }

        p {
          letter-spacing: 0.3px !important;
        }
      }
    }

    #menu-toggle {
      display: none;

      &:checked ~ .main-mobile-navigation {
        display: block;
      }

      &:checked + label span {
        opacity: 1;
        transform: rotate(45deg) translate(-3px, -4px);
      }

      &:checked + label span:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }

      &:checked + label span:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, 0px);
      }
    }

    label {
      position: absolute;
      right: 0px;
      top: 0px;
      margin-left: 20px;

      span {
        display: block;
        position: relative;
        margin: 0 0 4px 0;
        width: 23px;
        height: 2.5px;
        background: #fcfcfc;
        transition: 0.5s;
        transform-origin: 11px 0px;
        z-index: 99999;
        cursor: pointer;
        border-radius: 10px;

        &:first-child {
          transform-origin: 0% 0%;
        }

        &:nth-last-child(2) {
          transform-origin: 60% 100%;
        }
      }
    }

    @media only screen and (max-width: 575px) {
      display: block;
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin-right: 30px;
      vertical-align: middle;
      font-size: ${Theme["$medium-f-size"]};
      font-family: ${Theme["$title-font-family"]};
      letter-spacing: 0.3px;
      color: ${Theme["$gray-4"]};
      cursor: pointer;
      font-weight: 500;

      &.search {
        width: 100%;
        max-width: 505px;

        @media only screen and (max-width: 1330px) {
          margin-left: 0 !important;
          width: 70%;
        }

        @media only screen and (max-width: 1055px) {
          width: 68%;
        }
      }

      .btn-primary {
        font-size: ${Theme["$medium-f-size"]};
      }

      .logo {
        width: 140px;
      }

      .fa-bell {
        font-size: ${Theme["$base-title-f-size"]};
      }

      .profile-photo {
        float: left;

        img {
          width: 43px;
          height: 43px;
          border-radius: 6px;
        }
      }

      .form-group {
        margin-bottom: 0;
        position: relative;

        .search-input {
          position: relative;
          padding-left: 60px;
          border-radius: 25px;
          background-color: ${Theme["$gray-85"]};
          border: none;
          height: 2.8rem;
          color: ${Theme["$white-color"]};

          &::placeholder {
            color: ${Theme["$gray-6"]};
            font-size: ${Theme["$medium-f-size"]};
            font-family: ${Theme["$medium-font-family"]};
          }
        }

        .search-icon {
          position: absolute;
          top: 13px;
          left: 22px;
          z-index: 2;
          display: block;
          pointer-events: none;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

const SearchMobileScreen = styled.div`
  .search-bar {
    background-color: ${Theme["$gray-92"]};
    z-index: 999999;
    display: none;
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 15px;
    animation-name: FadeIn;
    animation-duration: 0.5s;

    @keyframes FadeIn {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    .search-icon {
      display: none !important;
    }

    .search-input {
      padding-left: 39px !important;
      width: 81%;
      float: left;
      padding: 6px 10px 7px 38px;
      height: 2.2rem !important;
      border-radius: 5px !important;
      background-color: ${Theme["$white-color"]} !important;
      color: ${Theme["$gray-30"]} !important;

      &::placeholder {
        font-size: ${Theme["$base-f-size"]};
        font-family: ${Theme["$base-font-family"]};
        color: ${Theme["$gray-30"]} !important;
      }
    }

    .fa-search {
      position: absolute;
      top: 24px;
      left: 25px;
      color: ${Theme["$gray-93"]};
    }

    .btn-cancel {
      font-family: ${Theme["$base-font-family"]};
      font-size: ${Theme["$base-f-size"]};
      color: #fff;
      float: right;
      width: 18%;
      padding: 6px 0 6px 5px;
      letter-spacing: 0.5px;
      text-align: center;

      &:focus {
        box-shadow: none;
      }
    }
  }

  #search-header-toggle {
    display: none;

    &:checked ~ .search-bar {
      display: block;
    }
  }
`;
