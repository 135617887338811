import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ErrorLabel } from "../../theme/css/Global";
import {Theme} from "../../theme/css/theme";


const InputField = (props) => {
  const [showInputField, setShowInputField] = useState(true);

  const fieldChange = (event) => {
    props.onChange({
      key: props.fieldKey,
      value: event.target.value,
    });
  };

  const editField = (event) => {
    setShowInputField(false);
    props.onEditField(true);
  };

  const {
    subType,
    name,
    label,
    placeholder,
    value,
    error,
    id,
    inputClass,
    minValue,
    isAdmin,
    readOnly,
    showEditButton,
    fieldFor,
  } = props;

  return fieldFor === "userInfo" ? (
    showInputField ? (
      <div className="form-group">
        <label htmlFor={id}>{label}</label>
        {showEditButton ? (
          <React.Fragment>
            <span> | </span>{" "}
            <EditLabel onClick={(event) => editField(event)}>
              EDIT
            </EditLabel>
          </React.Fragment>
        ) : (
          ""
        )}

        <input
          type={subType}
          name={name}
          value={value || ""}
          onChange={(event) => fieldChange(event)}
          id={id}
          className={inputClass}
          min={minValue}
          placeholder={placeholder}
          readOnly={readOnly}
        />
        {error && error.length !== 0 ? (
          <div>
            <ErrorLabel>{error}</ErrorLabel>
          </div>
        ) : null}
      </div>
    ) : (
      ""
    )
  ) : (
    <div
      className="form-label-group"
    >
      <input
        type={subType}
        name={name}
        value={value || ""}
        onChange={(event) => fieldChange(event)}
        id={id}
        className={inputClass}
        min={minValue}
        placeholder={placeholder}
        readOnly={isAdmin}
      />
      <label htmlFor={id}>{label} </label>
      {error && error.length !== 0 ? (
        <div>
          <ErrorLabel>{error}</ErrorLabel>
        </div>
      ) : null}
    </div>
  );
};

InputField.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  subType: PropTypes.string,
  isReadOnly: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
};

export default InputField;

const EditLabel = styled.a`
  color: #3d8fff !important;
  font-family: ${Theme["$title-font-family"]} !important;
`;