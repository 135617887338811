import React from "react";
import { CircleSpinner } from "react-spinners-kit";

const CircleLoader = (props) => {
  const { size, color } = props;
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <CircleSpinner size={size || 40} color={color ? color : "#3d8eff"} loading={true}  />
        </div>
      </div>
    </div>
  );
};

export default CircleLoader;
