import React, { useState, useEffect } from "react";
import { ToastsStore } from "react-toasts";

import CircleLoader from "../../shared/CircleLoader";
import { Button, CustomCheckbox, ErrorLabel } from "../../../theme/css/Global";
import { getFields } from "../../shared/FieldRenderer";
import apiClient from "../../../store/actions/ApiInterceptor";
import {
  EmailNotificationsConstant,
  EmailNotificationsUnsubcribeConstant,
} from "../../../constants/EmailNotificationsConstant";

const _ = require("lodash");

function EmailNotifications() {
  const [emailNotificationsFormData, setEmailNotificationsFormData] = useState({
    daily_offers: false,
    personal_recommendations: false,
    offers_club_newsletter: false,
    offers_club_news: false,
  });
  const [unsubscribeFormData, setUnsubscribeFormData] = useState({
    unsubscribe: false,
  });
  const [issLoading, setIsLoading] = useState(false);
  // const [pageLoading, setPageLoading] = useState(false);
  const [error, setError] = useState({ msg: null });

  useEffect(() => {
    getEmailNotificationSettings();
  }, []);

  //check all option false or not
  const checkUnsubscribe = (data) => {
    let flag = true;
    for (let element in data) {
      if (element !== "id" && element !== "user" && data[element] === true) {
        flag = false;
      }
    }
    return flag;
  };

  const getEmailNotificationSettings = () => {
    // setPageLoading(true);
    apiClient
      .get("notification/")
      .then((res) => {
        if (_.get(res.data, "count") !== 0) {
          //for first time user
          if (_.get(res.data.results[0], "daily_offers") === null) {
            setEmailNotificationsFormData({
              ...emailNotificationsFormData,
              id: res.data.results[0].id,
            });
          } else {
            //set unsubscribe if all option are false
            if (checkUnsubscribe(res.data.results[0])) {
              setUnsubscribeFormData({ unsubscribe: true });
              setEmailNotificationsFormData({
                ...emailNotificationsFormData,
                id: res.data.results[0].id,
              });
            } else {
              setEmailNotificationsFormData(res.data.results[0]);
            }
          }
        }
        // setPageLoading(false);
      })
      .catch((error) => {});
  };

  const saveEmailNotificationSettings = (event) => {
    event.preventDefault(true);
    setIsLoading(true);
    //if have notification id 
    if (_.get(emailNotificationsFormData, "id")) {
      //it set unsunscribe from all
      if (unsubscribeFormData.unsubscribe) {
        apiClient
          .patch(`notification/${emailNotificationsFormData.id}`, {
            daily_offers: false,
            personal_recommendations: false,
            offers_club_newsletter: false,
            offers_club_news: false,
          })
          .then((res) => {
            setEmailNotificationsFormData(res.data);
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
          });
      } else {
        if (checkUnsubscribe(emailNotificationsFormData)) {
          setError({ msg: "Please select atleast one option" });
          setIsLoading(false);
        } else {
          // update other option
          console.log("saaaaaaa", emailNotificationsFormData);
          apiClient
            .patch(`notification/${emailNotificationsFormData.id}`, {
              daily_offers: emailNotificationsFormData.daily_offers,
              personal_recommendations:
                emailNotificationsFormData.personal_recommendations,
              offers_club_newsletter:
                emailNotificationsFormData.offers_club_newsletter,
              offers_club_news: emailNotificationsFormData.offers_club_news,
            })
            .then((res) => {
              setEmailNotificationsFormData(res.data);
              setIsLoading(false);
            })
            .catch((error) => {
              setIsLoading(false);
            });
        }
      }
      //if dont have notification id
    } else {
      ToastsStore.error(
        "Something went wrong. Please refresh the page and try again."
      );
      setIsLoading(false);
    }
  };

  const handleFieldChange = ({ key, value }) => {
    setEmailNotificationsFormData({
      ...emailNotificationsFormData,
      [key]: value,
    });
    //uncheck unsubscribe when check other option
    if (value && unsubscribeFormData.unsubscribe) {
      setUnsubscribeFormData({ unsubscribe: false });
    }
    if (_.get(error, "msg") !== null) {
      setError({ msg: null });
    }
  };

  const handleUnsubscribeChange = ({ key, value }) => {
    setUnsubscribeFormData({
      ...unsubscribeFormData,
      [key]: value,
    });
    //uncheck other options when check unsubscribe option
    if (value) {
      setEmailNotificationsFormData({
        ...emailNotificationsFormData,
        daily_offers: false,
        personal_recommendations: false,
        offers_club_newsletter: false,
        offers_club_news: false,
      });
    }
    if (_.get(error, "msg") !== null) {
      setError({ msg: null });
    }
  };

  return (
    <React.Fragment>
      {/* {!pageLoading ?  */}
      <div className="col-xl-9 col-lg-8">
        <div className="white-panel">
          <h4 className="dark-bold-heading">Communication Preferences</h4>
          <h4 className="email-notification-subtitle">Personal Information</h4>
          <p className="mb-0">
            We only want to send you the offers your prefer, Select your options
            below.
          </p>
          <CustomCheckbox>
            <div className="custom-control custom-checkbox mb-4">
              {getFields(
                EmailNotificationsConstant,
                emailNotificationsFormData,
                null,
                handleFieldChange
              )}
            </div>
            <hr />
            <div className="custom-control custom-checkbox">
              {getFields(
                EmailNotificationsUnsubcribeConstant,
                unsubscribeFormData,
                null,
                handleUnsubscribeChange
              )}
            </div>
            <Button
              type="submit"
              className="btn btn-primary mt-4 pt-xl-2 notification-save"
              onClick={(event) => saveEmailNotificationSettings(event)}
            >
              {issLoading ? <CircleLoader size={20} color="#ffffff" /> : "Save"}
            </Button>
            <div>
              {_.get(error, "msg") !== null ? (
                <ErrorLabel>{_.get(error, "msg")}</ErrorLabel>
              ) : (
                ""
              )}
            </div>
          </CustomCheckbox>
        </div>
      </div>
      {/*  : <CircleLoader />} */}
    </React.Fragment>
  );
}

export default EmailNotifications;
